export default ({
    spaces,
    breakpoints,
    helpers,
}) => ({
    key: { name: 'GameArchiveFeaturedMidgrounds' },

    midgrounds: {
        display: 'none',
        width: `calc(100% + ${spaces[4]*2}px)`,
        height: 'calc(100% + 2px)',
        position: 'absolute',
        top: -1,
        right: -spaces[4],
        zIndex: 2,

        [breakpoints.md]: {
            display: 'block',
            width: '90%',
            right: 0,
        },

        [breakpoints.lg]: {
            width: '80%',
            right: spaces[4],
        },
    },

    midground: {
        display: 'block',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundSize: '200% 200%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        opacity: 0,
        zIndex: 1,
        ...helpers.clipRect('6% 0%', '40% 0%', '40% 100%', '6% 100%'),

        '&.active': {
            ...helpers.clipRect('6% 0%', '40% 0%', '84% 100%', '50% 100%'),
            transform: 'translate3d(0, 0, 0)',
            backgroundSize: '100% 100%',
            zIndex: 2,
        },

        [breakpoints.max]: {
            right: spaces[0],
        },
    },

    direction0: { // ltr
        '& $midground': {
            transform: 'translate3d(-100vw, 0, 0)',
            // ...helpers.clipRect('40% 0%', '40% 0%', '84% 100%', '84% 100%'),
        },

        '& $in': {
            '&.active': {
                transform: 'translate3d(0, 0, 0)',
                // ...helpers.clipRect('6% 0%', '40% 0%', '84% 100%', '50% 100%'),
            },
        },

        '& $out': {
            transform: 'translate3d(100vw, 0, 0)',
            // ...helpers.clipRect('6% 0%', '6% 0%', '50% 100%', '50% 100%'),

            '&.active': {
                transform: 'translate3d(0, 0, 0)',
                // ...helpers.clipRect('6% 0%', '40% 0%', '84% 100%', '50% 100%'),
            },
        },
    },

    direction1: { // rtl
        '& $midground': {
            transform: 'translate3d(100vw, 0, 0)',
            // ...helpers.clipRect('6% 0%', '6% 0%', '50% 100%', '50% 100%'),
        },

        '& $in': {
            '&.active': {
                transform: 'translate3d(0, 0, 0)',
                // ...helpers.clipRect('6% 0%', '40% 0%', '84% 100%', '50% 100%'),
            },
        },

        '& $out': {
            transform: 'translate3d(-100vw, 0, 0)',
            // ...helpers.clipRect('40% 0%', '40% 0%', '84% 100%', '84% 100%'),

            '&.active': {
                transform: 'translate3d(0, 0, 0)',
                // ...helpers.clipRect('6% 0%', '40% 0%', '84% 100%', '50% 100%'),
            },
        },
    },

    in: {
        transition: 'all ease-in-out .75s',
        transitionDelay: '.5s',
        opacity: 1,
    },

    out: {
        transition: 'all ease-in-out .75s',
        transitionDelay: '.5s',
        opacity: 1,
    },
})
