export default ({
    colors,
    breakpoints,
    spaces,
    helpers,
    gradients,
}) => ({
    key: { name: 'TestimonialsCard' },
    
    card: {
        transition: 'opacity ease-in-out .25s',
        pointerEvents: 'none',

        '&.active': {
            '& $image': {
                transform: 'translate3d(-50%, -50%, 10px) scale(1)',
                transitionDelay: '.15s',
            },
        },

        '&:hover': {
            '& .btn': {
                '&::before': {
                    transform: 'translate3d(0, 0, 0)',
                },
            },
		},
    },

    tilt: {
        transformStyle: 'preserve-3d',
        transform: 'perspective(1000px)',
    },

    inner: {
        width: '100%',
        paddingTop: '95%',
        position: 'relative',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		
		[breakpoints.sm]: {
			paddingTop: '75%',
		},

        [breakpoints.md]: {
            paddingTop: '55%',
        }
    },

    background: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        ...helpers.clipCorners.default,
        transition: 'clip-path ease-in-out .15s',
        transitionDelay: '.15s',

        '&::before': {
            display: 'block',
            width: '150%',
            height: '60%',
            position: 'absolute',
            left: '50%',
            bottom: 0,
            transform: 'translateX(-50%)',
            content: '""',
            zIndex: 1,
            opacity: .8,
        },

        '$color_white &': {
            backgroundColor: colors.tertiary,

            '&::before': {
                background: gradients.radial.bottom.pink,
            },
        },

        '$color_black &': {
            backgroundColor: colors.blue,

            '&::before': {
                background: gradients.radial.bottom.primary,
            },
        },
    },

    image: {
		width: spaces[5],
		height: spaces[5],
        position: 'absolute',
        top: 0,
        left: '50%',
        transform: 'translate3d(-50%, -50%, 10px) scale(.5)',
        transformOrigin: 'center',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        borderRadius: spaces[0]/2,
        transition: 'transform ease-in-out .25s',

        [breakpoints.sm]: {
            display: 'block',
        },

        [breakpoints.lg]: {
            top: '35%',
			left: 0,
			
			width: 120,
			height: 120,
        },
    },

    text: {
        display: 'block',
        width: '100%',
        padding: [spaces[3], spaces[3]],
        position: 'absolute',
        top: '50%',
        left: 0,
        transform: 'translate3d(0, -50%, 50px)',
        color: colors.white,
        fontWeight: 600,
        textAlign: 'left',
		
		'& p': {
			fontSize: 14,
			lineHeight: '15px',
		},

        [breakpoints.sm]: {
			padding: [spaces[3], spaces[4]],
            paddingLeft: spaces[4] + 50,
        },

        [breakpoints.md]: {
            '& p': {
                fontSize: 18,
				lineHeight: '24px',
            },
        },

        [breakpoints.lg]: {
            width: '100%',
        },

        [breakpoints.xlg]: {
            '& p': {
                fontSize: 18,
				lineHeight: '24px',
            },
        },
    },

    name: {
        display: 'block',
        margin: [spaces[0], 0, 0],
		fontSize: 14,
		lineHeight: '15px',
        fontWeight: 700,
        textTransform: 'uppercase',
		color: colors.white,

        [breakpoints.md]: {
            '& p': {
                fontSize: 18,
            },
        },
    },

    color_white: {},
    color_black: {},
})