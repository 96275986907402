export default ({ colors, spaces, breakpoints }) => ({
  key: { name: "GamesArchiveDefaultView" },

  default: {
    backgroundColor: colors.black,
    padding: [spaces[5], 0],
    position: "relative",
    overflow: "hidden",

    "&::before": {
      display: "block",
      width: 400,
      height: 400,
      position: "absolute",
      top: "80%",
      left: 0,
      transform: "translate3d(-30%, -60%, 0) scale(.9)",
      border: `3px solid ${colors.secondary}`,
      borderRadius: "100%",
      zIndex: 1,
      content: '""',

      [breakpoints.md]: {
        width: 520,
        height: 520,
        left: -spaces[3],
      },
    },
  },

  title: {
    width: 300,
    position: "relative",
    fontSize: "3.0em",
    lineHeight: "56px",
    color: colors.white,
    marginBottom: spaces[4],
    [breakpoints.lg]: {
      marginBottom: 0,
    },

    "& > span": {
      position: "relative",
      zIndex: 1,
    },

    "&::before": {
      display: "block",
      width: 2,
      height: 180,
      position: "absolute",
      top: 0,
      left: "70%",
      transform: "translate3d(0, -25%, 0) rotate(-30deg)",
      backgroundColor: colors.blue,
      content: '""',
      zIndex: 0,
    },
  },

  grid: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    position: "relative",
    zIndex: 2,

    "&::before": {
      display: "block",
      width: 250,
      height: 250,
      position: "absolute",
      top: "30%",
      right: 0,
      transform: "translate3d(30%, -60%, 0) scale(.9)",
      border: `3px solid ${colors.blue}`,
      borderRadius: "100%",
      content: '""',
      zIndex: 1,

      [breakpoints.md]: {
        width: 400,
        height: 400,
        right: -spaces[3],
      },
    },
  },

  loadMore: {
    margin: [spaces[5], 0, 0],
  },
})
