import React from 'react'
import cx from 'classnames'
import is from 'react-jss'

import style from './style'
import RandomText from '../RandomText'

const BackgroundText = ({
    classes,
    vertical = false,
    white = false,
    text = [],
    flip,
	className = '',
	mobile = false,
}) => {
	const longestLength = text.reduce((a, b) => a.length > b.length ? a : b, '').length
	const lineCountFactor = Math.max((text.length - 1) / 2, 1)

    return (
        <div className={cx(classes.backgroundText, className, {
            [classes.vertical]: vertical,
            [classes.mobile]: mobile,
            [classes.white]: white,
            [classes.flip]: flip,
        })}>
            <span className={classes.text} style={{
                fontSize: `${ (84 / longestLength) / lineCountFactor }vw`,
                lineHeight: `${ (0.833 * (84 / longestLength)) / lineCountFactor }vw`,
            }}>
                { text.map((t, i) => (
                    <RandomText key={i} animate={true}>{ t }</RandomText>
                ))}
            </span>
        </div>
    )
}

export default is(style)(BackgroundText)