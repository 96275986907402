import React, { useState } from 'react'
import cx from 'classnames'
import htmlParser from 'react-html-parser'
import is from 'react-jss'

import { getImageSourceUrl } from '../../../../../helpers'

import Link from '../../../../Link'
import Button from '../../../../Button'
import RandomText from '../../../../RandomText'

import style from './style'

const DefaultCard = ({
    classes,
    index,
    game: {
        title,
        slug,
        featuredImage,
    },
}) => {
    const [ hovered, setHovered ] = useState(false)

    const backgroundImage = getImageSourceUrl(featuredImage?.node, 'card_game')

    return (
        <div
            className={ cx(
                classes.card, {
                    [classes.wide]: index === 1,
                    hovered,
                },
            ) }
            onMouseEnter={e => setHovered(true)}
            onMouseLeave={e => setHovered(false)}
        >
            <Link to={`/games/${slug}/`}>
                <div className={cx(classes.inner)} style={{ backgroundImage: `url(${ backgroundImage })`}}>
                    <h2 className={cx(classes.title)}>{ htmlParser(title) }</h2>
                </div>

                <Button className={ cx(classes.button, 'no-hover') }>
                    <span className="gradient-text">
                        <RandomText speed={25} animate={hovered}>See results</RandomText>
                    </span>
                </Button>
            </Link>
        </div>
    )
}

export default is(style)(DefaultCard)