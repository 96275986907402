import React from 'react'
import cx from 'classnames'
import is from 'react-jss'

import style from './style'

const NavThumb = ({
    classes,
    images = [],
    index,
    direction = 'next',
    clickHandler,
    className,
    disabled = false,
}) => (
    <div
        className={ cx(classes.thumb, className, classes[direction] || classes.next, { disabled }) }
        onClick={e => clickHandler(e)}
    >
        { images.map((image, i) => (
            <span key={i} className={ cx(classes.inner, { active: index === i }) }>
                <div className={classes.image} style={{ backgroundImage: `url(${image})` }} />
            </span>
        )) }
    </div>
)

export default is(style)(NavThumb)
