import React from 'react'

import PostArchiveDefault from './PostArchiveDefault'
import PostArchiveFeatured from './PostArchiveFeatured'
import PostArchiveLatest from './PostArchiveLatest'
import PostArchiveImageRows from './PostArchiveImageRows'

const PostArchive = ({
    content: {
        postArchiveType,
        ...content
    },
    ...props
}) => {
    switch (postArchiveType) {
        case 'default': return <PostArchiveDefault {...{ content, ...props }} />
        case 'featured': return <PostArchiveFeatured {...{ content, ...props }} />
        case 'latest': return <PostArchiveLatest {...{ content, ...props }} />
        case 'image_rows': return <PostArchiveImageRows {...{ content, ...props }} />
        default: return <div>PostArchive: { postArchiveType || 'false' }</div>
    }
}

export default PostArchive
