import React from 'react'
import htmlParser from 'react-html-parser'
import Tilt from 'react-tilt'
import cx from 'classnames'
import is from 'react-jss'

import style from './style'
import { getImageSourceUrl } from '../../../../helpers'

const TestimonialCard = ({
    classes,
    color = 'white',
    post: {
        headshot,
        testimonial,
        name,
        jobTitle,
    },
    mouseEnter,
    mouseLeave,
    active = false,
    className,
}) => {

    const headshotImage = getImageSourceUrl(headshot, 'medium')

    return (
        <div className={ cx(classes.card, className, classes[`color_${color}`] || classes.color_white, { active }) }>
            <Tilt className={classes.tilt} options={{ max: 20, reverse: true }}>
                <div className={classes.inner} onMouseEnter={ (e) => mouseEnter() } onMouseLeave={ (e) => mouseLeave() }>
                    <span className={classes.background} />

                    { headshot && 
                        <span style={{ backgroundImage: `url('${ headshotImage }')` }} className={classes.image} />
                    }
                    
                    <div className={classes.text}>
                        <div className={classes.testimonial}>{ htmlParser(testimonial) }</div>
                        <h3 className={classes.name}>{ `${name}, ${jobTitle}` }</h3>
                    </div>
                </div>
            </Tilt>
        </div>
    )
}

export default is(style)(TestimonialCard)
