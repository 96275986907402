import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import htmlParser from 'react-html-parser'
import is from 'react-jss'

import Button from '../../../Button'

import style from './style'

const Question = ({
	classes,
	question: {
		required,
		label,
		fields,
		description,
	},
	i: index,
}) => {
	const [ fileLabel, setFileLabel ] = useState('Attach')
	const [ textAreaOpen, setTextAreaOpen ] = useState(false)
	const [ descriptionOpen, setDescriptionOpen ] = useState(false)
	const [ checked, setChecked ] = useState(false)

	const updateFileInput = (e) => {
		let fileName = false

		if (e.target.files && e.target.files.length > 0) {
			fileName = e.target.value.split('\\').pop()
		}

		if (fileName) {
			setFileLabel(fileName)
		} else {
			setFileLabel('Attach')
		}
	}

	if (fields.filter(f => f.name === 'latitude' || f.name === 'longitude').length > 0) return null

	if (fields.length > 1 && ((fields[0].type === 'input_file' && fields[1].type === 'textarea') || (fields[0].type === 'input_file' && fields[1].type === 'textarea'))) {
		let file = fields[0].type === 'input_file' ? 0 : 1,
			text = fields[0].type === 'textarea' ? 0 : 1
		
		return [
			<div {...{ key: 'file', className: cx(classes.fileInput)}}>
				<span className={cx(classes.label)}>{ label }{ required && <span> *</span> }</span>

				{ textAreaOpen === false ? [
					<input {...{
						key: 'file',
						className: cx(classes.fileInputField),
						type: 'file',
						id: fields[ file ].name,
						name: fields[ file ].name,
						accept: 'application/msword, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*',
						onChange: e => updateFileInput(e)
					}} />,
					<div key="label" className={cx(classes.fileInputButtonRow)}>
						<label htmlFor={ fields[ file ].name }>
							<Button className={cx(classes.fileInputButton)}>{ fileLabel }</Button>
						</label>
						<Button color="black" clickHandler={ e => setTextAreaOpen(true) }>Paste</Button>
					</div>
				] : (
					<div {...{ className: cx(classes.input)} }><textarea {...{ name: fields[ text ].name, placeholder: 'Please enter here', rows: 6 }}></textarea></div>
				)}
			</div>
		]
	} else {
		return fields.map((f, i) => {
			switch (f.type) {
				case 'input_file': return (
					<div key={i} {...{ className: cx(classes.fileInput)}}>
						<span className={cx(classes.label)}>{ label }{ required && <span> *</span> }</span>
						<input {...{
							className: cx(classes.fileInputField),
							type: 'file',
							id: f.name,
							name: f.name,
							accept: 'application/msword, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*',
							onChange: e => updateFileInput(e)
						}} />
						<label htmlFor={ f.name }>
							<Button className={cx(classes.fileInputButton)}>{ fileLabel }</Button>
						</label>
					</div>
				)
				case 'input_text': return <input key={i} {...{ type: 'text', name: f.name, className: cx(classes.input), placeholder: `${label}${ required ? ' *' : ''}` }} />
				case 'input_hidden': return <input key={i} {...{ type: 'hidden', name: f.name, value: f.values }} />
				case 'textarea': return <div key={i} {...{ className: cx(classes.input)} }><textarea {...{ name: f.name, placeholder: `${label}${ required ? ' *' : ''}`, rows: 6 }}></textarea></div>
				case 'multi_value_single_select': return (
					<select key={i} {...{ name: f.name, className: cx(classes.input) }}>
						<option {...{ value: false }}>{ label }</option>
						{ f.values && f.values.map((f, i) => (
							<option key={i} {...{ value: f.value }}>{ f.label }</option>
						)) }
					</select>
				)
				case 'multi_value_multi_select': return (
					<div key={i} className={classes.checkboxContainer}>
						{ description && (
							<div className={ cx(classes.description, { open: descriptionOpen }) }>
								<div className={classes.descriptionInner}>
									<h4>Terms &amp; Conditions</h4>
									{ htmlParser(description.replace(/<\/p>n<p>/g, '<\/p><p>')) }
									<Button color="black" className={classes.descriptionClose} clickHandler={ () => { setDescriptionOpen(false); setChecked(true) } }>I accept these terms</Button>
								</div>
							</div>
						) }
						{ f.values && f.values.map((v, i) => [
							<p key="tos" className={classes.descriptionTrigger} onClick={() => setDescriptionOpen(true) }>View our terms and conditions</p>,
							<label key="label" className={classes.descriptionLabel}>
								{ v.label }
								<input name={f.name} value={v.value} type="checkbox" defaultChecked={checked} onClick={ () => setChecked(!checked) } />
								<span className="checkmark"></span>
							</label>
						]) }
					</div >
				)
				default: return null
			}
		})
	}
}

export default is(style)(Question)
