import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import htmlParser from 'react-html-parser'
import is from 'react-jss'

import { displayDate, getImageSourceUrl } from '../../../../helpers'

import Button from '../../../Button'
import Tag from '../../../Tag'
import Link from '../../../Link'

import style from './style'

const PostCard = ({
    classes,
    index = 1,
    post: {
        slug,
		title,
		date,
        postFields: {
            thumbnail,
			offSitePost = false,
			postUrl,
        },
    },
    tag = false,
    active = true,
    autoActivate = false,
    className,
}) => {
	const [ autoActive, setAutoActive ] = useState(false)

    if (autoActivate) {
        useEffect(() => {
            setTimeout(() => {
                setAutoActive(true)
            }, index * 150)
        }, [active])
	}

	const getExcerpt = (title, n = 45) => {
		// return (title.length > n) ? title.substr(0, n-1) + '&hellip;' : title ///this line cuts str for card
		return title
	}

	const thumbnailImage = getImageSourceUrl(thumbnail, 'card_news')

	const CardInner = () => (
		<div className={classes.inner}>
			<span style={{ backgroundImage: `url('${ thumbnailImage }')` }} className={classes.image}>
				<span className={classes.overlay} />
			</span>
			<span className={classes.line} />
			<span className={classes.text}>
				<span className={classes.date}>{ displayDate(date) }</span>
				<h2 className={classes.title} data-text={htmlParser(getExcerpt(title))}>{ htmlParser(getExcerpt(title)) }</h2>
				{ tag && (
					<Tag className={classes.tag}>Category</Tag>
				) }
			</span>

			<Button color="black" className={ cx(classes.button, 'no-hover') }>
				<FontAwesomeIcon icon={[ 'far', 'angle-right' ]} color="white" className="icon" />
			</Button>
		</div>
	)

    return (
		<div className={ cx(classes.card, className, { active: !autoActivate ? active : autoActive }) }>
			<Link
				to={ offSitePost ? postUrl : `/news/${slug}` }
				className={classes.link}
				external={offSitePost ? true : false }
			>
				<CardInner />
			</Link>
		</div>
    )
}

export default is(style)(PostCard)
