export default ({
    color,
    breakpoints,
    typography,
}) => ({
    key: { name: 'BackgroundText' },
    
    backgroundText: {
        position: 'absolute',
		display: 'none',
		
		pointerEvents: 'none',
		userSelect: 'none',

        [breakpoints.md]: {
            display: 'block',
        },

        '& $text': {
            position: 'absolute',
            margin: 0,
            whiteSpace: 'nowrap',
            textTransform: 'uppercase',
			color: `rgba(0,0,0, 0.05)`,
			textAlign: 'left',
        },

        '&$vertical': {
            transform: 'translate3d(-50%, 0, 0)',
            fontSize: '0.65em',
            '& $text': {
                textOrientation: 'mixed',
                writingMode: 'vertical-rl',
                transform: 'translate3d(0, -50%, 0) rotate3d(0,0,1,180deg)',
            },

            '&$flip': {
                '& $text': {
                    transform: 'translate3d(0, -50%, 0)',
                },
            },
        },

        '&$white': {
            '& $text': {
                color: `rgba(255,255,255, 0.1)`,
            },
        },
	},
	
    mobile: {
		display: 'block',

		[breakpoints.mw.md]: {
			'& $text': {
				fontSize: '18vw!important',
				lineHeight: '18vw!important',
			},
		},
		
		[breakpoints.md]: {
			display: 'none',
		}
	},

    text: {
        display: 'block',
        ...typography.h1,
    },
    
    vertical: {},
    white: {},
    flip: {},
})