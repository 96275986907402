export default ({
    spaces,
    colors,
    breakpoints,
}) => ({
    key: { name: 'GamesArchiveFeatured' },
    
    inner: {
        height: '100vh',
        alignItems: 'flex-start',

        [breakpoints.md]: {
            minHeight: 650,
        },

        [breakpoints.lg]: {
            minHeight: 0,
        },
    },

    featured: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'flex-start',
        justifyContent: 'center',
        height: '100%',
        padding: 0,

        '&::after': {
            display: 'block',
            width: 300,
            height: 300,
            position: 'absolute',
            top: '100%',
            left: 0,
            transform: 'translate3d(-30%, -60%, 0)',
            border: `3px solid ${colors.primary}`,
            borderRadius: '100%',
            zIndex: 3,
            content: '""',

            [breakpoints.md]: {
                width: 400,
                height: 400,
                left: -spaces[3],
            },
        },
    },

    tag: {
        margin: [0, 0, spaces[2]],
    },

    text: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        height: '100%',
        maxHeight: 320,
        textAlign: 'left',
        zIndex: 4,

        [breakpoints.sm]: {
            paddingRight: 150 + spaces[2],
        },

        [breakpoints.md]: {
            maxHeight: 440,
        },

        [breakpoints.lg]: {
            paddingLeft: 150 - spaces[4] + spaces[2],
            paddingRight: 150 - spaces[4] + spaces[2],
        },
    },

    next: {
        display: 'none',
        position: 'absolute',
        top: '50%',
        right: 0,
        transform: 'translateY(-50%)',
        zIndex: 3,

        [breakpoints.sm]: {
            display: 'block',
        },
    },

    prev: {
        display: 'none',
        position: 'absolute',
        top: '50%',
        left: 0,
        transform: 'translateY(-50%)',
        zIndex: 3,

        [breakpoints.lg]: {
            display: 'block',
        },
    },

    buttons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        margin: [spaces[2], 0, 0],
    },

    button: {
        margin: [0, 0, 0, -spaces[2]],

        '&:first-child': {
            marginLeft: 0,
        },
    },
})
