import React, { useEffect } from 'react'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import is from 'react-jss'

import withSlider from '../Slider'
import Panel from '../Panel'
import Button from '../Button'

import style from './style'

const Slices = ({
    classes,
    slices = [],
    title,
    index,
    animated,
    setAnimated,
    setIndex,
    setPrevIndex,
    active,
    navigation,
    setNavigation,
    direction,
    setDirection,
    goTo,
}) => {
    useEffect(() => {
        setIndex(2)
    }, [])

    const prev = () => {
        if (navigation) {
            setDirection(0)
            setNavigation(false)
            setPrevIndex(index)

            if (index === 0) {
                setIndex(featuredContent.length - 1)
            } else {
                setIndex((prevState) => prevState-1)
            }

            setTimeout(() => {
                setNavigation(true)
            }, 1000)
        }
    }

    const next = () => {
        if (navigation) {
            setIndex((prevState) => prevState+1)
            setAnimated(true)

            if (index === (Math.ceil(slices.length/2)+1)) {
                setNavigation(false)

                setTimeout(() => {
                    setAnimated(false)
                    setIndex(2)
                    setNavigation(true)
                }, 750)
            }
        }
    }

    useEffect(() => {
        let interval = null
        if (active) {
            interval = setInterval(() => {
                next()
            }, 5000)
        } else if (!active) {
            clearInterval(interval)
        }

        return () => clearInterval(interval)
    }, [index, active, navigation])

    return (
        <Panel
            classNames={{ container: classes.container, inner: classes.inner }}
            outer={[
                <div key="slices" className={classes.slices}>
                    { slices.map((slice, i) => {
                        let positionClass = (index - i >= 0) ? `before${index-i}` :`after${i-index}`

                        return (
                            <div key={i} className={ cx(classes.slice, positionClass, { [classes.animated]: animated, active: i === index }) }>
                                <div className={classes.sliceInner}>
                                    <div className={ cx(classes.background, classes.inactiveBackground) } style={{ backgroundImage: `url(${slice.inactive.sourceUrl})`}} />
                                    <div className={ cx(classes.background, classes.activeBackground) } style={{ backgroundImage: `url(${slice.active.sourceUrl})`}} />
                                    <h3 className={classes.sliceTitle}>
                                        <Button className={ cx(classes.button, 'no-hover') }>
                                            <span className={ cx('gradient-text', classes.sliceTitleText) }>{ slice.name }</span>
                                        </Button>
                                    </h3>
                                    { slice.address && (
                                        <span className={classes.sliceAddress}>{ slice.address }</span>
                                    ) }
                                </div>
                            </div>
                        )
                    }) }
                </div>,
                <Button key="prev" className={classes.prev} clickHandler={ () => prev() }>
                    <FontAwesomeIcon icon={[ 'far', 'angle-left' ]} color="black" className={classes.icon} />
                </Button>,
                <Button key="next" className={classes.next} clickHandler={ () => next() }>
                    <FontAwesomeIcon icon={[ 'far', 'angle-right' ]} color="black" className={classes.icon} />
                </Button>,
            ]}
        >
            { title && (
                <h2 className={classes.title}><span>{ title }</span></h2>
            ) }
        </Panel>
    )
}

export default is(style)(withSlider(Slices))
