export default ({
    spaces,
    breakpoints,
}) => ({
    key: { name: 'PostArchiveFeaturedHighlights' },
    
    highlights: {
        display: 'none',
        margin: [0, 0, spaces[3]],

        [breakpoints.md]: {
            display: 'block',
        },

        [breakpoints.lg]: {
            margin: [0, 'auto', spaces[3]],
        },
    },

    highlightList: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        maxHeight: 0,
        margin: 0,
        position: 'relative',
        zIndex: 2,
        overflow: 'hidden',
        transition: 'opacity ease-in-out .25s',
        opacity: 0,

        '&.active': {
            maxHeight: 100,
            margin: [spaces[3], 0, 0],
            opacity: 1,
            
            [breakpoints.lg]: {
                maxHeight: 150,
            },
        },

        [breakpoints.lg]: {
            justifyContent: 'center',
        },
    },

    highlight: {
        width: 100,
        height: 'auto',
        marginLeft: spaces[2],
        verticalAlign: 'top',

        '&:first-child': {
            marginLeft: 0,
        },

        [breakpoints.lg]: {
            width: 150,
        },
    },
})
