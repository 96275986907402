export default ({
	spaces,
	colors,
	breakpoints,
	helpers,
	gradients,
}) => ({
	key: { name: 'ContactSmall' },
	
	small: {
		padding: [spaces[5], 0],
		position: 'relative',
		backgroundColor: colors.pink,
		overflow: 'hidden',

		'&::after': {
            display: 'block',
            width: 300,
            height: 300,
            position: 'absolute',
            top: '100%',
            right: 0,
            transform: 'translate3d(30%, -60%, 0) scale(.9)',
            border: `3px solid ${colors.primary}`,
            borderRadius: '100%',
            zIndex: 1,
            transition: 'opacity ease-in-out .25s, transform ease-in-out .25s',
            content: '""',

            [breakpoints.md]: {
                width: 400,
                height: 400,
                right: -spaces[3],
            },
        },
	},

	background: {
		width: '100%',
		height: '100%',
		position: 'absolute',
		top: 0,
		left: 0,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		zIndex: 1,
	},

	gradient: {
		width: '100%',
		height: '100%',
		position: 'absolute',
		top: 0,
		left: 0,
		backgroundColor: helpers.hexToRgba(colors.blue, .4),
		'-webkit-clip-path': 'polygon(0% 0%, 70% 0%, 100% 100%, 30% 100%)',
		'clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
		zIndex: 2,

		'&::before, &::after': {
			display: 'block',
			width: '150%',
			height: '100%',
			position: 'absolute',
			left: '50%',
			transform: 'translateX(-50%)',
			content: '""',
		},

		'&::before': {
			top: 0,
			background: gradients.radial.top.blue,
		},

		'&::after': {
			bottom: 0,
			background: gradients.radial.bottom.pink,
		},

		[breakpoints.md]: {
			width: '50%',
			left: '10%',
		},
	},

	title: {
		display: 'block',
		margin: [0, 'auto'],
        position: 'relative',
        fontSize: 54,
        lineHeight: '56px',
		color: colors.white,

        '& > span': {
            display: 'block',
            position: 'relative',
            zIndex: 2,
		},
		
		'&.large': {
			width: 300,
		},

        '&::before': {
            display: 'block',
            width: 2,
            height: '110%',
            position: 'absolute',
            top: 0,
            left: '80%',
            transform: 'translateY(-20%) rotate(-35deg)',
            backgroundColor: colors.primary,
            zIndex: 1,
            content: '""',
		},
		
		[breakpoints.md]: {
			margin: 0,
		},
	},

	
	button: {
		margin: [spaces[3], 0, 0],
		position: 'relative',
		zIndex: 2,

		[breakpoints.md]: {
			margin: 0,
		},
	},
})
