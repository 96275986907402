export default ({
    colors,
    breakpoints,
    spaces,
}) => ({
    key: { name: 'GameHeader' },
    
    panel: {
        '&::after': {
            display: 'block',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            transform: 'none',
            backgroundColor: colors.tertiary,
            opacity: 0,
            transition: 'opacity ease-in-out .25s',
            zIndex: 1,
        },

        '&::before': {
            display: 'block',
            width: '100%',
            height: '100%',
            left: 0,
            transform: 'none',
            backgroundColor: colors.tertiary,
            '-webkit-clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 100% 100%)',
            'clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 100% 100%)',
            opacity: 0,
            transition: 'opacity ease-in-out .25s',
            zIndex: 1,

            [breakpoints.sm]: {
                left: '10%',
                '-webkit-clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 40% 100%)',
                'clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 40% 100%)',
            },

            [breakpoints.sm]: {
                left: '30%',
                '-webkit-clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 30% 100%)',
                'clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 30% 100%)',
            },
        },

        '&.loaded': {
            '&::before': {
                opacity: .3,
            },

            '&::after': {
                opacity: .45,
            },
        },
    },
    
    background: {
        transform: 'scale(1.6)',
        transformOrigin: 'center',
        transition: 'all ease-in-out .25s',

        '.loaded &': {
            transform: 'scale(1)',
        },
    },

    foreground: {
        display: 'none',
        width: '100%',
        height: 'auto',
        position: 'absolute',
        right: 0,
        bottom: 0,
        verticalAlign: 'bottom',
        transform: 'translate3d(25%, 0, 0)',
        opacity: 0,
        transition: 'opacity ease-in-out .25s, transform ease-in-out .25s',
        transitionDelay: '1s',

        [breakpoints.lg]: {
            display: 'block',
            maxHeight: '80%',
            width: 'auto',
        },

        '.loaded &': {
            transform: 'translate3d(0, 0, 0)',
            opacity: 1,
        },
    },

    inner: {
        '&::after': {
            display: 'block',
            width: 300,
            height: 300,
            position: 'absolute',
            top: '100%',
            left: 0,
            transform: 'translate3d(-30%, -60%, 0) scale(.9)',
            border: `3px solid ${colors.primary}`,
            borderRadius: '100%',
            zIndex: 2,
            transition: 'opacity ease-in-out .25s, transform ease-in-out .25s',
            opacity: 0,
            content: '""',

            [breakpoints.md]: {
                width: 400,
                height: 400,
                left: -spaces[3],
            },

            '.loaded &': {
                transform: 'translate3d(-30%, -60%, 0) scale(1)',
                opacity: 1,
                transitionDelay: '.5s',
            },
        },
    },

    header: {
        padding: [spaces[5], 0, spaces[4]],

        [breakpoints.lg]: {
            padding: 0,
        },
    },

    text: {
        position: 'relative',
        textAlign: 'center',
    },

    logo: {
        maxWidth: 200,
        width: '100%',
        margin: [0, 'auto'],
        position: 'relative',
        transform: 'scale(.8)',
        transformOrigin: 'center',
        opacity: 0,
        transition: 'opacity ease-in-out .25s, transform ease-in-out .25s',
        transitionDelay: '.75s',
        zIndex: 4,

        [breakpoints.sm]: {
            maxWidth: 300,
        },

        '.loaded &': {
            opacity: 1,
            transform: 'scale(1)',
        },
    },


    title: {
        maxWidth: 640,
        margin: [spaces[2], 'auto', 0],
        position: 'relative',
        fontSize: 30,
        lineHeight: '32px',
        color: colors.white,
        zIndex: 3,

        [breakpoints.sm]: {
            fontSize: 45,
            lineHeight: '47px',
        },

        [breakpoints.md]: {
            fontSize: 54,
            lineHeight: '56px',
        },

        [breakpoints.lg]: {
            fontSize: 82,
            lineHeight: '84px',
        },

        '& > div': {
            position: 'relative',
			zIndex: 2,
			margin: [ 0, -10 ],
        },

        '&::before': {
            display: 'block',
            width: 3,
            height: '200%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate3d(-50%, -50%, 0) rotate(-36deg)',
            transformOrigin: 'center',
            backgroundColor: colors.secondary,
            opacity: 0,
            transition: 'all ease-in-out .25s',
            zIndex: -1,
            content: '""',

            '.loaded &': {
                transform: 'translate3d(-50%, -50%, 0) rotate(-36deg)',
                opacity: 1,
                transitionDelay: '.5s',
            },
        },
    },

    buttons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: [spaces[3], 0, 0],

        [breakpoints.sm]: {
            margin: [spaces[4], 0, 0],
        },
    },

    button: {
        position: 'relative',
        margin: [0, 0, 0, -spaces[2]],
        zIndex: 4,
        pointerEvents: 'none',
        '-webkit-appearance': 'none',

        '&:first-child': {
            marginLeft: 0,
        },

        '&.square': {
            height: 124,
            minHeight: 124,
            maxHeight: 124,
        },
    },

    developer: {
        maxHeight: 60,
        width: 'auto',
        verticalAlign: 'middle',
        pointerEvents: 'none',

        '&.square': {
            maxHeight: 60,
        },
    },
})