import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import is from 'react-jss'

import Panel from '../../Panel'
import Button from '../../Button'
import RandomText from '../../RandomText'

import style from './style'

const GameHeader = ({
    classes,
    content: {
        foreground,
        background,
    },
    data: {
        title,
        gameFields: {
            logo,
            developer,
        },
    }}) => {

    const [ loaded, setLoaded ] = useState(false)
    let arr = [1]

    useEffect(() => {
        setTimeout(() => {
            setLoaded(true)
        }, 200)
    }, [ loaded ])

    let developerType = 'default'
    if (
        developer.mediaDetails &&
        developer.mediaDetails.width &&
        developer.mediaDetails.height &&
        (developer.mediaDetails.width / developer.mediaDetails.height) >= 0.5 &&
        (developer.mediaDetails.width / developer.mediaDetails.height) <= 1.5
    ) {
        developerType = 'square'
	}
    
    return (
        <Panel image={background.sourceUrl} classNames={{ panel: cx(classes.panel, { loaded }), background: classes.background, inner: classes.inner }}>
            <div className={classes.header}>
                <img src={foreground.sourceUrl} className={classes.foreground} />
                <div className={classes.text}>
                    { logo && logo.sourceUrl && (
                        <img src={logo.sourceUrl} className={classes.logo} />
                    ) }

                    <h1 className={classes.title}>
						<RandomText {...{
							animate: true,
							full: true,
							useOwnLetters: true,
							// letters: title.split('').filter((c, i) => title.split('').indexOf(c) === i).join(),
						}}>{ title }</RandomText>
					</h1>
                    
                    { developer && developer.sourceUrl && arr && (
                        <div className={classes.buttons}>
                            <Button className={ cx(classes.button, 'no-hover') }>
                                <span className="gradient-text">Developer</span>
                            </Button>
                            {arr.map((item, i ) => (
                                <React.Fragment key={i}>
                                    <Button color="black" className={ cx(classes.button, 'no-hover') }>
                                        <img src={developer.sourceUrl} className={ cx(classes.developer, developerType) } />
                                    </Button>
                                </React.Fragment>
                            ))}
                        </div>
                    ) }
                </div>
            </div>
        </Panel>
    )
}

export default is(style)(GameHeader)