import React from 'react'
import cx from 'classnames'
import LazyLoad from 'react-lazyload'
import is from 'react-jss'

import style from './style'

const StaticImage = ({
    classes,
    content: {
        image,
        subtitle,
        credit,
        hasCredit,
        ...content
    },
}) => image ? (
    <div className={cx(classes.column)}>
        <div className={cx('container')}>
            <div className={cx('row', 'justify-center')}>
                <div className={cx(classes.image)}>
                    <LazyLoad offset={200} height={100}>
                        <img src={image.sourceUrl} />
                    </LazyLoad>

                    { hasCredit ? (
                        <div className={cx(classes.leftRight)}>
                            <div className={cx(classes.left)}>{ subtitle }</div>
                            <div className={cx(classes.right)}>
                                <span>Image Credit:</span>
                                <br />
                                <span>{ credit }</span>
                            </div>
                        </div>
                    ) : (
                        <div className={cx(classes.centre)}>
                            { subtitle }
                        </div>
                    )}
                </div>
            </div>
        </div>
    </div>
) : null

export default is(style)(StaticImage)