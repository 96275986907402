import React, { useState } from 'react'
import cx from 'classnames'
import LazyLoad from 'react-lazyload'
import is from 'react-jss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useVideoState } from '../../../context'

import Link from '../../Link'
import Button from '../../Button'
import Legals from '../../Legals'

import style from './style'

const Video = ({
    classes,
    content: {
        title = 'Download Now',
        game: {
            platforms,
            gameFields: {
                videoThumbnail,
				video,
				legals,
				links,
				...gameFields
            },
        },
	},
}) => {

	const { enableVideoPlayer } = useVideoState()

	const sort = [
		'ios-app-store',
		'google-play',
		'microsoft-store',
		'steam',
		'samsung-galaxy-store',
		'amazon-app-store',
		'mac-app-store',
		'tiltingpoint-launcher-xsollasitebuilder'
	]

    return [
		(
			<div key="video" className={classes.video}>
				<div className="container container--wide">
					<div className="row tac lg-tal">
						<div className="col-xs-12 col-lg-4">
							<div className={classes.left}>
								<h2 className={classes.title}><span>{ title }</span></h2>

								{ platforms.nodes.length > 0 && (
									<div className={classes.platforms}>
										<h3 className={classes.platformsTitle}><span className="gradient-text">Platforms</span></h3>

										<ul className={classes.platformsList}>
											{ platforms?.nodes.slice().sort((a, b) => (sort.indexOf(a?.slug) - sort.indexOf(b?.slug))).map(({ slug, platformFields }, i) => (
												links[ slug.replace(/-([a-z])/g, g => g[1].toUpperCase()) ] ? (
													<li key={i} className={classes.platform}>
														<a {...{
															href: links[ slug.replace(/-([a-z])/g, g => g[1].toUpperCase()) ],
															target: "_blank",
															rel: "noopener",
														}}>
															<LazyLoad offset={200} height={100}>
																<img src={platformFields.icon.sourceUrl} alt={slug} className={classes.platformImage} />
															</LazyLoad>
														</a>
													</li>
												) : (
													<li key={i} className={classes.platform}>
														<LazyLoad offset={200} height={100}>
															<img src={platformFields.icon.sourceUrl} alt={slug} className={classes.platformImage} />
														</LazyLoad>
													</li>
												)
											)) }
										</ul>
									</div>
								) }
							</div>
						</div>
						<div className={ cx('col-xs-12 col-lg-8', classes.imageContainer) }>
							<div className={classes.image} style={{ backgroundImage: `url(${videoThumbnail && videoThumbnail.sourceUrl})`}}>
								{ video?.mediaItemUrl && (
									<Button className="no-hover" className={classes.button} clickHandler={ e => enableVideoPlayer({ video: video.mediaItemUrl }) }>
										<FontAwesomeIcon icon={[ 'far', 'play-circle' ]} color="black" className="icon" />
									</Button>
								)}
							</div>
						</div>
						<div className="col-xs-12">
							<Link to="/games/">
								<Button className={classes.backBtn}>
									<span className="gradient-text">
										<FontAwesomeIcon icon={[ 'far', 'angle-left' ]} color="black" className={classes.backBtnIcon} />
										Back to our games
									</span>
								</Button>
							</Link>
						</div>
					</div>
				</div>
			</div>
		),
		<Legals key="legals" {...{ legals }} />
	]
}

export default is(style)(Video)