import React from 'react'
import cx from 'classnames'
import htmlParser from 'react-html-parser'
import is from 'react-jss'

import Link from '../../../../Link'
import Button from '../../../../Button'

import style from './style'
import { getImageSourceUrl } from '../../../../../helpers'

const PostArchiveImageRowsRow = ({
	classes,
	row,
	// row: {
	// 	title,
	// 	content,
	// 	slug,
	// 	postFields: {
	// 		offSitePost,
	// 		postUrl,
	// 		thumbnail
	// 	},
	// 	...row
	// },
}) => {

	const image = getImageSourceUrl(row?.postFields?.thumbnail, 'generic_thumbnail')

	return row?.postFields.thumbnail ? (
		<div className={cx(classes.row, 'row')}>
			<div className={cx('col-xs-12', 'col-md-6', classes.image)}>
				<img src={ image } />
				<h2 className={cx(classes.title)}>{ row?.title }</h2>
			</div>
			<div className={cx('col-xs-12', 'col-md-6')}>
				<div className={cx(classes.content)}>
					<div className={cx(classes.copy)}>{ htmlParser(row?.content) }</div>

					<div className={cx(classes.button)}>
						{ row?.postFields.offSitePost ? (
							<a {...{ href: row?.postFields.postUrl, target: '_blank', rel :'noreferrer noopener' }}>
								<Button>Learn more</Button>
							</a>
						) : (
							<Link to={`/news/${row?.slug}`}>
								<Button>Learn more</Button>
							</Link>
						)}
					</div>
				</div>
			</div>
		</div>
	) : null
}

export default is(style)(PostArchiveImageRowsRow)