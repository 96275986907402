import React from 'react'
import cx from 'classnames'
import is from 'react-jss'

import PostArchiveImageRowsRow from './PostArchiveImageRowsRow'

import style from './style'

const ImageRows = ({
	classes,
    content: {
		imageRowsContent,
	},
}) => imageRowsContent && imageRowsContent.length > 0 ? (
	<div className={cx(classes.imageRows)}>
		<div className={cx('container')}>
			{ imageRowsContent.map((row, i) => (
				<PostArchiveImageRowsRow {...{ key: i, row: row.post }} />
			))}
		</div>
    </div>
) : null

export default is(style)(ImageRows)