export default ({
    spaces,
    colors,
    helpers,
    breakpoints,
}) => ({
    key: { name: 'ParallaxSliderBackgrounds' },

    backgrounds: {
        width: `calc(150% + ${spaces[4]*2}px)`,
        height: 'calc(100% + 2px)',
        position: 'absolute',
        top: -1,
        right: -spaces[4],

        '&::before': {
            display: 'block',
            width: 3,
            height: '15%',
            backgroundColor: colors.blue,
            position: 'absolute',
            top: '40%',
            left: '10%',
            transform: 'translate3d(-50%, -50%, 19px) rotate(-36deg)',
            transition: 'height ease-in-out .3s, width ease-in-out .3s, transform ease-in-out .3s',
            transitionDelay: '.1s',
            content: '""',
        },

        '&::after': {
            display: 'block',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: colors.tertiary,
            opacity: .65,
            zIndex: 3,
            content: '""',

            [breakpoints.lg]: {
                display: 'none',
            },
        },

        '&.active': {
            '&::before': {
                height: '40%',
                transform: 'translate3d(-50%, -50%, 19px) rotate(-26deg)',
            }
        },

        [breakpoints.md]: {
            width: '150%',
            right: 0,
        },

        [breakpoints.lg]: {
            width: '115%',
            right: spaces[4],
        },
    },

    background: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        transition: 'none',
        overflow: 'hidden',
        backfaceVisibility: 'hidden',
        opacity: 0,

        '&.active': {
            transform: 'translate3d(0, 0, 0)',
            zIndex: 2,
            opacity: 1,
        },

        [breakpoints.lg]: {
            ...helpers.clipRect('0% 0%', '70% 0%', '100% 100%', '30% 100%'),

            '&.active': {
                ...helpers.clipRect('42% 0%', '70% 0%', '100% 100%', '72% 100%'),
            },
        },

        [breakpoints.max]: {
            right: spaces[0],
        },
    },

    image: {
        display: 'block',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate3d(-50%, -50%, 0)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        transition: 'all ease-in-out .75s',
        backfaceVisibility: 'hidden',
        overflow: 'hidden',
    },

    direction0: { // ltr
        '& $background': {
            transform: 'translate3d(-100vw, 0, 0)',
            // ...helpers.clipRect('40% 0%', '40% 0%', '84% 100%', '84% 100%'),
        },

        '& $in': {
            '&.active': {
                transform: 'translate3d(0, 0, 0)',
                // ...helpers.clipRect('6% 0%', '40% 0%', '84% 100%', '50% 100%'),
            },
        },

        '& $out': {
            transform: 'translate3d(120vw, 0, 0)',
            // ...helpers.clipRect('6% 0%', '6% 0%', '50% 100%', '50% 100%'),

            '&.active': {
                transform: 'translate3d(0, 0, 0)',
                // ...helpers.clipRect('6% 0%', '40% 0%', '84% 100%', '50% 100%'),
            },
        },
    },

    direction1: { // rtl
        '& $background': {
            transform: 'translate3d(120vw, 0, 0)',
            // ...helpers.clipRect('6% 0%', '6% 0%', '50% 100%', '50% 100%'),
        },

        '& $in': {
            '&.active': {
                transform: 'translate3d(0, 0, 0)',
                // ...helpers.clipRect('6% 0%', '40% 0%', '84% 100%', '50% 100%'),
            },
        },

        '& $out': {
            transform: 'translate3d(-120vw, 0, 0)',
            // ...helpers.clipRect('40% 0%', '40% 0%', '84% 100%', '84% 100%'),

            '&.active': {
                transform: 'translate3d(0, 0, 0)',
                // ...helpers.clipRect('6% 0%', '40% 0%', '84% 100%', '50% 100%'),
            },
        },
    },

    in: {
        transition: 'transform ease-in-out 1.25s, clip-path ease-in-out 1.25s',
        opacity: 1,
    },

    out: {
        transition: 'transform ease-in-out 1.25s, clip-path ease-in-out 1.25s',
        opacity: 1,
    },
})
