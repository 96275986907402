export default ({
	colors,
	spaces,
}) => ({
	key: { name: 'Legals' },
	
	legals: {
		background: colors.black,
		padding: `${spaces[2]}px 0`,
	},
	column: {
		'& > p': {
			color: colors.darkGrey,
			textAlign: 'left',
			fontSize: '16px',
		}
	}
})