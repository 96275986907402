import React from 'react'
import is from 'react-jss'
import htmlParser from 'react-html-parser'

import Panel from '../../Panel'
import BackgroundText from '../../BackgroundText'

import style from './style'

const CtaText = ({
    classes,
    content,
}) => (
    <Panel>
        <div className={classes.column}>
            <h2 className={classes.title}>{ content.title }</h2>
            <div>{ htmlParser(content.content) }</div>
        </div>

		{ content.backgroundText && (
			<BackgroundText {...{
				vertical: true,
				text: content.backgroundText.map(b => b.line),
				flip: content.flip,
				className: classes.backgroundText
			}} />
		)}
    </Panel>
)

export default is(style)(CtaText)