export default ({
    spaces,
    colors,
    breakpoints,
    typography,
    helpers,
}) => ({
    key: { name: 'CareerFormQuestion' },

    checkboxContainer: {
        margin: [spaces[2], 0, spaces[3]],
    },

    description: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: helpers.hexToRgba(colors.black, .9),
        zIndex: 99,
        opacity: 0,
        visibility: 'hidden',
        pointerEvents: 'none',
        transition: 'all ease-in-out .25s',

        '&.open': {
            opacity: 1,
            visibility: 'visible',
            pointerEvents: 'auto',
        },
    },

    descriptionInner: {
        width: '90%',
        maxWidth: 600,
        height: '90%',
        maxHeight: '90%',
        padding: spaces[2],
        backgroundColor: colors.white,
        borderRadius: 3,
        textAlign: 'center',
        overflowY: 'scroll',
        transform: 'translate3d(0, 10%, 0)',
        transition: 'all ease-in-out .15s',
        opacity: 0,

        '.open &': {
            opacity: 1,
            transform: 'translate3d(0, 0, 0)',
            transitionDelay: '.25s',
        },

        [breakpoints.sm]: {
            width: '85%',
            height: '85%',
            padding: spaces[3],
        },

        '& p, & li': {
            ...typography.small,
        },
    },

    descriptionTrigger: {
        fontWeight: 600,
        color: colors.white,
        cursor: 'pointer',
    },

    descriptionClose: {
        margin: [spaces[3], 0, 0],
    },

    descriptionLabel: {
		display: 'block',
		position: 'relative',
		paddingLeft: '35px',
		margin: [spaces[1], 0, 0],
		cursor: 'pointer',
        fontSize: 16,
        lineHeight: '26px',
		userSelect: 'none',

		'& input': {
			position: 'absolute',
			opacity: 0,
			cursor: 'pointer',
			height: 0,
			width: 0,
		},

		'& span': {
			position: 'absolute',
			top: 0,
			left: 0,
			height: 25,
			width: 25,
			backgroundColor: colors.tertiary,
			borderRadius: 5,
			transition: 'all ease .15s',

			'&:after': {
				content: '""',
				position: 'absolute',
				display: 'none',

				left: 9,
				top: 5,
				width: 5,
				height: 10,
				border: 'solid white',
				borderWidth: '0 3px 3px 0',
				transform: 'rotate(45deg)',
			},
		},

		'&:hover input ~ .checkmark': {
			backgroundColor: colors.secondary,
		},
		'& input:checked ~ .checkmark': {
			// backgroundColor: colors.primary,

			'&:after': {
				display: 'block',
			},
		},
	},
})
