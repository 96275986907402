import React from 'react'
import cx from 'classnames'
import LazyLoad from 'react-lazyload'
import is from 'react-jss'

import style from './style'

const Backgrounds = ({
    classes,
    images = [],
    index,
    prevIndex,
    direction,
}) => (
    <div className={ cx(classes.backgrounds, classes[`direction${direction}`] || classes.direction1) }>
        { images.map(({ image }, i) => (
            <div
				key={i}
                className={ cx(
                    classes.background, {
                        active: index === i,
                        [classes.out]: prevIndex === i,
                        [classes.in]: index === i,
                    },
                ) }
            >
                <LazyLoad offset={200} height={0}>
                    <span className={classes.image} style={{ backgroundImage: `url(${image})` }} />
                </LazyLoad>
            </div>
        )) }
    </div>
)

export default is(style)(Backgrounds)
