import React from "react"
import is from "react-jss"

import style from "./style"

const GamesList = ({ classes, content }) => {
  const groupedByTitle =
    content?.gamelist?.length &&
    content?.gamelist.reduce((acc, item) => {
      if (!acc[item.title] && item.title) {
        acc[item.title] = { ...item, gamelist: [] }
      }
      const uniqueGames = item.gamelist.reduce((gamesAcc, game) => {
        if (gamesAcc && !gamesAcc.some(g => g.gamename === game.gamename)) {
          gamesAcc.push(game)
        }
        return gamesAcc
      }, acc[item.title]?.gamelist)
      if (acc[item.title]) {
        acc[item.title].gamelist = uniqueGames
      }

      return acc
    }, {})

  const result = Object.values(groupedByTitle)
  if (!result || result.length === 0) {
    return null
  }
  return (
    <div className={classes.gameSection}>
      <div className={classes.gameContainer}>
        <h1 className={classes.gameContainerTitle}>ALL GAMES</h1>
        <div className={classes.gameColumns}>
          {result.length &&
            result.map(item => (
              <div className={classes.gameBlock} key={item.title}>
                <p className={classes.gameSubtitle}>{item.title}</p>
                <ul className={classes.gameList}>
                  {item?.gamelist?.length &&
                    item.gamelist.map(game => {
                      return (
                        <li className={classes.gameListLi} key={game.gamename}>
                          <a href={game.gamelink} className={classes.gameIcon}>
                            <img src={game.gameimage.sourceUrl} alt="" />
                          </a>
                          <p className={classes.gameName}>{game.gamename}</p>
                        </li>
                      )
                    })}
                </ul>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default is(style)(GamesList)
