import React from 'react'
import cx from 'classnames'
import is from 'react-jss'

import RandomText from '../../../../RandomText'

import style from './style'

const Titles = ({
    classes,
    index,
    titles,
}) => (
    <div className={classes.titles}>
        { titles.map((title, i) => (
            <h2 key={i} className={ cx(classes.title, { active: index === i }) }>
                <RandomText {...{
					speed: 20,
					full: true,
					animate: index === i,
					useOwnLetters: true
				}}>{ title }</RandomText>
            </h2>
        )) }
    </div>
)

export default is(style)(Titles)
