export default ({
    spaces,
    colors,
    breakpoints,
}) => ({
    key: { name: 'Video' },

    video: {
        padding: [spaces[5], 0],
        position: 'relative',
        backgroundColor: colors.tertiary,
        overflow: 'hidden',
        
        '&::after': {
            display: 'block',
            width: 300,
            height: 300,
            position: 'absolute',
            top: '100%',
            left: 0,
            transform: 'translate3d(-30%, -60%, 0) scale(.9)',
            border: `3px solid ${colors.secondary}`,
            borderRadius: '100%',
            zIndex: 2,
            content: '""',

            [breakpoints.md]: {
                width: 400,
                height: 400,
                left: -spaces[3],
            },
        },
    },

    title: {
		display: 'block',
		margin: [0, 'auto'],
        position: 'relative',
        width: 300,
        fontSize: 30,
        lineHeight: '32px',
        color: colors.white,

        [breakpoints.md]: {
            margin: 0,
            fontSize: 54,
            lineHeight: '66px',
        },

        '& > span': {
            display: 'block',
            position: 'relative',
            zIndex: 2,
        },

        '&::before': {
            display: 'block',
            width: 2,
            height: '110%',
            position: 'absolute',
            top: 0,
            left: '80%',
            transform: 'translateY(-20%) rotate(-35deg)',
            backgroundColor: colors.primary,
            zIndex: 1,
            content: '""',
		},
    },

    platforms: {
        width: '100%',
        maxWidth: '100%',
    },
    
    platformsTitle: {
        margin: [spaces[3], 0, spaces[2]],
        fontSize: 24,
        lineHeight: '28px',
    },

    imageContainer: {
        marginTop: spaces[3],

        [breakpoints.lg]: {
            marginTop: 0,
        },
    },

    image: {
        width: '100%',
        paddingTop: '56.25%',
        position: 'relative',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: colors.black,
        cursor: 'pointer',
    },

    button: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },

    left: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        textAlign: 'center',
        
        [breakpoints.lg]: {
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            textAlign: 'left',
        },
    },

    platformsList: {
        maxWidth: 400,
        padding: 0,
        margin: [0, 'auto'],

        [breakpoints.lg]: {
            margin: 0,
        },
    },

    platformsList: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        padding: 0,
    },

    platform: {
        margin: [spaces[1], spaces[1], 0, 0],
        position: 'relative',
        listStyle: 'none',
        zIndex: 3,

        '&::before': {
            display: 'none',
        },
    },

    platformImage: {
        width: '100%',
        height: 'auto',
        minWidth: 100,
        maxWidth: 120,
        verticalAlign: 'top',
    },

    backBtn: {
        margin: [spaces[4], 0, 0],
        position: 'relative',
        zIndex: 30,

        '&:hover': {
			'& .gradient-text': {
				background: 'transparent',
				filter: 'none',
				color: colors.white,
				'-webkit-background-clip': 'none',
				backgroundClip: 'none',
			},
		},
    },

    backBtnIcon: {
		margin: [0, spaces[1], 0, 0],
		fontSize: 22,
		color: colors.primary,
		verticalAlign: 'middle',
		transform: 'translateY(-2px)',
	},
})