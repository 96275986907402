import React from "react"
import cx from "classnames"
import is from "react-jss"

import style from "./style"

const Dot = ({
  classes,
  i,
  content: { copy },
  red = true,
  last = false,
  show = false,
  handleAutomatedHover,
}) => {
  const doHover = state => {
    handleAutomatedHover(i, red, !state)
  }

  return (
    <div
      className={cx(classes.dot, {
        [classes.red]: red === true,
        [classes.yellow]: red === false,
        [classes.front]: show,
      })}
    >
      <div
        {...{
          onClick: e => doHover(true),
          onPointerEnter: e => doHover(true),
          onPointerOut: e => doHover(false),
          className: cx(classes.interactable),
        }}
      >
        <div
          className={cx(classes.gradient, {
            [classes.hover]: show,
          })}
        >
          <div className={classes.inner}></div>
        </div>

        <div
          className={cx(classes.label, {
            [classes.hover]: show,
            [classes.last]: last,
          })}
        >
          <h4>{copy}</h4>
        </div>
      </div>
    </div>
  )
}

export default is(style)(Dot)
