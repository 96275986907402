export default ({ colors, spaces, typography, breakpoints, gradients }) => ({
  key: { name: "ContactDefault" },

  contact: {
    position: "relative",
    backgroundColor: colors.lightTertiary,
    color: colors.white,
    overflow: "hidden",

    padding: [spaces[5], 0],

    textAlign: "center",

    [breakpoints.md]: {
      textAlign: "left",
    },

    "&:before": {
      display: "block",
      position: "absolute",
      content: '""',
      transition: "opacity ease-in-out .15s",

      width: "250%",
      height: "75%",
      bottom: 0,
      left: "-50%",
      background: gradients.radial.bottom.pink,
    },
  },

  left: {
    marginBottom: spaces[3],

    [breakpoints.md]: {
      marginBottom: 0,
    },
  },

  title: {
    color: colors.white,
    marginBottom: spaces[3],

    [breakpoints.md]: {
      marginBottom: spaces[5],
    },
  },

  subtitle: {
    color: colors.primary,
    marginBottom: 0,
  },

  careers: {
    margin: [spaces[2], 0, 0],
    color: colors.primary,
  },

  careersLink: {
    display: "block",
    fontWeight: 400,
    color: colors.white,

    "&:hover": {
      color: colors.primary,
    },
  },

  email: {
    ...typography.p,
    color: colors.white,

    "&:hover": {
      color: colors.primary,
    },
  },

  form: {
    display: "flex",
    flexDirection: "column",
    opacity: 1,
    transformOrigin: "center",
    transition: "all .15s ease-in-out",

    "&$loading": {
      transform: "scale(.9)",
      opacity: 0.5,
      pointerEvents: "none",
      userSelect: "none",
    },
  },

  input: {
    ...typography.p,
    backgroundColor: colors.tertiary,
    color: colors.white,
    padding: spaces[2],
    border: `2px solid ${colors.tertiary}`,

    marginBottom: `${spaces[1]}px !important`,
    borderRadius: spaces[0] / 2,

    "&::placeholder": {
      color: colors.white,
      opacity: 1,
    },

    "&$error": {
      borderColor: colors.secondary,
    },
  },

  checkboxContainer: {
    ...typography.p,
    backgroundColor: colors.tertiary,
    color: colors.white,
    padding: spaces[2],
    border: `2px solid ${colors.tertiary}`,
    marginBottom: `${spaces[1]}px !important`,
    borderRadius: spaces[0] / 2,

    "&::placeholder": {
      color: colors.white,
      opacity: 1,
    },

    "&$error": {
      borderColor: colors.secondary,
    },
  },

  checkboxListContainer: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "10px",
    "& > div": {
      marginRight: "20px",
    },
  },
  textBoxContainer: {
    position: "relative",

    "& $input": {
      width: "100%",
      lineHeight: 1.5,
      resize: "none",
    },
  },
  submitButtonContainer: {
    position: "relative",
    width: "100%",
  },
  submit: {
    position: "absolute",
    right: 0,
    top: "100%",
  },

  icon: {
    fontSize: 25,
    margin: 0,
  },

  loading: {},
  error: {},
  submitted: {},

  message: {
    display: "block",
    width: "100%",
    margin: [spaces[4], 0, 0],
    fontSize: 18,
    textTransform: "uppercase",
    textAlign: "center",
    fontWeight: 700,

    [breakpoints.md]: {
      margin: [spaces[2], 0, 0],
    },
  },
})
