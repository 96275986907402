export default ({
    spaces,
    breakpoints,
}) => ({
    key: { name: 'PostArchiveFeaturedButtons' },
    
    buttons: {
        display: 'block',
        marginTop: spaces[2],

        [breakpoints.lg]: {
            textAlign: 'center',
        }
    },

    button: {
        display: 'none',

        '&.active': {
            display: 'inline-flex',
        },
    },
})
