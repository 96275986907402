export default ({
    breakpoints,
    spaces,
    helpers,
}) => ({
    key: { name: 'TeamText' },
    
    text: {
        display: 'none',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: [spaces[3], 0, 0],

        '&.active': {
            display: 'flex',
        },

        [breakpoints.md]: {
            margin: 0,   
        },

        [breakpoints.lg]: {
            alignItems: 'flex-start',
        },
    },

    name: {
        display: 'inline-block',
        margin: 0,
        fontSize: 24,
        lineHeight: '28px',
        ...helpers.gradientText,
    },

    jobTitle: {
        display: 'block',
        margin: 0,
        fontSize: 24,
        lineHeight: '28px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
    },

    description: {
        maxWidth: 500,
        margin: [spaces[3], 0, 0],
    },
})
