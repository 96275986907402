export default ({
    colors,
    breakpoints,
	spaces,
	helpers,
}) => ({
    key: { name: 'StaticImage' },
    
    column: {
		padding: [ spaces[4], 0 ],
		backgroundColor: colors.white,
    },

    image: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        '& > img': {
            maxWidth: '80vw',
            maxHeight: '80vh',
            width: '100%',
			height: 'auto',
			
			...helpers.clipCorners.default,

            marginBottom: spaces[1],
    
            [breakpoints.md]: {
                maxWidth: '60vw',
            },
        }
    },

    centre: {
        width: '100%',
        textAlign: 'center',
    },

    leftRight: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',

        fontSize: '11px',
        lineHeight: '13px',

        [breakpoints.md]: {
            fontSize: '13px',
            lineHeight: '15px',
        },

        '& > $left': {
            textAlign: 'left',
            flexBasis: '65%',
        },
        
        '& > $right': {
            textAlign: 'right',
        },
    },

    left: {},
    right: {},
})