export default ({ colors, spaces, helpers, typography, breakpoints }) => ({
  key: { name: "ScaleMomentum" },

  scaleMomentum: {
    padding: [spaces[5], 0],
    position: "relative",
    backgroundColor: colors.tertiary,
    color: colors.white,
    overflow: "hidden",
  },

  title: {
    ...typography.h1,
    display: "block",
    ...helpers.gradientText,
    textAlign: "center",
    marginBottom: spaces[4],

    [breakpoints.md]: {
      marginBottom: spaces[5] + spaces[4],
    },
  },

  powerUp: {
    position: "relative",
    display: "flex",
    flexDirection: "column",

    marginBottom: spaces[3],

    [breakpoints.md]: {
      flexDirection: "row",
      justifyContent: "center",
      height: 150,

      "& > $dotRow": {
        flexGrow: 1,
      },
    },
    [breakpoints.lg]: {
      flexDirection: "row",

      height: 150,

      "& > $dotRow": {
        flexGrow: 1,
        maxWidth: "65%",
        flexShrink: 1,

        "&:before": {
          marginLeft: "2.5rem",
          marginRight: "2.5rem",
          width: "calc(100% - 5rem)",
        },
      },
    },
  },
  dotRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    height: spaces[4],
    flexGrow: 5,

    "&:before": {
      position: "absolute",
      width: "100%",
      height: 1,
      content: '""',
      backgroundColor: colors.grey,
    },
  },
  yellow: {
    margin: "auto",
    marginTop: spaces[3],

    "&:before": {
      [breakpoints.md]: {
        width: "calc(100% - 5rem)",
        left: "2.5rem",
      },
    },

    [breakpoints.md]: {
      marginTop: 0,
    },
  },
  copy: {
    marginTop: spaces[1],
    fontSize: 18,
    textAlign: "center",

    [breakpoints.md]: {
      fontSize: 24,
      textAlign: "left",
    },
  },

  backgroundText: {
    top: 0,
    left: 0,

    [breakpoints.md]: {
      top: "125%",

      "& > span": {
        bottom: "100%",
      },
    },
  },

  button: {
    margin: [spaces[4], 0, 0],
    position: "relative",
    zIndex: 1,
  },

  left: {},
  right: {},
  panelDN: {
    width: "100%",
    overflow: "hidden",
    position: "relative",
    backgroundSize: "cover",
    backgroundColor: "#000034",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    "&:before, &:after": {
      left: "50%",
      width: "150%",
      height: "60%",
      content: '""',
      display: "none",
      opacity: "0.8",
      zIndex: 1,
      position: "absolute",
      transform: "translateX(-50%)",
    },
    "&:before": {
      top: 0,
      display: "block",
      background:
        "-webkit-radial-gradient(center top, ellipse cover, rgba(243, 63, 255, 1) 0%, rgba(243, 63, 255, 0) 65%)",
    },
    "&:after": {
      bottom: 0,
      display: "block",
      opacity: 1,
      background:
        "-webkit-radial-gradient(center bottom, ellipse cover,rgba(1, 196, 254, 1) 0%, rgba(1, 196, 254, 0) 65%)",
    },
    [breakpoints.lg]: {
      height: "100vh",
      minHeight: "900px",
      padding: "80px 0",
    },
  },
  titleDn: {
    [breakpoints.md]: {
      display: "block",
      color: "#ffffff",
      position: "absolute",
      top: "10px",
      left: "50%",
      transform: "translateX(-50%)",
      width: "100%",
      textAlign: "center",
    },
    [breakpoints.xlg]: {
      fontSize: "56px",
      top: "10%",
    },
  },
  backgroundDnR: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    "&:before": {
      bottom: "13%",
      right: "7%",
      width: "3px",
      height: "15%",
      content: '""',
      display: "block",
      position: "absolute",
      transform: "translate3d(-50%, -50%, 19px) rotate(-36deg)",
      transition:
        "height ease-in-out 0.3s, width ease-in-out 0.3s, transform ease-in-out 0.3s",
      backgroundColor: "#6653fd",
      transitionDelay: "0.1s",
      zIndex: 2,
    },
    "&:after": {
      left: "100%",
      width: "400px",
      border: "3px solid #f140ff",
      height: "400px",
      content: '""',
      zIndex: 2,
      position: "absolute",
      top: "-5%",
      transform: "translate3d(-90%, -30%, 0)",
      borderRadius: "100%",
    },
  },
  backgroundDnL: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    "&:before": {
      top: "30%",
      left: "10%",
      width: "3px",
      height: "15%",
      content: '""',
      display: "block",
      position: "absolute",
      transform: "translate3d(-50%, -50%, 19px) rotate(-36deg)",
      transition:
        "height ease-in-out 0.3s, width ease-in-out 0.3s, transform ease-in-out 0.3s",
      backgroundColor: "#6653fd",
      transitionDelay: "0.1s",
    },
    "&:after": {
      left: "13%",
      width: "400px",
      border: "3px solid #f140ff",
      height: "400px",
      content: '""',
      zIndex: 2,
      position: "absolute",
      top: "80%",
      transform: "translate3d(-90%, -30%, 0)",
      borderRadius: "100%",
    },
  },
  containerDn: {
    width: "100%",
    margin: "0 auto",
    padding: " 0 24px",
    maxWidth: "1400px",
    [breakpoints.lg]: {
      height: "100vh",
    },
  },
  innerDn: {
    width: "100%",
    margin: "0 auto",
    padding: "64px 0",
    zIndex: 2,
    position: "relative",
    maxWidth: "1400px",
    textAlign: "center",
    [breakpoints.md]: {
      padding: "100px 0",
    },
    [breakpoints.lg]: {
      top: 0,
      left: "50%",
      width: "100%",
      height: "100%",
      margin: "0 auto",
      display: "flex",
      padding: "0 64px",
      position: "absolute",
      maxWidth: "1400px",
      transform: "translateX(-50%)",
      textAlign: "left",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  devNetwork: {
    display: "flex",
    alignItems: "center",
    background:
      "linear-gradient(to right,  rgba(1,0,50,0.65) 0%,rgba(1,0,50,0) 80%)",
    borderRadius: "75px 0 0 75px",
    color: "#fff",
    height: "520px",
    paddingLeft: "76px",
    [breakpoints.mw.xlg]: {
      display: "block",
      background:
        "linear-gradient(to bottom, rgba(1, 0, 50, 1) 24%, rgba(1, 0, 50, 0) 100%)",
      borderRadius: "75px 75px 0 0",
      height: "auto",
      padding: "76px",
    },
    [breakpoints.mw.lg]: {
      padding: "45px",
    },
    [breakpoints.mw.sm]: {
      padding: "45px 15px",
    },
  },
  devNetworkLogo: {
    flex: "0 0 427px",
    maxWidth: "427px",
    marginRight: "54px",
    img: {
      width: "100%",
    },
    [breakpoints.mw.max]: {
      flex: "0 0 356px",
      maxWidth: "356px",
    },
    [breakpoints.mw.xlg]: {
      maxWidth: "356px",
      margin: "0 auto 50px",
    },
  },
  devNetworkChart: {
    paddingLeft: "56px",
    position: "relative",
    [breakpoints.mw.xlg]: {
      padding: "56px 0 0",
    },
    "&:before": {
      backgroundColor: "#fff",
      boxShadow:
        "0 0 1rem #00f6ff, 0 0 2rem #00f6ff, 0 0 5rem #00f6ff, 0 0 10rem #00f6ff",
      content: '""',
      display: "block",
      width: "3px",
      height: "332px",
      position: "absolute",
      top: "23px",
      left: "-2px",
      [breakpoints.mw.xlg]: {
        width: "100%",
        height: "2px",
        top: 0,
        left: 0,
      },
    },
  },
  devNetworkChartLogo: {
    flex: "0 0 368px",
    width: "368px",
    height: "86px",
    display: "flex",
    alignItems: "center",
    [breakpoints.mw.md]: {
      display: "block",
      width: "auto",
      height: "auto",
    },
    [breakpoints.mw.sm]: {
      img: {
        maxWidth: "319px",
        width: "100%",
      },
    },
  },
  devNetworkChartOne: {
    display: "flex",
    alignItems: "center",
    marginTop: "50px",
    position: "relative",
    textAlign: "left",
    [breakpoints.mw.md]: {
      display: "block",
      paddingLeft: "144px",
      "&:before": {
        backgroundColor: "#fff",
        boxShadow:
          "0 0 1rem #00f6ff, 0 0 2rem #00f6ff, 0 0 5rem #00f6ff, 0 0 10rem #00f6ff",
        content: '""',
        display: "block",
        width: "97px",
        height: "2px",
        position: "absolute",
        top: "50%",
        left: 0,
        transform: "translateY(-50%)",
        zIndex: 2,
      },
      "&:after": {
        backgroundColor: "#fff",
        borderRadius: "100%",
        boxShadow:
          "0 0 1rem #00f6ff, 0 0 2rem #00f6ff, 0 0 5rem #00f6ff, 0 0 10rem #00f6ff",
        content: '""',
        display: "block",
        width: "14px",
        height: "14px",
        position: "absolute",
        top: "50%",
        left: "97px",
        transform: "translateY(-50%)",
        zIndex: 1,
      },
    },
    [breakpoints.mw.sm]: {
      display: "block",
      paddingLeft: "75px",
      "&:before": {
        width: "50px",
      },
      "&:after": {
        left: "50px",
      },
    },
    "&:first-child": {
      marginTop: 0,
    },
  },
})
