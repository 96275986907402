export default ({
    colors,
    breakpoints,
    spaces,
    helpers,
    gradients,
}) => ({
    key: { name: 'PostArchiveCard' },
    
    card: {
        position: 'relative',
        
        '&:hover': {
            '& $inner': {
                transform: `translate3d(0px, -${spaces[3]}px, 0)`,
                transitionDelay: '0s',
            },

            [breakpoints.sm]: {
                '& $inner': {
                    transform: `translate3d(-${spaces[2]}px, -${spaces[3]}px, 0)`,
                },
            },

            '& $image': {
                '&::before, &::after': {
                    opacity: 1,
                },
            },

            '& $overlay': {
                opacity: 0,
            },

            '& $date': {
                maxHeight: 100,
                opacity: 1,
            },

            '& $title': {
                '&::after': {
                    clip: 'rect(0, 800px, 800px, 0px)',
                },
            },

            '& $button': {
                '& > span': {
                    maxWidth: 150,
                },
            },
        },
    },

    inner: {
        width: '100%',
        paddingTop: '75%',
        position: 'relative',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        transition: 'transform ease-in-out .15s',
        transitionDelay: '.35s',

        [breakpoints.md]: {
            paddingTop: '55%',
        }
    },

    image: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        ...helpers.clipCorners.animated,
        transition: 'clip-path ease-in-out .15s',
        transitionDelay: '.15s',
        overflow: 'hidden',

        '&::before, &::after': {
            display: 'block',
            position: 'absolute',
            content: '""',
            opacity: 0,
            transition: 'opacity ease-in-out .15s',
            zIndex: 1,
            pointerEvents: 'none',
        },

        '&::before': {
            width: '250%',
            height: '150%',
            top: '-50%',
            left: '-50%',
            background: gradients.radial.bottom.secondary,
        },

        '&::after': {
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            background: helpers.hexToRgba(colors.secondary, .1),
        },
    },

    overlay: {
        display: 'block',
        width: '250%',
        height: '150%',
        position: 'absolute',
        top: '-50%',
        left: '-100%',
        background: gradients.radial.bottom.tertiary,
        opacity: .6,
        transition: 'opacity ease-in-out .15s',
        content: '""',
    },

    text: {
        display: 'block',
        width: '100%',
        maxHeight: '100%',
        padding: [0, spaces[3]],
        position: 'absolute',
        top: '50%',
        left: 0,
        transform: 'translate3d(0, -50%, 0)',
		textAlign: 'left',
    },

    date: {
        display: 'inline-block',
        maxHeight: 0,
        margin: [0, 0, spaces[0]],
        fontSize: 22,
        fontWeight: 700,
        textTransform: 'uppercase',
        ...helpers.gradientText,
        transition: 'max-height ease-in-out .25s, opacity ease-in-out .5s',
        opacity: 0,
        overflow: 'hidden',
    },

    title: {
        margin: 0,
        position: 'relative',
        fontSize: 20,
        lineHeight: '22px',
        fontWeight: 700,
        textTransform: 'uppercase',
        color: colors.white,

        '.browser:not(.ie) &': {
            color: 'transparent',
            '-webkit-text-stroke-width': '1px',
            '-webkit-text-stroke-color': colors.white,
        },

        '&::after': {
            display: 'block',
            width: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            content: 'attr(data-text)',
            color: colors.white,
            clip: 'rect(200px, 800px, 800px, 0px)',
            transition: 'clip ease-in-out .5s',

            '.active &, .autoActive &': {
                clip: 'rect(20px, 800px, 800px, 0px)',
            },

            '.browser.ie &': {
                display: 'none',
            },
        },

        [breakpoints.sm]: {
            fontSize: 24,
            lineHeight: '26px',
        },

        [breakpoints.lg]: {
            fontSize: 32,
            lineHeight: '34px',
        },
    },

    button: {
        position: 'absolute',
        bottom: -31,
        left: '60%',

        '& > span': {
            transition: 'max-width ease-in-out .25s',
            overflow: 'hidden',
            maxWidth: 0,
        },
    },
})
