import React, { useState, useEffect, useRef } from 'react'
import cx from 'classnames'
import is from 'react-jss'

import { debounce } from '../../../helpers'

import Button from '../../Button'
import CareerFormQuestion from './CareerFormQuestion'

import style from './style'

const CareerForm = ({
	classes,
	data: {
		careerFields: {
			greenhouseId,
			questions: _questions,
			locationQuestions: _locationQuestions,
			...careerFields
		},
	},
}) => {
	const questions = JSON.parse(_questions) ?? []
	const locationQuestions = JSON.parse(_locationQuestions) ?? []

	const [ message, setMessage ] = useState('')
	const [ status, setStatus ] = useState('active')

	const [ query, setQuery ] = useState('')
	const debouncedQuery = debounce(query, 500)
	
	const [ locationResults, setLocationResults ] = useState([])

	const [ coords, setCoords ] = useState({ lat: false, lng: false, place: false })

	const ref = useRef()

	const handleSubmit = async (e) => {
		e.preventDefault()

		let isValid = true
		const form = new FormData(document.getElementById('careerForm'))

		questions.forEach((q, i) => {
			if (q.required && q.fields[0].name && form.get(q.fields[0].name) !== null && form.get(q.fields[0].name) === '') {
				isValid = false
			}
		})

		locationQuestions.forEach((q, i) => {
			if (q.required && q.fields[0].name && form.get(q.fields[0].name) !== null && form.get(q.fields[0].name) === '' && q.fields[0].name !== 'latitude' && q.fields[0].name !== 'longitude') {
				isValid = false
			}
		})

		if (locationQuestions && locationQuestions.length > 0) {
			if (form.get('location') === 'false' || form.get('latitude') === 'false' || form.get('longitude') === 'false') {
				console.log('lat lng missing')
				isValid = false
			}
		}

		const id = form.get('id')

		if (!id) isValid = false

		if (isValid) {
			setStatus('loading')
			
			const options = {
				method: 'POST',
				body: form,
				// headers: {
				// 	'Authorization': `Basic ${Buffer.from('e4232e3b92023fb6e5fc817dc2186b08-2').toString('base64')}`,
				// }
			}

			// delete options.headers['Content-Type']

			let request = await fetch(`${process.env.GATSBY_API_ENDPOINT}/careers/submit/${ id }`, options),
				response = await request.json()

			if (response.success) {
				setStatus('error')
				// setStatus('success')
				setMessage('Thank you for your application.')
			} else {
				setStatus('error')
				setMessage('Sorry, something went wrong. Please try again')
			}
		} else {
			console.log('error')
			setStatus('error')
			setMessage("Please check and complete the form.")
		}
	}

	useEffect(() => {
		const locationSearch = async () => {
			let request = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${ debouncedQuery }.json?access_token=${ process.env.GATSBY_MAPBOX }`, {
				method: 'GET',
			}),
				response = await request.json()

			setLocationResults(response.features && response.features.length > 0 ? response.features.map(f => ({
				lat: f.center[0],
				lng: f.center[1],
				place: f.place_name || f.text,
			})) : [])
		}

		if (debouncedQuery.replace(/\s/g, '').length > 0 && debouncedQuery !== coords.place) locationSearch()
	}, [debouncedQuery])

	useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
				setLocationResults([])
            }
        }
		document.addEventListener('mousedown', handleClickOutside)
		
        return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [ ref ])

	const handleSelection = (l) => {
		setCoords(l)
		setQuery(l.place)
	}

	return questions.length > 0 ? (
		<div className={cx(classes.careerForm)}>
			<div className={cx("container", 'container--wide')}>
				<div className="row">
					<div className="col-xs-12 tac">
						<h2 className={cx(classes.title)}>Apply for this job</h2>
					</div>

					<form id="careerForm" onSubmit={ e => handleSubmit(e) }>
						<div className={cx('col-xs-10', 'col-xs-offset-1', classes.form)}>
							<div className={classes.blur} />

							<div className={cx(classes.formElements)}>
								<div className={classes.left}>
									<h2>Fill our form</h2>
								</div>

								<div className={cx(classes.right, {
									[classes.loading]: status === 'loading' || status === 'success',
								})}>
									<input type="hidden" name="id" defaultValue={`${ greenhouseId }`}/>

									{ questions && questions.map((q, i) => (
										<CareerFormQuestion {...{ key: i, question: q, i, questions, classes }} />
									)) }

									{ locationQuestions && locationQuestions.length > 0 && [
										<div key="location_search" className={classes.locationSearch} {...{ ref }}>
											<input
												type="text"
												name="location_search"
												placeholder="Location"
												value={query}
												className={cx(classes.input, classes.locationInput)}
												autoComplete="off"
												onChange={ e => setQuery(e.target.value) }
											/>

											{ locationResults.length > 0 && coords.place !== query && (
												<div key="location-results" className={classes.locationResults}>
													{ locationResults.map((l, i) => (
														<div key={i} className={classes.locationResult} onClick={ e => handleSelection(l) }>{ l.place }</div>
													)) }
												</div>
											) }
										</div>,

										<input key="latitude" type="hidden" name="latitude" defaultValue={`${ coords.lat }`}/>,
										<input key="longitude" type="hidden" name="longitude" defaultValue={`${ coords.lng }`}/>,
										<input key="location" type="hidden" name="location" defaultValue={`${ coords.place }`}/>,
									]}
								</div>

							</div>

							{ (status === 'error' || status === 'success') && (
								<h4 className={cx(classes.errorMessage)}>{ message }</h4>
							)}
						</div>

						{ status !== 'success' && (
							<div className={cx("col-xs-12", "tac")}>
								<Button {...{
									className: cx(classes.button),
									clickHandler: e => handleSubmit(e)
								}}>Apply</Button>
							</div>
						)}
					</form>
				</div>
			</div>
		</div>
	) : null
}

export default is(style)(CareerForm)