import React, { useState, useEffect } from "react"
import LazyLoad from "react-lazyload"
import is from "react-jss"

import { getImageSourceUrl, withIsVisible } from "../../../helpers"
import { useMenuState } from "../../../context"

import Panel from "../../Panel"
import withSlider from "../../Slider"

import Quotes from "./Quotes"

import style from "./style"

const Partners = ({
  classes,
  isVisible = false,
  index,
  setIndex,
  setAnimated,
  navigation,
  setNavigation,
}) => {
  const {
    options: { partners, quotes },
  } = useMenuState()
  const [indices, setIndices] = useState([0, 0, 0, 0, 0, 0])
  const [loaded, setLoaded] = useState(false)

  const shuffle = () => {
    let newIndices = [...indices]

    for (let i = 0; i < indices.length; i++) {
      if (Math.random() >= 0.6) {
        newIndices[i] = -Math.round(
          (Math.floor((Math.random() * partners.length) / 2) /
            partners.length) *
            100
        )
      }
    }

    setIndices(newIndices)
  }

  useEffect(() => {
    if (!loaded) {
      setLoaded(true)
    }

    let interval = null

    interval = setInterval(() => {
      shuffle()
    }, 2500)

    return () => clearInterval(interval)
  }, [indices, loaded])

  useEffect(() => {
    let interval = null

    interval = setInterval(() => {
      next()
    }, 8000)

    return () => clearInterval(interval)
  }, [index, navigation, isVisible])

  const next = () => {
    if (navigation && isVisible) {
      setIndex(prevState => prevState + 1)
      setAnimated(true)

      if (index === quotes.length - 2) {
        setNavigation(false)

        setTimeout(() => {
          setAnimated(false)
          setIndex(0)
          setNavigation(true)
        }, 8000)
      }
    }
  }

  return partners && partners.length > 0 ? (
    <Panel
      classNames={{ panel: classes.panel, inner: classes.inner }}
      outer={[
        <div key={"logos"} className={classes.logos}>
          {indices.map((col, i) => (
            <div
              key={i}
              className={classes.logoColumn}
              style={{ transform: `translate3d(0, ${indices[i]}%, 0)` }}
            >
              {partners.map(({ title, logo }, j) => (
                <LazyLoad key={j} offset={200} height={200}>
                  <img
                    src={getImageSourceUrl(logo, "parnter_logo")}
                    className={classes.logo}
                    alt={title}
                  />
                </LazyLoad>
              ))}
            </div>
          ))}
        </div>,
      ]}
    >
      <div className={classes.partners}>
        <h2 className={classes.title}>Our partners</h2>
        <Quotes {...{ index, quotes }} />
      </div>
    </Panel>
  ) : null
}

export default is(style)(withSlider(withIsVisible(Partners)))
