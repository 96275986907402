import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tilt from 'react-tilt'
import cx from 'classnames'
import is from 'react-jss'

import Button from '../../../Button'

import style from './style'

const TeamCard = ({
    classes,
    post: {
        headshot,
        socialLinks: {
            facebook,
            instagram,
            linkedin,
            twitter,
            youtube,
        },
    },
    theme = 'light',
    active = false,
    className,
}) => (
    <div className={ cx(classes.card, className, classes[`theme_${theme}`] || classes.theme_light, { active }) }>
        <Tilt className={classes.tilt} options={{ max: 20, reverse: true }}>
            <div className={classes.inner}>
                <span style={{ backgroundImage: `url('${headshot?.sourceUrl}')` }} className={classes.image} />

                <div className={classes.links}>
                    {facebook && (
                        <a href={facebook.url} target="_blank" rel="noreferrer noopener" className={classes.link}>
                            <Button color="black" className="thin">
                                <FontAwesomeIcon icon={[ 'fab', 'facebook-square' ]} color="white" className="icon small" />
                            </Button>
                        </a>
                    ) }

                    {instagram && (
                        <a href={instagram.url} target="_blank" rel="noreferrer noopener" className={classes.link}>
                            <Button color="black" className="thin">
                                <FontAwesomeIcon icon={[ 'fab', 'instagram' ]} color="white" className="icon small" />
                            </Button>
                        </a>
                    ) }

                    {linkedin && (
                        <a href={linkedin.url} target="_blank" rel="noreferrer noopener" className={classes.link}>
                            <Button color="black" className="thin">
                                <FontAwesomeIcon icon={[ 'fab', 'linkedin-in' ]} color="white" className="icon small" />
                            </Button>
                        </a>
                    ) }

                    {twitter && (
                        <a href={twitter.url} target="_blank" rel="noreferrer noopener" className={classes.link}>
                            <Button color="black" className="thin">
                                <FontAwesomeIcon icon={[ 'fab', 'twitter' ]} color="white" className="icon small" />
                            </Button>
                        </a>
                    ) }

                    {youtube && (
                        <a href={youtube.url} target="_blank" rel="noreferrer noopener" className={classes.link}>
                            <Button color="black" className="thin">
                                <FontAwesomeIcon icon={[ 'fab', 'youtube' ]} color="white" className="icon small" />
                            </Button>
                        </a>
                    ) }
                </div>
            </div>
        </Tilt>
    </div>
)

export default is(style)(TeamCard)
