import React from 'react'
import cx from 'classnames'
import is from 'react-jss'

import style from './style'

const ProgressWheel = ({
    classes,
    current = 0,
    max = 10,
    color = 'yellow',
    background = 'black',
    className,
}) => {
    const val = Math.max(0, Math.min(100, current / max * 100))
    const s = 60
    const r = 27
    const c = Math.PI * (r*2)
    const percentage = ((100-val) / 100) * c

    return (
        <svg className={ cx(classes.progressWheel, className, classes[`color_${color}`] || classes.color_yellow, classes[`background_${background}`] || classes.background_black) } viewport={ `0 0 ${s}px ${s}px` } version="1.1" xmlns="http://www.w3.org/2000/svg">
            <circle className={ cx(classes.progressWheelCircle, classes.progressWheelBackground) } r={r} cx={r+ ((s/2)-r) } cy={r+ ((s/2)-r) } fill="transparent" strokeDasharray={ `${c} ${c}` } />
            <circle className={ cx(classes.progressWheelCircle, classes.progressWheelBar) } r={r} cx={r+ ((s/2)-r) } cy={r+ ((s/2)-r) } fill="transparent" strokeDasharray={ `${c} ${c}` } style={{ strokeDashoffset: percentage }} />
        </svg>
    )
}

export default is(style)(ProgressWheel)
