import React from 'react'
import cx from 'classnames'
import htmlParser from 'react-html-parser'
import Tilt from 'react-tilt'
import is from 'react-jss'

import { displayDate, getImageSourceUrl } from '../../../../../helpers'

import Link from '../../../../Link'

import style from './style'

const PostArchiveLatestViewCard = ({
    classes,
    post: {
        title,
		slug,
		date,
		postFields: {
			thumbnail,
			offSitePost,
			postUrl,
		},
    },
    active = false,
    className,
}) => {
	const ButtonWrapper = ({ children }) => offSitePost ? (
		<a {...{ href: postUrl, target: '_blank', rel :'noreferrer noopener', className: classes.cardLink }}>{ children }</a>
	) : (
		<Link {...{ to: `/news/${slug}`, className: classes.cardLink }}>{ children }</Link>
	)

	const thumbnailImage = getImageSourceUrl(thumbnail, 'card_news')

	return (
		<div className={ cx(classes.card, className, { active }) }>
			<Tilt className={classes.tilt} options={{ max: 20, reverse: true }}>
				<div className={classes.cardInner}>
					<span className={classes.line} />
					<div className={classes.inner}>
						<span style={{ backgroundImage: `url('${ thumbnailImage }')` }} className={classes.image} />

						<ButtonWrapper>
							<span className={classes.text}>
								<span className={classes.date}>{ displayDate(date) }</span>
								<h2 className={classes.title} data-text={ htmlParser(title) }>{ htmlParser(title) }</h2>
							</span>
							<div className={classes.btn}>
								<div className="btn no-hover btn--white">
									<span className="btn__inner">
										Read
									</span>
								</div>
							</div>
						</ButtonWrapper>
						<Link to={ `/news/${slug}` } className={classes.cardLink}>
							
						</Link>
					</div>
				</div>
			</Tilt>
		</div>
	)
}

export default is(style)(PostArchiveLatestViewCard)
