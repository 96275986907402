import React from 'react'
import cx from 'classnames'
import LazyLoad from 'react-lazyload'
import is from 'react-jss'

import style from './style'

const PostArchiveFeaturedHighlights = ({
    classes,
    index,
    highlights = [],
}) => (
    <div className={classes.highlights}>
        { highlights.map((images, i) => {
            return images ? (
                <div key={i} className={ cx(classes.highlightList, { active: i === index }) }>
                    { images.map(({ image }, j) => (
                        <LazyLoad key={j} offset={200} height={100}>
                            <img src={image.sourceUrl} className={classes.highlight} />
                        </LazyLoad>
                    )) }
                </div>
            ) : null
        }) }
    </div>
)

export default is(style)(PostArchiveFeaturedHighlights)
