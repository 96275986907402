export default ({
    spaces,
    colors,
    breakpoints,
}) => ({
    key: { name: 'Slices' },

    container: {
        padding: 0,
    },

    inner: {
        alignItems: 'flex-start',
        height: '100vh',
        paddingTop: 0,
        paddingBottom: 0,
    },

    title: {
        display: 'none',
        position: 'relative',
        width: 300,
        fontSize: 54,
        lineHeight: '56px',
        textAlign: 'left',
        color: colors.white,
        zIndex: 5,

        '& > span': {
            display: 'block',
            position: 'relative',
            zIndex: 5,
        },

        '&::before': {
            display: 'block',
            width: 2,
            height: '200%',
            position: 'absolute',
            top: 0,
            left: '70%',
            transform: 'translateY(-20%) rotate(-35deg)',
            backgroundColor: colors.blue,
            zIndex: 4,
            content: '""',
        },

        [breakpoints.lg]: {
            display: 'block',
        },
    },

    slices: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        overflow: 'hidden',
    },

    slice: {
        flex: '0 0 auto',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: '50%',
        transform: 'translate3d(-50%, 0, 0)',
        zIndex: 0,

        [breakpoints.sm]: {
            width: '80%',
        },

        [breakpoints.lg]: {
            width: '50%',
        },

        '&.before4, &.before5, &.before6, &.before7, &.before8, &.before9, &.before10, &.before11, &.before12, &.before13, &.before14': {
            transform: 'translate3d(-241%, 0, 0)',
            transition: 'none',
        },
        
        '&.before3': {
            transform: 'translate3d(-197%, 0, 0)',
        },

        '&.before2': {
            transform: 'translate3d(-153%, 0, 0)',
        },
        
        '&.before1': {
            transform: 'translate3d(-109%, 0, 0)',
        },

        '&.active': {
            transform: 'translate3d(-50%, 0, 0)',
            zIndex: 1,

            '& $background': {
                '-webkit-clip-path': 'polygon(0% 0%, 75% 0%, 100% 100%, 25% 100%)',
                'clip-path': 'polygon(0% 0%, 75% 0%, 100% 100%, 25% 100%)',
            },
        },
    
        '&.after1': {
            transform: 'translateX(9%)',
        },
    
        '&.after2': {
            transform: 'translateX(53%)',
        },

        '&.after3': {
            transform: 'translateX(97%)',
        },

        '&.after4, &.after5, &.after6, &.after7, &.after8, &.after9, &.after10, &.after11, &.after12, &.after13, &.after14': {
            transform: 'translateX(221%)',
            transition: 'none',
        },
    },

    animated: {
        transition: 'all ease-in-out .75s',
        transitionDelay: '.35s',

        '& $background': {
            transition: 'all ease-in-out .75s',
            transitionDelay: '.35s',
        },
    },

    sliceInner: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: '50%',
        transform: 'translate3d(-50%, 0, 0)',
        textAlign: 'center',
    },

    background: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        transform: 'translate3d(0, 0, .1)',
        transformOrigin: 'center',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        willChange: 'transition',
        '-webkit-clip-path': 'polygon(15% 0%, 60% 0%, 85% 100%, 40% 100%)',
        'clip-path': 'polygon(15% 0%, 60% 0%, 85% 100%, 40% 100%)',
    },

    activeBackground: {
        opacity: 0,

        '.active &': {
            opacity: 1,
        },
    },

    inactiveBackground: {
        backgroundColor: colors.tertiary,
        opacity: 1,
    },

    sliceTitle: {
        position: 'absolute',
        top: '50%',
        right: '6%',
        transform: 'translate3d(-50%, -50%, 0)',
        opacity: 0,
        transition: 'all ease-in-out .25s',

        '.active &': {
            transform: 'translate3d(0, -50%, 0)',
            opacity: 1,
            transitionDelay: '.75s',
        },
    },

    sliceAddress: {
        width: '50%',
        position: 'absolute',
        bottom: spaces[3],
        left: '36%',
        transform: 'translate3d(25%, -50%, 0)',
        textTransform: 'uppercase',
        fontSize: 16,
        fontWeight: 'bold',
        color: colors.white,
        opacity: 0,
        transition: 'all ease-in-out .75s',

        '.active &': {
            transform: 'translate3d(0, -50%, 0)',
            opacity: 1,
            transitionDelay: '.75s',
        },
    },

    button: {
        width: 300,
    },

    sliceTitleText: {
        fontSize: 24,
        textTransform: 'uppercase',
    },

    prev: {
        display: 'none',
        position: 'absolute',
        zIndex: 3,

        '& > span > span': {
            padding: [0, spaces[2]],
        },

        [breakpoints.sm]: {
            display: 'inline-flex',
            top: spaces[4],
            left: '7%',
        },

        [breakpoints.lg]: {
            left: '22%',
        },

        [breakpoints.xlg]: {
            left: '23.5%',
        },
    },

    next: {
        display: 'inline-flex',
        position: 'absolute',
        top: '5%',
        right: '15%',
        zIndex: 3,

        '& > span > span': {
            padding: [0, spaces[2]],
        },

        [breakpoints.sm]: {
            top: 'auto',
            bottom: spaces[4],
            right: '7%',
        },

        [breakpoints.lg]: {
            right: '22%',
        },

        [breakpoints.xlg]: {
            right: '23.5%',
        },
    },

    icon: {
        fontSize: 25,
    },
})
