import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import is from 'react-jss'

import withSlider from '../Slider'
import Panel from '../Panel'
import ProgressWheel from '../ProgressWheel'

import { withIsVisible } from '../../helpers'

import style from './style'

const TextWheel = ({
    classes,
    isVisible = false,
    title,
    posts = [],
    link = {},
    index,
    setIndex,
    animated,
    setAnimated,
    navigation,
    setNavigation,
    goTo,
    setActive,
    active,
    Text,
    Card,
    color = 'tertiary',
    progressColor = 'yellow',
    progressBackground = 'black',
    gradients = {
        top: 'blue',
        bottom: 'pink',
    },
    className,
}) => {

    useEffect(() => {
        setIndex(0)
    }, [])

    useEffect(() => {
        if (isVisible && !active) {
            setActive(true)
        } else if (!isVisible && active) {
            setActive(false)
        }
    }, [ isVisible ])

    useEffect(() => {
        let interval = null
        if (active) {
            interval = setInterval(() => {
                next()
            }, 8000)
        } else if (!active) {
            clearInterval(interval)
        }

        return () => clearInterval(interval)
    }, [active, index, navigation])

    const next = () => {
        if (navigation) {
            setIndex((prevState) => prevState+1)
            setAnimated(true)

            if (index === (Math.ceil(posts.length) - 1)) {
                setNavigation(false)
    
                setTimeout(() => {
                    setAnimated(false)
                    setIndex(0)
                    setNavigation(true)
                }, 200)
            }
        }
    }
    
    const NavigationItems = () => {
        return posts.map((post, i) => {
            return (i < posts.length) 
            ? <span 
                className={ cx(classes.navigationItem, { active: i === index }) } 
                key={i} 
                onClick={() => goTo(i)} 
                /> 
            : null
        })
    }

    return (
        <Panel
            classNames={{
                panel: cx(
                    classes.panel,
                    classes[`color_${color || 'tertiary'}`],
                    className,
                ),
                inner: classes.inner,
            }}
            gradients={gradients}
            outer={[
                <div key="navigation" className={classes.navigation}><NavigationItems /></div>,
                link.to && link.text ? (
                    <div key="link" className={classes.link}>
                        <Link to={link.to} className="btn btn--white">
                            <span className="btn__inner">{ link.text }</span>
                        </Link>
                    </div>
                ) : null,
                <ProgressWheel
                    key="progressWheel"
                    current={index-1}
                    max={posts.length / 1.2}
                    className={classes.progressWheel}
                    color={progressColor}
                    background={progressBackground}
                />
            ]}
        >
            <div className={ cx( classes.posts, { [classes.animated]: animated }) } style={{ transform: `translate3d(0, -50%, 0) rotate(-${index * 6}deg)` }}>
                { posts.map((post, i) => (
                    <Card key={i} { ...{ index: i, post, active: i === index } } className={classes.card} />
                )) }
            </div>
            <div className={classes.text}>
                { title && (
                    <h2 className={classes.title}><span>{ title }</span></h2>
                ) }

                { posts.map((post, i) => (
                    <Text className={classes.description} key={i} { ...{ index: i, post, active: i === index }} />
                )) }
            </div>
        </Panel>
    )
}

export default is(style)(withIsVisible(withSlider(TextWheel)))
