import React from "react"
import cx from "classnames"
import LazyLoad from "react-lazyload"
import htmlParser from "react-html-parser"
import is from "react-jss"

import BackgroundText from "../../../BackgroundText"
import Panel from "../../../Panel"

import style from "./style"

const PowerUpTeamUp = ({
  classes,
  content: {
    powerupTeamup: { powerupCopy, teamupCopy },
  },
}) => (
  <Panel>
    <div className={cx("container")} style={{ zIndex: 2 }}>
      <div className={cx("row")}>
        <div className={cx("col-xs-12", "col-md-2", classes.row)}>
          <div className={classes.logoHeading}>
            <div className={cx(classes.logo)}>
              <LazyLoad offset={200} height={62}>
                <img src="https://cdn-tiltingpoint-website.tiltingpoint.io/static/powerUp.svg" />
              </LazyLoad>
            </div>
            <h3 className={cx(classes.title, classes.red)}>Acquisition</h3>
          </div>
          <span>
            <ul className={classes.bulletList}>
              <li>Successful gaming studios join the TP family</li>
              <li>
                1st party studios receive full access to cutting-edge marketing
                & monetization tools and our publishing super powers
              </li>
            </ul>
          </span>
        </div>
        <div className={cx("col-xs-12", "col-md-3", classes.dotRowParent)}>
          <div className={cx(classes.dotRow)}>
            <div className={cx(classes.dot, classes.red)}></div>
            <div className={cx(classes.dot, classes.red)}></div>
            <div className={cx(classes.dot, classes.yellow)}></div>
          </div>
        </div>
        <div className={cx("col-xs-12", "col-md-2", classes.row)}>
          <div className={classes.logoHeading}>
            <div className={cx(classes.logo)}>
              <LazyLoad offset={200} height={62}>
                <img src="https://cdn-tiltingpoint-website.tiltingpoint.io/static/teamUp.svg" />
              </LazyLoad>
            </div>

            <h3 className={cx(classes.title, classes.yellow)}>Publishing</h3>
          </div>
          <span>
            <ul className={classes.bulletList}>
              <li>Value add to our acquisitions</li>
              <li>
                1st party studios receive BD access to top-tier IPs & brands
              </li>
              <li>
                Multiple alternative distribution opportunities, including new
                platforms & subscription services (e.g. Nintendo Switch, Apple
                Arcade, Netflix)
              </li>
            </ul>
          </span>
        </div>
        <div className={cx("col-xs-12", "col-md-3", classes.dotRowParent)}>
          <div className={cx(classes.dotRow)}>
            <div className={cx(classes.dot, classes.yellow)}></div>
            <div className={cx(classes.dot, classes.yellow)}></div>
            <div className={cx(classes.dot, classes.blue)}></div>
          </div>
        </div>
        <div className={cx("col-xs-12", "col-md-2", classes.row)}>
          <div className={classes.logoHeading}>
            <div className={cx(classes.logo)}>
              <LazyLoad offset={200} height={62}>
                <img src="https://cdn-tiltingpoint-website.tiltingpoint.io/app/uploads/2023/08/18193834/power-up-team-up-level-up.png" />
              </LazyLoad>
            </div>

            <h3 className={cx(classes.title, classes.blue)}>Cross promotion</h3>
          </div>

          <span>
            <ul className={classes.bulletList}>
              <li>Build a direct relationship with players</li>
              <li>Introduce the new generation of UA & discoverability</li>
              <li>Learn what players want, personalize, and increase LTV</li>
            </ul>
          </span>
        </div>
      </div>
    </div>

    <BackgroundText
      {...{
        text: ["Pillars"],
        className: classes.backgroundText,
      }}
    />
    <BackgroundText
      {...{
        text: ["Pillars"],
        mobile: true,
        className: classes.backgroundText,
      }}
    />
  </Panel>
)

export default is(style)(PowerUpTeamUp)
