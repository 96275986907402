import React, { useState, useEffect } from 'react'
import htmlParser from 'react-html-parser'
import cx from 'classnames'
import is from 'react-jss'

import Panel from '../../Panel'
import Tag from '../../Tag'

import style from './style'

const CareerHeader = ({
    classes,
    content: {
        background,
    },
    data: {
        title,
        careerFields: {
            location,
        },
    },
}) => {
    const [ loaded, setLoaded ] = useState(false)

    useEffect(() => {
        setLoaded(true)
    }, [ loaded ])

    return (
        <Panel
            image={ background && background.sourceUrl ? background.sourceUrl : 'https://cdn-tiltingpoint-website.tiltingpoint.io/app/uploads/2020/05/01165432/f89d93e14aa0656f84098091d6bb7dff.jpg' }
            classNames={ {
                panel: cx(classes.panel, {
                    loaded,
                }),
                background: classes.background,
                inner: classes.inner,
            } }
        >
            <div className={classes.header}>
                <div className={classes.text}>
                    <h1 className={classes.title}><span>{ htmlParser(title) }</span></h1>
                    <Tag className={classes.tag}>{ location }</Tag>
                </div>
            </div>
        </Panel>
    )
}

export default is(style)(CareerHeader)
