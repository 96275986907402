import React from 'react'
import cx from 'classnames'
import htmlParser from 'react-html-parser'
import is from 'react-jss'

import BackgroundText from '../../BackgroundText'

import style from './style'

const CareerDetails = ({
    classes,
    data: {
        title,
        content: postContent,
        // postContent,
        careerFields: {
            backgroundTextLabel,
        },
    },
}) => {
    let backgroundText = ['Job', 'Description']

    if (typeof backgroundTextLabel === 'string') {
        backgroundText = backgroundTextLabel.split(' ')
    }

    return (
        <div className={classes.details}>
            <BackgroundText {...{ text: backgroundText, flip: true, vertical: false, className: classes.backgroundText, white: true }} />

            <div className="container">
                <div className="row">
                    <div className={ cx(classes.content, 'col-xs-12') }>
                        { htmlParser(postContent) }
                    </div>
                </div>
            </div>
		</div>
    )
}

export default is(style)(CareerDetails)
