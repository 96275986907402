import React from 'react'
import LazyLoad from 'react-lazyload'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import is from 'react-jss'

import Button from '../../Button'
import Link from '../../Link'

import style from './style'

const Video = ({
    classes,
    content: {
		title,
		quote,
		name,
		headshot,
		showLower,
		lowerTitle,
		lowerStatistic,
		lowerLabel,
		lowerImage,
        ...content
    },
}) => (
    <div className={cx(classes.gamesDetail)}>
		<div className="container container--wide">
			<div className="row">
				<div className={cx('col-xs-12', classes.backBtnCol)}>
					<Link to="/games/">
						<Button className={classes.backBtn}>
							<span className="gradient-text">
								<FontAwesomeIcon icon={[ 'far', 'angle-left' ]} color="black" className={classes.backBtnIcon} />
								Back to our games
							</span>
						</Button>
					</Link>
				</div>
				<div className="col-xs-12 col-md-3">
					<h1 className={cx(classes.title)}><span>{ title }</span></h1>
				</div>
				<div className={cx('col-xs-12', 'col-md-9', classes.cardParent)}>
					<div className={cx(classes.card)}>
						<div className={cx(classes.cardBackground)}></div>
						<div className={cx(classes.cardInner)}>
							<p>{ quote }</p>
							<p><strong>{ name }</strong></p>
						</div>
					</div>
					{ headshot && (
						<div className={cx(classes.cardImage)}>
							<LazyLoad offset={200} height={150}>
								<img src={ headshot.sourceUrl } />
							</LazyLoad>
						</div>
					)}
				</div>
				{ showLower && (
					<div className={cx('col-xs-12')}>
						<div className={cx(classes.achievements)}>
							<div className={classes.achievementsInner}>
								{ lowerStatistic === null ? (
									<div className={classes.titleText}>
										<h1 className={cx(classes.achievementsTitle)}>{ lowerTitle }</h1>
									</div>
								) : (
									<div className={classes.achievementText}>
										<h2 className={cx(classes.achievementsTitle)}>{ lowerTitle }</h2>
										<h1 className={cx(classes.statistic)}>{ lowerStatistic }</h1>
										<h4 className={cx(classes.lower)}>{ lowerLabel }</h4>
									</div>
								)}

								{ lowerImage && (
									<div className={classes.achievementImage}>
										<LazyLoad offset={200} height={350}>
											<img src={ lowerImage.sourceUrl } />
										</LazyLoad>
									</div>
								)}
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	</div>
)

export default is(style)(Video)