import React from 'react'
import cx from 'classnames'
import is from 'react-jss'
import htmlParser from 'react-html-parser'

import Link from '../../Link'

import style from './style'

const Titles = ({
    classes,
    titles = [],
    index,
}) => {
    const getTitle = (title) => {
        let stat = title.match(/\{\{\{(.*)\}\}\}/)

        return stat
            ? title.replace(stat[0], `<span class="gradient-text">${stat[1]}</span>`)
            : title
    }

    return (
        <div className={classes.titles}>
            { titles.map(({ subtitle, title, slug }, i) => (
                <Link key={i} to={`/games/${slug}/`}>
                    <div className={ cx(classes.title, { active: index === i }) }>
                        <span className={classes.subtitle}>{ subtitle }</span>
                        <h2 className={classes.heading}>{ htmlParser(getTitle(title)) }</h2>
                    </div>
                </Link>
            )) }
        </div>
    )
}

export default is(style)(Titles)
