import React from 'react'

import CareersArchiveDefault from './CareersArchiveDefault'
import CareersArchiveFeatured from './CareersArchiveFeatured'

const CareersArchive = ({
    content: {
        careerArchiveType: type,
        ...content
    },
    ...props
}) => {
    switch (type) {
        case 'default': return <CareersArchiveDefault {...{ content, ...props }} />
        case 'featured': return <CareersArchiveFeatured {...{ content, ...props }} />
        // case 'related': return <CareersArchiveRelated {...{ content, ...props }} />
        default: return <div>CareersArchive: { type || 'false' }</div>
    }
}

export default CareersArchive