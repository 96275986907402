export default ({ colors, spaces, helpers, typography, breakpoints }) => ({
  key: { name: "ScaleMomentumDot" },

  interactable: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",

    height: "2rem",

    pointerEvents: "none",

    [breakpoints.md]: {
      width: "5rem",
      height: "5rem",

      pointerEvents: "auto",
      cursor: "pointer",
    },
  },

  gradient: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    width: "2rem",
    height: "2rem",

    backgroundColor: colors.tertiary,

    transition: "all .15s ease",
    transitionDelay: "0s",

    pointerEvents: "none",

    "&:before, &:after": {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,

      content: '""',
      borderRadius: 100,

      transition: "all .15s ease",
      transitionDelay: "0.15s",
    },

    [breakpoints.md]: {
      "&$hover": {
        width: "5rem",
        height: "5rem",
        transitionDelay: "0.15s",

        "&:before, &:after": {
          transitionDelay: "0s",
        },

        "& > $inner": {
          width: "2.5rem",
          height: "2.5rem",
          transitionDelay: "0.15s",
        },
      },
    },
  },

  inner: {
    position: "relative",
    width: "2rem",
    height: "2rem",

    backgroundColor: colors.tertiary,

    borderRadius: 100,

    transition: "all .15s ease",

    "&:after": {
      position: "absolute",
      top: "50%",
      left: "50%",

      transform: "translate3d(-50%, -50%, 0)",

      width: "0.75rem",
      height: "0.75rem",
      content: '""',
      backgroundColor: colors.grey,
      borderRadius: 100,
    },
  },

  label: {
    position: "absolute",
    top: "-4rem",
    left: "50%",
    height: "4rem",
    "& > h4": {
      minWidth: "150px",
      fontSize: "calc(.00085*100vw + 14.18182px)",
    },
    marginLeft: -4,
    paddingLeft: 4,

    overflow: "hidden",
    pointerEvents: "none",

    "&:before, &:after": {
      position: "absolute",
      top: 4,
      height: 0,
      content: '""',
      transition: "all ease .15s",
    },

    "&:before": {
      left: 4,
      width: 1,

      backgroundColor: colors.grey,
      transition: "all ease .15s",
    },

    "&:after": {
      left: 4,
      top: 4,
      width: 0,
      borderRadius: 100,

      transitionDelay: ".25s",
    },

    "& > *": {
      transform: `translate3d(-100%, 0, 0) translate3d(-${spaces[3]}px, 0, 0)`,
      color: colors.white,
      lineHeight: 1,
      marginLeft: spaces[3],
      transition: "transform ease .5s, opacity ease .15s",
      opacity: 0,
    },

    "&$last": {
      transform: `scale3d(-1, 1, 1) translate3d(-${spaces[0]}px, 0, 0)`,
      transformOrigin: "0% 100%",
      marginRight: -spaces[3],

      "& > *": {
        transform: `scale3d(-1, 1, 1) translate3d(100%, 0, 0) translate3d(${spaces[3]}px, 0, 0)`,
      },
    },

    "&$hover": {
      opacity: 1,

      "&:before": {
        transitionDelay: ".35s",
        height: "calc(4rem - 4px)",
      },

      "&:after": {
        transitionDelay: ".25s",
        top: 0,
        left: 0,
        width: 9,
        height: 9,
      },

      "& > *": {
        transform: `translate3d(0, 0, 0)`,
        transition: "transform ease .15s, opacity ease .5s",
        transitionDelay: ".45s",
        opacity: 1,
      },

      "&$last": {
        "& > *": {
          transform: `scale3d(-1, 1, 1) translate3d(0, 0, 0)`,
        },
      },
    },

    [breakpoints.mw.md]: {
      display: "none",
    },
  },

  red: {
    flexBasis: "auto",

    "& $interactable": {
      marginLeft: 0,
    },

    [breakpoints.md]: {
      flexBasis: "100%",

      "& $interactable": {
        marginLeft: "-2.5rem",
      },
    },

    [breakpoints.md]: {
      "& $gradient": {
        "&$hover": {
          "&:before": {
            boxShadow: `0 0 3px 1rem ${helpers.hexToRgba(
              colors.orange,
              0.125
            )}, 0 0 0 0.75rem ${helpers.hexToRgba(
              colors.orange,
              0.125
            )}, 0 0 0 0.5rem ${helpers.hexToRgba(
              colors.orange,
              0.125
            )}, 0 0 0 0.25rem ${helpers.hexToRgba(colors.orange, 0.125)};`,
          },
          "&:after": {
            boxShadow: `0 0 0 2px ${colors.orange} inset, 0 0 0 4px ${colors.tertiary} inset, 0 0 0 5px ${colors.grey} inset`,
          },
        },
      },
    },

    "& $inner": {
      border: `2px solid ${colors.red}`,
    },

    "& $label": {
      "&:after": {
        backgroundColor: colors.orange,
      },
    },
  },

  yellow: {
    flexBasis: "auto",

    "& $interactable": {
      marginLeft: 0,
    },

    [breakpoints.md]: {
      "& $gradient": {
        "&$hover": {
          "&:before": {
            boxShadow: `0 0 3px 1rem ${helpers.hexToRgba(
              colors.darkYellow,
              0.125
            )}, 0 0 0 0.75rem ${helpers.hexToRgba(
              colors.darkYellow,
              0.125
            )}, 0 0 0 0.5rem ${helpers.hexToRgba(
              colors.darkYellow,
              0.125
            )}, 0 0 0 0.25rem ${helpers.hexToRgba(colors.darkYellow, 0.125)};`,
          },
          "&:after": {
            boxShadow: `0 0 0 2px ${colors.darkYellow} inset, 0 0 0 4px ${colors.tertiary} inset, 0 0 0 5px ${colors.grey} inset`,
          },
        },
      },
    },

    "& $inner": {
      border: `2px solid ${colors.yellow}`,
    },

    "& $label": {
      "&:after": {
        backgroundColor: colors.darkYellow,
      },
    },
  },

  dot: {
    zIndex: 2,

    "&$front": {
      zIndex: 5,
    },

    [breakpoints.mw.md]: {
      "&:not(:first-child):not(:last-child)": {
        "& $gradient": {
          width: "0.75rem",
          height: "0.75rem",
        },

        "& $inner": {
          border: "none",
        },
      },
    },
  },

  front: {},
  hover: {},
  last: {},
})
