import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import is from 'react-jss'
import mediumIcon from '../../../../../../../assets/images/medium.svg'
import mailchimpIcon from '../../../../../../../assets/images/mailchimp.svg'

import Button from '../../../../../Button'

import style from './style'

const SocialIcons = ({
    classes,
    social,
    socialLinks,
}) => {
    const [ loaded ] = useState(true)
    let icons = []

    if (socialLinks?.youtube) icons.push({ href: socialLinks?.youtube?.url, icon: [ 'fab', 'youtube' ] })
    if (socialLinks?.facebook) icons.push({ href: socialLinks?.facebook?.url, icon: [ 'fab', 'facebook-square' ] })
    if (socialLinks?.twitter) icons.push({ href: socialLinks?.twitter?.url, icon: [ 'fab', 'twitter' ] })
    if (socialLinks?.instagram) icons.push({ href: socialLinks?.instagram?.url, icon: [ 'fab', 'instagram' ] })
    if (socialLinks?.linkedin) icons.push({ href: socialLinks?.linkedin?.url, icon: [ 'fab', 'linkedin' ] })
    
    return social && icons.length > 0 ? (
        <div className={ cx(classes.social, { loaded }) }>
            <Button className="no-hover">
                { icons.map(({ href, icon }, i) => (
                    <a {...{ key: i, href, target: '_blank', rel :'noreferrer noopener' }} className={classes.socialLink}>
                        <FontAwesomeIcon {...{ icon, color: 'black', className: classes.socialIcon }} />
                    </a>
                ))}
                    <a className={classes.socialLink} target='_blank' rel='noreferrer noopener' href='https://tiltingpoint.medium.com/'>
                        <img src={mediumIcon} className={classes.socialIcon} alt="" width={20} height={20}/>
                    </a>
                    <a className={classes.socialLink} target='_blank' rel='noreferrer noopener' href='http://eepurl.com/g26V8v'>
                        <img src={mailchimpIcon} className={classes.socialIcon} alt="" width={20} height={20}/>
                    </a>
            </Button>
        </div>
    ) : null
}

export default is(style)(SocialIcons)
