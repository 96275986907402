export default ({
    spaces,
    colors,
    typography,
    breakpoints,
    gradients,
}) => ({
    key: { name: 'GlassdoorReviews' },
    
    glassdoor: {
        position: 'relative',
        backgroundColor: colors.tertiary,
        overflow: 'hidden',

        '&::after': {
            display: 'block',
            width: '150%',
            height: '60%',
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            bottom: -spaces[3],
            background: gradients.radial.bottom.secondary,
            opacity: .8,
            zIndex: 1,
            content: '""',
        },
    },

    inner: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: [spaces[4], 0],
        position: 'relative',
        textAlign: 'center',
        zIndex: 2,

        [breakpoints.md]: {
            padding: 0,
        },

        '&::before, &::after': {
            display: 'block',
            position: 'absolute',
            zIndex: 1,
            content: '""',
        },

        '&::before': {
            width: 240,
            height: 240,
            top: spaces[4],
            left: spaces[4],
            transform: 'translate3d(-50%, -50%, 0)',
            border: `3px solid ${colors.primary}`,
            borderRadius: '100%',
            zIndex: 3,
            content: '""',

            [breakpoints.md]: {
                width: 300,
                height: 300,
            },
        },

        '&::after': {
            width: 2,
            height: '70%',
            right: '30%',
            bottom: '-20%',
            transform: 'rotate(-35deg)',
            backgroundColor: colors.secondary,
        },
    },

    logo: {
        width: '80%',
        maxWidth: 200,
        margin: [0, 'auto', spaces[1]],
        opacity: 0,
        visibility: 'hidden',

        '&.active': {
            opacity: 1,
            visibility: 'visible',
        },
    },

    stars: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: [0, 0, spaces[1]],
        textAlign: 'center',
        zIndex: 3,

        '& .star': {
            marginLeft: spaces[1],
            transform: 'translate3d(0, 0, 0) scale(.5)',
            opacity: 0,
            visibility: 'hidden',
            pointerEvents: 'none',
            transition: 'all ease-in-out .25s',

            '&:first-child': {
                marginLeft: 0,
            },
        },

        '&.active': {
            '& .star': {
                transform: 'translate3d(0, 0, 0) scale(1)',
                opacity: 1,
                visibility: 'visible',
                pointerEvents: 'auto',
                transition: 'all ease-in-out .5s',

                '&:nth-child(2)': {
                    transitionDelay: '.15s',
                },
    
                '&:nth-child(3)': {
                    transitionDelay: '.3s',
                },
    
                '&:nth-child(4)': {
                    transitionDelay: '.45s',
                },
    
                '&:nth-child(5)': {
                    transitionDelay: '.6s',
                },
            },
        },
    },

    reviews: {
        width: '100%',
        height: 140,
        margin: [spaces[5], 0, spaces[1]],
        position: 'relative',
        zIndex: 3,

        [breakpoints.sm]: {
            height: 180,
        },

        [breakpoints.md]: {
            height: 400,
        },
    },

    review: {
        width: '80%',
        maxWidth: 500,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate3d(-50%, -60%, 0)',
    },

    rating: {
        display: 'inline-block',
        margin: [0, 0, spaces[3]],
        position: 'relative',
        fontSize: 32,
        fontWeight: 400,
        color: colors.primary,
        transform: `translate3d(-${spaces[4]}px, 0, 0)`,
        transition: 'all ease-in-out .5s',
        opacity: 0,
        visibility: 'hidden',
        pointerEvents: 'none',
        zIndex: 3,

        '&.active': {
            transform: 'translate3d(0, 0, 0)',
            opacity: 1,
            visibility: 'visible',
            pointerEvents: 'auto',
        },
    },

    reviewText: {
        ...typography.h3,
        margin: 0,
        color: colors.white,
        transition: 'all ease-in-out .5s',
        opacity: 0,
        visibility: 'hidden',
        transform: `translate3d(${spaces[4]}px, 0, 0)`,
        pointerEvents: 'none',
        zIndex: 3,

        '&.active': {
            transform: 'translate3d(0, 0, 0)',
            opacity: 1,
            visibility: 'visible',
            pointerEvents: 'auto',
        },

        [breakpoints.mw.sm]: {
            fontSize: 20,
        },
    },

    progressWheel: {
        margin: [0, spaces[3], spaces[3], 0],
        position: 'relative',
        float: 'right',
        zIndex: 2,

        [breakpoints.md]: {
            position: 'absolute',
            right: spaces[3],
            bottom: spaces[3],
            float: 'none',
        },
    },
})
