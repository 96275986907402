import React from 'react'
import cx from 'classnames'
import is from 'react-jss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useVideoState } from '../../../context'

import Button from '../../Button'

import style from './style'

const Video = ({
    classes,
    content: {
		results,
        ...content
	},
}) => {
	const { enableVideoPlayer } = useVideoState()
	
	return (
		<div className={cx(classes.gameResults)}>
			<div className={cx('container', 'container--wide')}>
				<div className={cx('row', 'justify-center')}>
					{ results.map((r, i) => r.thumbnail ? (
						<div className={cx(classes.result, 'col-xs-12', 'col-md-7', 'col-lg-4')} key={i}>
							<div className={cx(classes.resultInner)}>
								<div className={classes.line} />
								<div className={cx(classes.thumbnail)} style={{ backgroundImage: `url(${ r.thumbnail.sourceUrl })`}}>
									{ r.content && (
										<Button className={cx(classes.button)} clickHandler={ e => enableVideoPlayer({ video: r.content.mediaItemUrl }) }>
											<FontAwesomeIcon icon={[ 'far', 'play-circle' ]} color="black" className={classes.icon} />
										</Button>
									) }
								</div>
							</div>
							<div className={cx(classes.statistic)}>
								<span>{ r.statistic }</span>
								<span>{ r.unit }</span>
							</div>
						</div>
					) : null )}
				</div>
			</div>
		</div>
	)
}

export default is(style)(Video)