export default ({
	helpers,
    colors,
    breakpoints,
	spaces,
}) => ({
	key: { name: 'GameResults' },
	
    gameResults: {
		padding: [0, 0, spaces[4]],
		backgroundColor: colors.black,
	},

	result: {
		marginBottom: spaces[3],

		'&:last-child': {
			marginBottom: 0,
		},

		[breakpoints.lg]: {
			padding: `0 ${spaces[0]}px !important`,
			marginBottom: 0,
		},
	},
	
	resultInner: {
		padding: spaces[3],
		marginBottom: -spaces[3],
		paddingBottom: spaces[4],
		position: 'relative',
        borderRadius: spaces[0]/2,
		...helpers.gradientBackgrounds.tertiarySecondary,
	},

	line: {
		display: 'block',
		width: 3,
		height: '110%',
		backgroundColor: colors.primary,
		position: 'absolute',
		top: '30%',
		left: '65%',
		transform: 'translate3d(-50%, -50%, 0) rotate(-36deg)',
		transitionDelay: '.1s',
		zIndex: 1,
	},

	thumbnail: {
		position: 'relative',
		width: '75%',
		height: 0,

		margin: 'auto',
		paddingTop: '75%',

		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		zIndex: 2,
	},

	button: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate3d(-50%, -50%, 0)',
		fontSize: spaces[3],
		zIndex: 3,

		'&:hover': {
			...helpers.gradientText,
		}
	},

	statistic: {
		color: colors.white,
		backgroundColor: colors.tertiary,
		padding: spaces[1],
		margin: [ 0, spaces[1] ],
		position: 'relative',
		textAlign: 'center',
		borderRadius: spaces[0]/2,
		fontSize: 18,
		lineHeight: '28px',
		fontWeight: 700,
		textTransform: 'uppercase',
		zIndex: 3,
		
		'& > span': {
			'&:first-child': {
				display: 'inline-block',
				marginRight: spaces[0],
				fontSize: 30,
				color: colors.white,
			},
		},
	},

	icon: {
		fontSize: 25,
	},
})