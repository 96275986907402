import React from 'react'
import cx from 'classnames'
import htmlParser from 'react-html-parser'
import is from 'react-jss'

import style from './style'

const ProprietaryTechnology = ({
    classes,
    content: {
        technologies
    },
}) => technologies ? technologies.map((t, i) => (
	<div key={i} className={classes.panel} style={{ backgroundImage: `url(${t.background.sourceUrl})` }}>
		<div className={cx(classes.technologies, classes[t.theme])}>
			<div className={cx('container', 'container--wide')}>
				<div className={cx('row')}>
					<div className={cx('col-xs-12')}>
						<div className={cx(classes.name)}>
							<h2>{ t.name }</h2>
						</div>
					</div>
					<div className={cx('col-xs-12', 'col-lg-6')}>
						<h2 className={cx(classes.title)}>{ t.title }</h2>
						<h4 className={cx(classes.tagline)}>{ t.tagline }</h4>
					</div>
					<div className={cx('col-xs-12', 'col-lg-6', classes.copy)}>{ htmlParser(t.copy) }</div>
					<div className={cx('col-xs-12', classes.image)}>
						<img src={ t.image.sourceUrl } />
					</div>
				</div>
			</div>
		</div>
	</div>
)) : null

export default is(style)(ProprietaryTechnology)