export default ({
    colors,
    breakpoints,
	spaces,
	gradients,
	helpers,
}) => ({
	key: { name: 'GameDetails' },

    gamesDetail: {
		backgroundColor: colors.black,
		position: 'relative',
		padding: [ spaces[5], 0 ],
		overflow: 'hidden',

		'&:before': {
            display: 'block',
            position: 'absolute',
            content: '""',
            transition: 'opacity ease-in-out .15s',
			
			width: '250%',
            height: '50%',
            top: 0,
            left: '-50%',
            background: gradients.radial.top.pink,
        },
	},

	title: {
		color: colors.white,
		marginBottom: spaces[5],
		position: 'relative',
		textAlign: 'center',

		[breakpoints.md]: {
			marginBottom: 0,
			textAlign: 'left',
		},

		'& > span': {
			position: 'relative',
			zIndex: 2,
		},

		'&::before': {
            display: 'none',
            width: 3,
            height: 200,
            backgroundColor: colors.blue,
            position: 'absolute',
            top: '80%',
            left: '80%',
            transform: 'translate3d(-50%, -50%, 0) rotate(-36deg)',
            transition: 'height ease-in-out .3s, width ease-in-out .3s, transform ease-in-out .3s',
            transitionDelay: '.1s',
			content: '""',
			zIndex: 1,

			[breakpoints.md]: {
				display: 'block'
			},
        },
	},

	cardParent: {
		position: 'relative',
	},

	card: {
		position: 'relative',
		marginBottom: spaces[2],
		padding: spaces[3],
	
		[breakpoints.md]: {
			marginLeft: spaces[3],
			marginBottom: spaces[0],
			padding: [spaces[3], spaces[4]],
		},
		
		[breakpoints.lg]: {
			padding: [spaces[4], spaces[5]],
		},
		
		backgroundColor: colors.lightTertiary,

		'-webkit-clip-path': 'polygon(0% 0%, 90% 0%, 100% 30%, 100% 100%, 10% 100%, 0% 70%)',
            'clip-path': 'polygon(0% 0%, 90% 0%, 100% 30%, 100% 100%, 10% 100%, 0% 70%)',
	},

	cardBackground: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		overflow: 'hidden',

		'&:before': {
            display: 'block',
            position: 'absolute',
            content: '""',
            transition: 'opacity ease-in-out .15s',
			width: '250%',
            height: '150%',
            bottom: 0,
            left: '-50%',
			background: gradients.radial.bottom.primary,
			pointerEvents: 'none',
        },
	},

	cardInner: {
		position: 'relative',
		paddingTop: spaces[3],
		textAlign: 'center',

		[breakpoints.md]: {
			marginLeft: spaces[3],
			paddingTop: 0,
			textAlign: 'left',
		},

		[breakpoints.lg]: {
			marginLeft: spaces[5],
		},

		'& p': {
			color: colors.white,
		},
	},

	cardImage: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: 100,
		height: 100,
		position: 'absolute',
		top: 0,
		left: '50%',
		transform: 'translate3d(-50%, -50%, 0)',
	
		'& > img': {
			width: '100%',
			borderRadius: spaces[0] / 2,
		},

		[breakpoints.md]: {
			top: '35%',
			left: spaces[3],
			transform: 'translate3d(0, -50%, 0)',
		},

		[breakpoints.lg]: {
			width: 150,
			height: 150,
		}
	},

	achievements: {
		position: 'relative',

		'&::after': {
            display: 'block',
            width: 300,
            height: 300,
            position: 'absolute',
            top: 0,
            left: 0,
            transform: 'translate3d(-40%, -20%, 0)',
            border: `3px solid ${colors.blue}`,
            borderRadius: '100%',
            zIndex: 1,
            transition: 'opacity ease-in-out .25s, transform ease-in-out .25s',
            content: '""',

            [breakpoints.md]: {
                width: 400,
                height: 400,
                right: -spaces[3],
            },
        },
	},

	achievementsInner: {
		display: 'flex',
		flexDirection: 'column',
		padding: spaces[3],
		position: 'relative',
		backgroundColor: colors.white,
		borderRadius: spaces[0]/2,
		zIndex: 2,

		[breakpoints.sm]: {
			padding: spaces[4],
		},

		[breakpoints.md]: {
			flexDirection: 'row',
		},
	},

	achievementText: {
		flex: 1,
	},

	titleText: {
		flex: 1,
		display: 'flex',
		alignItems: 'center',

		'& h1': {
			margin: 0,
		},
	},

	achievementImage: {
		flex: 1,
		alignSelf: 'center',
		margin: [spaces[2], 0, 0],

		'& img': {
			verticalAlign: 'middle',
		},

		[breakpoints.md]: {
			margin: 0,
		},
	},

	achievementsTitle: {
		margin: [0, 0, spaces[3]],
		fontSize: 18,
		...helpers.gradientText,
		textAlign: 'center',

		[breakpoints.sm]: {
			fontSize: 24,
		},

		[breakpoints.md]: {
			textAlign: 'left',
			fontSize: 30,
		}
	},

	statistic: {
		margin: 0,
		textTransform: 'none',
		textAlign: 'center',

        fontSize: 40,
        lineHeight: 1.11538,

        [breakpoints.xs]: {
            fontSize: 'calc(.045*100vw + 15.27273px)',
        },

		[breakpoints.md]: {
			textAlign: 'left',
		},

        [breakpoints.xlg]: {
            fontSize: 75,
        },
	},
	
	lower: {
		textTransform: 'uppercase',
		width: '80%',
		margin: 'auto',
		...helpers.gradientText,

		textAlign: 'center',

		[breakpoints.sm]: {
			width: '50%',
		},

		[breakpoints.md]: {
			margin: 0,
			textAlign: 'left',
		}
	},

	backBtnCol: {
		display: 'none',
		position: 'relative',

		'&::before': {
            display: 'none',
            width: 2,
            height: '200%',
            position: 'absolute',
            top: '80%',
            right: '15%',
            transform: 'translateY(-50%) rotate(-35deg)',
            backgroundColor: colors.secondary,
            zIndex: 1,
            content: '""',
		},

		[breakpoints.md]: {
			display: 'block',
		},

		[breakpoints.lg]: {
			'&::before': {
				height: '250%',
			},
		},
	},

	backBtn: {
		margin: [0, 0, spaces[4]],

		'&:hover': {
			'& .gradient-text': {
				background: 'transparent',
				filter: 'none',
				color: colors.white,
				'-webkit-background-clip': 'none',
				backgroundClip: 'none',
			},
		},
	},

	backBtnIcon: {
		margin: [0, spaces[1], 0, 0],
		fontSize: 22,
		color: colors.primary,
		verticalAlign: 'middle',
		transform: 'translateY(-2px)',
	},
})