export default ({
    colors,
    breakpoints,
    helpers,
}) => ({
    key: { name: 'FlashingText' },
    
    title: {
        display: 'inline-block',
        position: 'relative',
        fontSize: 70,
        lineHeight: '66px',
        textAlign: 'center',
        color: colors.white,
        pointerEvents: 'none',
        backfaceVisibility: 'hidden',
        '-webkit-backface-visibility': 'hidden',
        transition: 'opacity ease-in-out .25s, visibility ease-in-out .25s',

        [breakpoints.sm]: {
            fontSize: 80,
            lineHeight: '76px',
        },

        [breakpoints.md]: {
            fontSize: 100,
            lineHeight: '96px',
        },

        [breakpoints.lg]: {
            fontSize: 125,
            lineHeight: '100px',
        },

        '&::before': {
            display: 'block',
            width: 240,
            height: 240,
            position: 'absolute',
            top: '100%',
            left: 0,
            border: `3px solid ${colors.primary}`,
            borderRadius: '100%',
            transform: 'translate(-60%, -25%) ',
            transformOrigin: 'center',
            transitionDelay: '.25s',
            zIndex: 1,
            // content: '""',

            [breakpoints.md]: {
                width: 400,
                height: 400,
                top: '85%',
                transform: 'translate(-50%, -50%) scale(1)',
            },
        },
    },

    word: {
        display: 'block',
        position: 'relative',
        color: helpers.hexToRgba(colors.white, .8),
        zIndex: 3,
    },

    active: {
        animation: '$flicker 5s infinite alternate',
    },

    '@keyframes flicker': {
        '0%, 19%, 21%, 23%, 25%, 54%, 56%, 100%': {
            textShadow: `0 0 1rem ${colors.secondary}, 0 0 2rem ${colors.secondary}, 0 0 5rem ${colors.secondary}, 0 0 10rem ${colors.secondary}`,
            color: colors.white,
        },

        '20%, 24%, 55%': {
            textShadow: 'none',
            boxShadow: 'none',
            color: helpers.hexToRgba(colors.white, .7),
        },
    },
})
