import React from 'react'

import GamesArchiveDefault from './GamesArchiveDefault'
import GamesArchiveFeatured from './GamesArchiveFeatured'
import GamesArchiveSlider from './GamesArchiveSlider'

const GamesArchive = ({
    content: {
        gamesArchiveType,
        ...content
    },
    ...props
}) => {
    switch (gamesArchiveType) {
        case 'default': return <GamesArchiveDefault {...{ content, ...props }} />
        case 'featured': return <GamesArchiveFeatured {...{ content, ...props }} />
        case 'slider': return <GamesArchiveSlider {...{ content, ...props }} />
        default: return <div>Games Archive { gamesArchiveType || 'false' }</div>
    }
}

export default GamesArchive