export default ({
    spaces,
    colors,
    breakpoints,
    helpers,
    gradients,
}) => ({
    key: { name: 'CareersArchiveDefaultViewCard' },

    card: {
        flexBasis: '100%',
        height: 0,
        minHeight: 200,
        margin: [spaces[0]/2, 0, spaces[0]/2, 0],
        paddingTop: '45%',
        position: 'relative',

        '&:hover': {
            '& $inner': {
                transform: `translate3d(0px, -${spaces[3]}px, 0)`,
                transformOrigin: '100% 100%',

                [breakpoints.sm]: {
                    transform: `translate3d(-${spaces[2]}px, -${spaces[3]}px, 0)`,
                },
            },

            '& $button': {
                '& > span': {
                    maxWidth: 140,
                },
            },
        },

        [breakpoints.sm]: {
            flexBasis: `calc(50% - ${spaces[0]}px)`,
            margin: spaces[0]/2,
        },

        [breakpoints.md]: {
            paddingTop: '25%',
            transition: 'all .25s ease',
        },
    },

    inner: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',

        padding: spaces[3],
        
        backgroundColor: colors.white,

        transition: 'all .25s ease',
        transitionDelay: '.35s',
        ...helpers.clipCorners.default,
        zIndex: 2,

        '&::before': {
            display: 'block',
            width: '200%',
            height: '50%',
            position: 'absolute',
            bottom: 0,
            left: 0,
            transform: 'translate3d(-25%, 0, 0)',
            background: gradients.radial.bottom.primary,
            opacity: .65,
            transition: 'all ease-in-out .15s',
            content: '""',
            zIndex: 1,
            pointerEvents: 'none',
        },

        '.hovered &': {
            backgroundColor: colors.blue,
            transitionDelay: '0s',
        },
    },

    title: {
        display: 'block',
        width: '100%',
        position: 'relative',
        margin: 0,
        fontSize: 24,
        lineHeight: '28px',
        fontWeight: 700,
        ...helpers.gradientText,
        textAlign: 'left',
        zIndex: 3,
        transition: 'color ease-in-out .25s',
        transitionDelay: '.35s',

        '.hovered &': {
            transitionDelay: '0s',
            color: colors.white,
            background: 'transparent',
        },

        [breakpoints.md]: {
            fontSize: 30,
            lineHeight: '32px',
        },
    },

    location: {
        marginTop: spaces[2],
        position: 'relative',
        zIndex: 3,
        transition: 'all ease-in-out .25s',
        transitionDelay: '.35s',
        ...helpers.gradientBackgrounds.leftRight,

        '& > span': {
            color: colors.white,
        },

        '.hovered &': {
            background: colors.white,
            transitionDelay: '0s',

            '& > span': {
                ...helpers.gradientText,
            },
        },
    },

    button: {
        position: 'absolute',
        bottom: 0,
        left: '100%',
        transform: 'translate3d(-100%, 0, 0)',
        marginLeft: -spaces[2],
        overflow: 'hidden',
        zIndex: 3,

        '& > span': {
            maxWidth: 0,
            maxHeight: 62,
            transition: 'max-width ease-in-out .25s',
            overflow: 'hidden',

            '& > span': {
                textOverflow: 'ellipsis',
                opacity: 0,
                overflow: 'hidden',
                transition: 'opacty ease-in-out .25s',

                '.hovered &': {
                    opacity: 1,
                },
            },
        },

        '.hovered &': {
            opacity: 1,
        },

        [breakpoints.sm]: {
            marginLeft: -spaces[3],
        },
    },
})
