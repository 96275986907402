import React from 'react'
import cx from 'classnames'
import is from 'react-jss'

import style from './style'

const Tag = ({
    classes,
    className,
    size = 'm',
    color = 'gradient',
    children,
}) => (
    <span className={ cx(classes.tag, className, classes[`size_${size}`] || classes.size_m) }>
        <span className={ cx(classes.text, classes[`color_${color}`] || classes.color_gradient) }>{ children }</span>
    </span>
)

export default is(style)(Tag)
