export default ({
    colors,
}) => ({
    key: { name: 'GamesArchiveFeaturedBackgrounds' },

    backgrounds: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
        backgroundColor: colors.tertiary,
    },

    background: {
        display: 'block',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        transform: 'translate3d(100%, 0, 0) scale(1.2)',
        zIndex: 0,

        '&.active': {
            zIndex: 2,
        },
    },

    direction0: { // ltr
        '& $background': {
            transform: 'translate3d(-100%, 0, 0)',
        },

        '& $in': {
            '&.active': {
                transform: 'translate3d(0, 0, 0)',
            },
        },

        '& $out': {
            transform: 'translate3d(100%, 0, 0)',

            '&.active': {
                transform: 'translate3d(0, 0, 0)',
            },
        },
    },

    direction1: { // rtl
        '& $background': {
            transform: 'translate3d(100%, 0, 0)',
        },

        '& $in': {
            '&.active': {
                transform: 'translate3d(0, 0, 0)',
            },
        },

        '& $out': {
            transform: 'translate3d(-100%, 0, 0)',

            '&.active': {
                transform: 'translate3d(0, 0, 0)',
            },
        },
    },

    in: {
        transition: 'transform ease-in-out 1s, background-size ease-in-out .75s',
    },

    out: {
        transition: 'transform ease-in-out 1s, background-size ease-in-out .75s',
    },
})
