export default ({
	colors,
    spaces,
	breakpoints,
	typography,
	fontSizes,
	gradients,
}) => ({
	key: { name: 'CareerForm' },

    careerForm: {
        backgroundColor: colors.blue,

        padding: [ spaces[3], 0 ],

        [breakpoints.md]: {
            padding: [ spaces[4], 0 ],
        }
	},
	
	title: {
		marginTop: spaces[3],
		marginBottom: 0,
		color: colors.white,
	},

	form: {
		position: 'relative',
		backgroundColor: colors.secondary,
		borderRadius: 5,
		margin: [ spaces[3], 'auto' ],
		position: 'relative',

		[breakpoints.md]: {
			margin: [ spaces[4], 'auto' ],
		},
	},

	blur: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		overflow: 'hidden',
		borderRadius: 5,

		'&::before': {
            display: 'none',
            width: '500%',
            height: '60%',
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            content: '""',
            zIndex: 1,
			opacity: .8,
			
            display: 'block',
            bottom: 0,
            background: gradients.radial.bottom.primary,
		},
	},

	button: {
		// ...helpers.gradientText,
		color: colors.black,
	},

	formElements: {
		display: 'flex',
		flexDirection: 'column',
		padding: [ spaces[3], 0 ],

		[breakpoints.md]: {
			padding: [ spaces[4], spaces[3] ],
			flexDirection: 'row',
		},
	},

	left: {
		flexBasis: '37.5%',
		position: 'relative',
		zIndex: 2,
		flexShrink: 0,

		'& h2': {
			color: colors.white,
		},

		[breakpoints.mw.md]: {
			textAlign: 'center',
			marginBottom: spaces[3],
		},

		[breakpoints.md]: {
			maxWidth: 220,
		}	
	},
	right: {
		flexGrow: '1',
		display: 'flex',
		flexDirection: 'column',
		// flexWrap: 'wrap',
		position: 'relative',
		zIndex: 2,
		transition: 'opacity ease .15',

		minWidth: 0,

		'& > *': {
			// flexBasis: '100%',
		},

		'&$loading': {
			transform: 'scale(.9)',
			opacity: .5,
			pointerEvents: 'none',
			userSelect: 'none',
		}
	},

	input: {
		...typography.p,
		backgroundColor: colors.tertiary,
		color: colors.white,
		padding: spaces[2],
		border: `2px solid ${colors.tertiary}`,

		marginBottom: `${spaces[1]}px !important`,
		borderRadius: spaces[0] / 2,

		'&::placeholder': {
			color: colors.white,
			opacity: 1,
		},

		'&$error': {
			borderColor: colors.secondary,
		},

		'& > textarea': {
			...typography.p,
			color: colors.white,
		},
		'& > textarea::placeholder': {
			color: colors.white,
		}
	},

	errorMessage: {
		position: 'relative',
		textAlign: 'center',
		paddingBottom: spaces[4],
		color: colors.white,
		zIndex: 2,
	},

	tempFix: {
		textAlign: 'center',
		paddingTop: spaces[4],
		paddingBottom: spaces[4],
		color: colors.white,
	},

	label: {
		display: 'block',
		margin: [0, 0, spaces[1]],
		color: colors.white,
	},

	fileInput: {
		marginBottom: spaces[1],

		'& label': {
			display: 'block',
		}
	},
	fileInputField: {
		width: 0.1,
		height: 0.1,
		opacity: 0,
		overflow: 'hidden',
		position: 'absolute',
		zIndex: -1,
	},
	fileInputButton: {
		display: 'block',
		position: 'relative',
		cursor: 'pointer',
		outline: 'none',
		['-webkit-appearance']: 'none',
	},
	fileInputButtonRow: {
		[breakpoints.mw.md]: {
			'& > *': {
				width: '100%',

				'&:not(:first-child)': {
					marginTop: spaces[0],
				}
			},
		},

		[breakpoints.md]: {
			display: 'flex',
			flexDirection: 'row',
			
			'& > *': {
				width: '50%',
			},
		},
	},

	findMe: {
		width: '50%',
		alignSelf: 'flex-start',
	},

	locationString: {
		// padding: [ spaces[1], 0],
	},

	locationSearch: {
		position: 'relative',
	},
	
	locationInput: {
		width: '100%',
		zIndex: 2,
	},

	locationResults: {
		position: 'absolute',
		top: '100%',
		left: 0,
		marginTop: -(spaces[1] + (spaces[0] / 2)),
		borderRadius: `0 0 ${spaces[0] / 2}px ${spaces[0] / 2}px`,
		backgroundColor: colors.lightGrey,

		[breakpoints.md]: {
			backgroundColor: colors.white,
		},
	},

	locationResult: {
		padding: [ spaces[0], spaces[1] ],
		cursor: 'pointer',
		userSelect: 'none',
		fontSize: fontSizes.sm,

		'&:last-child': {
			borderRadius: `0 0 ${spaces[0] / 2}px ${spaces[0] / 2}px`,
		},

		'&:hover': {
			backgroundColor: colors.lightGrey,
		}
	},

	loading: {},
	error: {},
})
