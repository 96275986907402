export default ({
	colors,
	spaces,
	helpers,
	breakpoints,
}) => ({
	key: { name: 'OurProprietaryTechnology' },
	
    panel: {
		backgroundColor: colors.tertiary
	},

	foreground: {
		// ...helpers.clipRect('25% 0%', '25% 0%', '75% 100%', '75% 100%'),
		...helpers.clipRect('0% 0%', '50% 0%', '100% 100%', '50% 100%'),
		
		background: `linear-gradient(180deg, ${colors.lightTertiary} 0%, ${colors.secondary} 100%)`,

		width: '65%',
		height: '100%',
		position: 'absolute',
		bottom: 0,
		right: -spaces[4],
	},

	row: {
		justifyContent: 'center',
	},

	title: {
		color: colors.white,
	},

	copy: {
		color: colors.white,

		margin: [ spaces[4], 0 ],

		fontSize: 18,

		[breakpoints.md]: {
			fontSize: 24,

			margin: [ spaces[5], 0 ],
		}
	},

	btn: {
		padding: '0!important',

		[breakpoints.mw.sm]: {
			'& .btn__inner': {
				maxWidth: 275,
			},
		}
	},
})