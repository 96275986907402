export default ({
    spaces,
    gradients,
    breakpoints,
    colors,
}) => ({
    key: { name: 'PostArchiveDefaultView' },

    default: {
        position: 'relative',
        padding: [spaces[5], 0],
		overflow: 'hidden',
		background: colors.white,

        '&::before, &::after': {
            display: 'block',
            width: '150%',
            height: '85vh',
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            content: '""',
            zIndex: 1,
            opacity: .8,
        },

        '&::after': {
            top: -spaces[6],
			background: gradients.radial.top.pink,
		},
		
		'&:not(.hide-bottom)::before': {
			bottom: -spaces[6],
			background: gradients.radial.bottom.primary,
		},
    },

    backgroundText: {
        top: spaces[3],
		left: -spaces[2],
    },

    inner: {
        position: 'relative',
        zIndex: 2,
    },

    title: {
		display: 'block',
        margin: 0,
        padding: 0,
        position: 'relative',
        fontSize: 30,
        lineHeight: '32px',
        textAlign: 'center',
		color: colors.black,
		
		paddingBottom: spaces[4],
    },

    categories: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: [spaces[4], 0],
    },

    category: {
        marginLeft: -spaces[2],

        '&:first-child': {
            marginLeft: 0,
        },
    },

    posts: {
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
		margin: [0, -spaces[0]/2],
		transition: 'all ease-in-out .15s',
		
		'&.loading': {
			opacity: 0.5,

			'& > $post': {
				pointerEvents: 'none',
			}
		}
    },

    post: {
        width: '100%',
        maxWidth: '100%',
		padding: spaces[0]/2,
		transition: 'all ease-in-out .15s',

        [breakpoints.sm]: {
            width: '50%',
            maxWidth: '50%',
        },
    },

    loadMore: {
        margin: [spaces[4], 0, 0],
    },
})
