import React from 'react'
import htmlParser from 'react-html-parser'
import cx from 'classnames'
import is from 'react-jss'

import Panel from '../../../Panel'
import Button from '../../../Button'
import Link from '../../../Link'

import style from './style'

const OurProprietaryTechnology = ({
	classes,
    content: {
		proprietaryTechnology: {
			copy,
		},
	},
}) => (
	<Panel
		image={"https://cdn-tiltingpoint-website.tiltingpoint.io/app/uploads/2020/05/19091715/our-proprietary-technology-bg.jpg"}
		outer={[
			<div key="foreground" className={cx(classes.foreground)}></div>	
		]}
	>
		<div className={cx('container')}>
			<div className={cx('row', classes.row)}>
				<div className={cx('col-xs-12', 'col-sm-10', 'tac')}>
					<h1 className={cx(classes.title)}>Our Proprietary Technology</h1>
				</div>

				<div className={cx('col-xs-12', 'col-md-6', 'tac', classes.copy)}>{ htmlParser(copy) }</div>

				<div className={cx('col-xs-12', 'tac', classes.btn)}>
					<Link to={'/what-we-do/our-tech'}>
						<Button>Learn About Our Tech</Button>
					</Link>
				</div>
			</div>
		</div>
	</Panel>
)

export default is(style)(OurProprietaryTechnology)