export default ({
    colors,
    breakpoints,
    spaces,
}) => ({
    key: { name: 'HeroOverlay' },
    
    panel: {
        backgroundColor: colors.tertiary,

        '&::before': {
            display: 'block',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            transform: 'translate3d(0, 0, 0)',
            zIndex: 2,
            backgroundColor: colors.tertiary,
            opacity: 0,
            transition: 'opacity ease-in-out .25s',
            content: '""',
        },

        '&.loaded': {
            '&::before': {
                opacity: .5,
            },
        },
    },

    inner: {
        zIndex: 4,

        '&::before': {
            display: 'block',
            width: 300,
            height: 300,
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translate3d(0, -60%, 0) scale(.9)',
            border: `3px solid ${colors.primary}`,
            borderRadius: '100%',
            zIndex: 2,
            transition: 'opacity ease-in-out .25s, transform ease-in-out .25s',
            opacity: 0,
            content: '""',

            [breakpoints.md]: {
                width: 400,
                height: 400,
            },

            '.loaded &': {
                transform: 'translate3d(0, -60%, 0) scale(1)',
                opacity: 1,
                transitionDelay: '.5s',
            },
        },
    },

    background: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate3d(-50%, -50%, 0) scale(1.6)',
        transformOrigin: 'center',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        opacity: 0,
        '-webkit-clip-path': 'polygon(50% 0%, 50% 0%, 77.5% 100%, 77.5% 100%)',
        'clip-path': 'polygon(50% 0%, 50% 0%, 77.5% 100%, 77.5% 100%)',
        transition: 'all ease-in-out .5s',
        zIndex: 0,

        '.loaded &': {
            transform: 'translate3d(-50%, -50%, 0) scale(1)',
            '-webkit-clip-path': 'polygon(5% 0%, 60% 0%, 95% 100%, 40% 100%)',
            'clip-path': 'polygon(5% 0%, 60% 0%, 95% 100%, 40% 100%)',
            transitionDelay: '.15s',
            opacity: 1,
            zIndex: 1,
        },
    },

    hero: {
        width: '100%',
        padding: [126, spaces[2], 64],
        position: 'relative',
        textAlign: 'center',

        [breakpoints.lg]: {
            padding: 0,
        },
    },

    title: {
        display: 'inline-block',
        maxWidth: 770,
        margin: [0, 'auto'],
        position: 'relative',
        fontSize: 30,
        lineHeight: '32px',
        color: colors.white,
        zIndex: 4,

        '.browser:not(.ie) &': {
            color: 'transparent',
            '-webkit-text-stroke-width': '1px',
            '-webkit-text-stroke-color': colors.white,
        },

        [breakpoints.sm]: {
            fontSize: 45,
            lineHeight: '47px',
        },

        [breakpoints.md]: {
            fontSize: 54,
            lineHeight: '66px',
        },

        [breakpoints.md]: {
            fontSize: 80,
            lineHeight: '76px',
        },

        [breakpoints.lg]: {
            fontSize: 100,
            lineHeight: '96px',
        },

        '&::after': {
            display: 'block',
            width: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            content: 'attr(data-text)',
            color: colors.white,
            '-webkit-clip-path': 'polygon(0% 50%, 100% 50%, 100% 50%, 0% 50%)',
            'clip-path': 'polygon(0% 50%, 100% 50%, 100% 50%, 0% 50%)',
            transition: 'clip-path ease-in-out .25s',
            transitionDelay: '.5s',

            '.loaded &': {
                '-webkit-clip-path': 'polygon(0% 20%, 100% 20%, 100% 80%, 0% 80%)',
                'clip-path': 'polygon(0% 20%, 100% 20%, 100% 80%, 0% 80%)',
            },

            '.browser.ie &': {
                display: 'none',
            },
        },
    },
})