export default ({
	colors,
	spaces,
	helpers,
	breakpoints,
}) => ({
	key: { name: 'PostArchiveImageRowsRow' },
	
	row: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'row',
		zIndex: 2,
		alignItems: 'center',

		marginTop: spaces[5],
		
		[breakpoints.md]: {
			marginTop: spaces[4],

			'&:nth-child(even)': {
				flexDirection: 'row-reverse',
			},
		},

		'&:first-child': {
			marginTop: 0,
		},
	},

	image: {
		position: 'relative',

		'& img': {
			...helpers.clipCorners.default,
		},
	},

	title: {
		position: 'absolute',
		top: 0,
		left: spaces[3],
		right: spaces[4],
		bottom: 0,

		padding: spaces[3],

		color: colors.white,
	},

	content: {
		width: '75%',
		margin: 'auto',
		marginTop: spaces[3],
		
		[breakpoints.md]: {
			marginTop: 0,
		}
	},

	copy: {
		color: colors.white,

		fontSize: 18,
		marginBottom: spaces[3],

		[breakpoints.md]: {
			fontSize: 24,
		},
	},

	button: {
		display: 'flex',
		justifyContent: 'flex-start',
	}
})