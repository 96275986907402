import React from "react"

import About from "./About"
import CareerDetails from "./CareerDetails"
import CareerForm from "./CareerForm"
import CareerHeader from "./CareerHeader"
import CareersArchive from "./CareersArchive"
import Column from "./Column"
import Contact from "./Contact"
import CtaText from "./CtaText"
import GameDetails from "./GameDetails"
import GameHeader from "./GameHeader"
import GameResults from "./GameResults"
import GamesArchive from "./GamesArchive"
import GlassdoorReviews from "./GlassdoorReviews"
import Hero from "./Hero"
import Locations from "./Locations"
import NewsHeader from "./NewsHeader"
import OurOfferings from "./OurOfferings"
import Partners from "./Partners"
import PostArchive from "./PostArchive"
import ProprietaryTechnology from "./ProprietaryTechnology"
import StaticImage from "./StaticImage"
import Team from "./Team"
import Testimonials from "./Testimonials"
import GamesList from "./GamesList"
import Video from "./Video"
import WhoWeAre from "../WhoWeAre"

const mapType = c => c && c?.__typename?.split("Flexiblecontent_Content_")[1]

const FlexibleFields = ({ content, ...props }) =>
  content && content.length > 0
    ? content.map((c, i) => {
        switch (mapType(c)) {
          case "About":
            return <About {...{ key: i, content: c, ...props }} />
          case "CareerDetails":
            return <CareerDetails {...{ key: i, content: c, ...props }} />
          case "CareerForm":
            return <CareerForm {...{ key: i, content: c, ...props }} />
          case "CareerHeader":
            return <CareerHeader {...{ key: i, content: c, ...props }} />
          case "CareersArchive":
            return <CareersArchive {...{ key: i, content: c, ...props }} />
          case "Column":
            return <Column {...{ key: i, content: c, ...props }} />
          case "Contact":
            return <Contact {...{ key: i, content: c, ...props }} />
          case "CtaText":
            return <CtaText {...{ key: i, content: c, ...props }} />
          case "GameDetails":
            return <GameDetails {...{ key: i, content: c, ...props }} />
          case "GameHeader":
            return <GameHeader {...{ key: i, content: c, ...props }} />
          case "GamesList":
            return <GamesList {...{ key: i, content: c, ...props }} />
          case "GameResults":
            return <GameResults {...{ key: i, content: c, ...props }} />
          case "GamesArchive":
            return <GamesArchive {...{ key: i, content: c, ...props }} />
          case "GlassdoorReviews":
            return <GlassdoorReviews {...{ key: i, content: c, ...props }} />
          case "Hero":
            return <Hero {...{ key: i, content: c, ...props }} />
          case "Locations":
            return <Locations {...{ key: i, content: c, ...props }} />
          case "NewsHeader":
            return <NewsHeader {...{ key: i, content: c, ...props }} />
          case "OurOfferings":
            return <OurOfferings {...{ key: i, content: c, ...props }} />
          case "Partners":
            return <Partners {...{ key: i, content: c, ...props }} />
          case "PostArchive":
            return <PostArchive {...{ key: i, content: c, ...props }} />
          case "ProprietaryTechnology":
            return (
              <ProprietaryTechnology {...{ key: i, content: c, ...props }} />
            )
          case "StaticImage":
            return <StaticImage {...{ key: i, content: c, ...props }} />
          case "Team":
            return <Team {...{ key: i, content: c, ...props }} />
          case "Testimonials":
            return <Testimonials {...{ key: i, content: c, ...props }} />
          case "Video":
            return <Video {...{ key: i, content: c, ...props }} />
          case "WhoWeAreContent":
            return <WhoWeAre {...{ key: i, content: c, ...props }} />
          default:
            return (
              <div key={i}>
                <pre>{JSON.stringify(c?.__typename, false, 4)}</pre>
              </div>
            )
        }
      })
    : null

export default FlexibleFields
