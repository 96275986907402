import React from 'react'

import Default from './Default'
import Small from './Small'
import Newsletter from './Newsletter'

const Contact = ({
    content: {
        contactType: type,
        ...content
    },
    ...props
}) => {
    switch (type) {
        case 'default': return <Default {...{ content, ...props }} />
        case 'small': return <Small {...{ content, ...props }} />
        case 'full': return <Default {...{ content, ...props }} />
        case 'newsletter': return <Newsletter {...{ content, ...props }} />
        default: return null
    }
}

export default Contact