import React, { useState, useEffect } from 'react'

import { useMenuState } from '../../../context'

import Slices from '../../Slices'

const Locations = ({
	content: {
		title,
	},
}) => {
	const { options: { locations }} = useMenuState()
	const [ posts, setPosts ] = useState(locations)

    useEffect(() => {
        let sorted = [
            ...locations,
            ...locations,
        ]

        sorted = [
            sorted[sorted.length-1],
            ...sorted.slice(0, sorted.length-1),
        ]

        setPosts(sorted)
	}, [locations])

	return (
		<Slices
			title={title}
			slices={posts}
		/>
	)
}

export default Locations