export default ({
    colors,
}) => ({
    key: { name: 'ProgressWheel' },
    
    progressWheel: {
        width: 60,
        height: 60,
    },

    progressWheelCircle: {
        strokeDashoffset: 0,
        strokeWidth: 3,
        stroke: colors.darkGrey,
        transition: 'stroke-dashoffset 0.35s',
        transform: 'rotate(-90deg)',
        transformOrigin: '50% 50%',
    },

    progressWheelBackground: {
        strokeWidth: 2,
    },

    progressWheelBar: {
        stroke: colors.yellow,
    },

    color_primary: {
        '& $progressWheelBar': {
            stroke: colors.primary,
        }
    },

    color_secondary: {
        '& $progressWheelBar': {
            stroke: colors.secondary,
        }
    },

    color_yellow: {
        '& $progressWheelBar': {
            stroke: colors.yellow,
        }
    },

    background_black: {
        '& $progressWheelBackground': {
            stroke: colors.black,
        }
    },

    background_white: {
        '& $progressWheelBackground': {
            stroke: colors.white,
        }
    },
})
