import React from 'react'
import cx from 'classnames'
import is from 'react-jss'

import style from './style'

const PostArchiveFeaturedBackgrounds = ({
    classes,
    index,
    loaded,
    images = [],
}) => (
    <div className={ cx(classes.backgrounds, { loaded }) }>
        { images.map((image, i) => (
            <div {...{
				key: i,
				style: { backgroundImage: `url(${image})` },
				className: cx(classes.background, { active: i === index })
			}} />
        )) }
    </div>
)

export default is(style)(PostArchiveFeaturedBackgrounds)
