import React, { useState, useEffect } from 'react'
import is from 'react-jss'

import Wheel from '../../Wheel'
import Card from './Card'

import style from './style'

const Testimonials = ({
    classes,
    content: {
        testimonials,
        theme,
        title,
        description,
    },
}) => {
    const [ posts, setPosts ] = useState(testimonials)

    useEffect(() => {
        let sorted = [
            ...testimonials,
            ...testimonials,
        ]

        sorted = [
            sorted[sorted.length-1],
            ...sorted.slice(0, sorted.length-1),
        ]

        setPosts(sorted)
    }, [testimonials])

    return (
        <Wheel { ...{
            posts,
            Card,
            navigation: true,
            title,
            description,
            color: theme === 'dark' ? 'black' : 'white',
            progressColor: theme === 'dark' ? 'primary' : 'secondary',
            gradients: {
                bottom: 'pink',
            },
            classNames: {
                panel: classes.panel,
                inner: classes.inner,
                posts: classes.posts,
            },
        } } />
    )
}

export default is(style)(Testimonials)
