import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import htmlParser from 'react-html-parser'
import is from 'react-jss'

import Panel from '../../../Panel'

import style from './style'
import { getImageSourceUrl } from '../../../../helpers'

const Tagline = ({
    classes,
    content: {
        title,
        tagline,
        background,
    },
}) => {
    const [ loaded, setLoaded ] = useState(false)

    const backgroundImage = getImageSourceUrl(background, 'panel_background')

    useEffect(() => {
        setLoaded(true)
    }, [ loaded ])

    return (
        <Panel
            classNames={{ panel: cx(classes.panel, { loaded }), inner: classes.inner }}
            outer={[
                <div key="background" className={classes.background} style={{ backgroundImage: `url(${ backgroundImage })` }} />
			]}
        >
            <div className={classes.hero}>
                <h1 className={classes.title}>{ title }</h1>
                <div className={classes.tagline}>{ htmlParser(tagline) }</div>
            </div>
        </Panel>
    )
}

export default is(style)(Tagline)