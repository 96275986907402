import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import is from 'react-jss'

import Panel from '../../../Panel'
import RandomText from '../../../RandomText'

import style from './style'

const Overlay = ({
    classes,
    content: {
        title,
    },
}) => {
    const [ loaded, setLoaded ] = useState(false)

    useEffect(() => {
        setLoaded(true)
    }, [ loaded ])

    return (
        <Panel
            classNames={{ panel: cx(classes.panel, { loaded }), inner: classes.inner }}
            outer={[
                <div key='background' className={classes.background} style={{ backgroundImage: `url(https://cdn-tiltingpoint-website.tiltingpoint.io/app/uploads/2020/05/19092343/overlay-hero-bg.jpg)` }} />
			]}
        >
            <div className={classes.hero}>
                <h1 className={classes.title} data-text={title}><RandomText speed={10} full={true} animate={loaded}>{ title }</RandomText></h1>
            </div>
        </Panel>
    )
}

export default is(style)(Overlay)