export default ({
    spaces,
    breakpoints,
    colors,
}) => ({
    key: { name: 'HeroFullSocialIcons' },
    
    social: {
        width: '100%',
        position: 'absolute',
        left: 0,
        bottom: spaces[2],
        zIndex: 2,
        opacity: 0,
        transform: 'translate3d(25%, 0, 0)',
        textAlign: 'center',
        visibility: 'hidden',
        backfaceVisibility: 'hidden',
        '-webkit-backface-visibility': 'hidden',
        transition: 'transform ease-in-out .25s, opacity ease-in-out .25s, visibility ease-in-out .25s',

        '&.loaded': {
            transform: 'translate3d(0, 0, 0)',
            opacity: 1,
            visibility: 'visible',
        },

        [breakpoints.sm]: {
            width: 'auto',
            left: spaces[3],
            bottom: spaces[3],
        },

        '& > div > span > span': {
            paddingLeft: spaces[2],
            paddingRight: spaces[2],
        },
        [breakpoints.mw.lg]:{
            marginBottom: 90
        }
    },

    button: {
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 62,
            margin: [0, 18], // account for the scew background
            backgroundColor: colors.white,
            // transition: 'transform ease-in-out .1s, color ease-in-out .1s',    
    },

    wwd: {
        [breakpoints.mw.xs60]:{
            marginTop: 15
        }
    }
})
