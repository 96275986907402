export default ({
    spaces,
}) => ({
    key: { name: 'CtaText' },
    
    column: {
        width: 525,
        maxWidth: '100%',
        margin: [0, 'auto'],
        textAlign: 'center',
        zIndex: 2,
    },
    
    title: {
        textTransform: 'uppercase',
        paddingBottom: spaces[3],
    },

    backgroundText: {
        width: 525,
        height: '100%',
        left: '50%',

        '& > span': {
            right: 'calc(100% - 75px)',
            top: '50%',
        }
    }
})