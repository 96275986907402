import React, { useState, useEffect } from 'react'

const Slider = BaseComponent => ({
    ...props
}) => {
    const [ loaded, setLoaded ] = useState(false)
    const [ index, setIndex ] = useState(0)
    const [ prevIndex, setPrevIndex ] = useState(index)
    const [ active, setActive ] = useState(true)
    const [ navigation, setNavigation ] = useState(true)
    const [ direction, setDirection ] = useState(1)
    const [ animated, setAnimated ] = useState(true)

    const pause = () => {
        setActive(false);
    }

    const play = () => {
        setActive(true);
    }

    const goTo = (i) => {
        if (navigation) {
            setAnimated(true)
            setNavigation(false)
            pause()
    
            if (i > index) {
                setDirection(1)
            } else {
                setDirection(0)
            }

            setIndex((prevState) => {
                setPrevIndex(prevState)
                return i
            })
    
            play()

            setTimeout(() => {
                setNavigation(true)
            }, 1000)
        }
    }

    return (
        <BaseComponent 
            { ...{
                loaded,
                setLoaded,
                active,
                setActive,
                index,
                setIndex,
                prevIndex,
                setPrevIndex,
                active,
                setActive,
                navigation,
                setNavigation,
                direction,
                setDirection,
                play,
                pause,
                goTo,
                animated,
                setAnimated,
                ...props
            } }
        />
    )
}

export default Slider
