export default ({
    spaces,
    breakpoints,
    colors,
}) => ({
    key: { name: 'ParallaxSlider' },
    
    panel: {
        backgroundColor: colors.tertiary,
        overflow: 'hidden',
    },

    container: {
        [breakpoints.mw.lg]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },

    inner: {
        height: '100vh',
        paddingTop: 0,

        [breakpoints.sm]: {
            minHeight: 650,
        },
    },

    thumb: {
        position: 'absolute',
        top: '60%',
        right: 0,
        transform: 'translateY(-50%)',
        zIndex: 3,

        [breakpoints.md]: {
            top: '50%',
        },
    },

    text: {
        maxWidth: 680,
        margin: [0, 'auto', spaces[5]],
        padding: [0, spaces[3]],
        position: 'relative',
        textTransform: 'uppercase',
        color: colors.white,
        textAlign: 'left',
        zIndex: 6,

        '&::after': {
            display: 'none',
            width: 400,
            height: 400,
            position: 'absolute',
            top: `calc(100% + ${spaces[6]}px)`,
            left: '50%',
            transform: 'translate3d(-90%, 0, 0)',
            border: `3px solid ${colors.secondary}`,
            borderRadius: '100%',
            zIndex: 2,
            content: '""',

            [breakpoints.md]: {
                display: 'block',
            },
        },

        [breakpoints.lg]: {
            margin: [0, '50%', 0, 0],
            zIndex: 5,

            '&::after': {
                top: '100%',
                transform: 'translate3d(-90%, -30%, 0)',
            },
        },
    },

    link: {
        display: 'none',
        width: 210,
        margin: [spaces[4], 0, 0],
        position: 'relative',
        textAlign: 'center',
        zIndex: 6,

        [breakpoints.lg]: {
            display: 'block',
        },

        [breakpoints.xl]: {
            margin: [spaces[5], 0, 0],
        },
    },

    btn: {
        width: 210,
        margin: [spaces[3], 0, 0],

        [breakpoints.lg]: {
            display: 'none',
        },
    },

    progressWheel: {
        position: 'absolute',
        bottom: spaces[3],
        left: spaces[3],
        zIndex: 3,
    },
})
