import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import is from 'react-jss'

import Panel from '../../Panel'
import Tag from '../../Tag'
import Link from '../../Link'
import Button from '../../Button'
import Image from '../../image'

import { displayDate } from '../../../helpers'

import style from './style'

const NewsHeader = ({
	classes,
	content: {
		title,
		published,
		author,
	},
	categories,
	data
}) => {
	const [ loaded, setLoaded ] = useState(false)

	useEffect(() => {
		setLoaded(true)
	}, [])

	return data?.featuredImage?.node?.sourceUrl ? [
		<Panel
			key="panel"
			outer={[
				<div {...{
					key: 'background',
					className: cx(classes.background),
					style: {
						backgroundImage: `url(${data?.featuredImage?.node?.sourceUrl})`
					},
				}} />
			]}
			classNames={{
				panel: cx(
					classes.panel, {
						loaded,
					},
				),
				inner: classes.inner
			}}
		>
			<div className={classes.header}>
				<div className={classes.text}>
					{ categories && categories.nodes && (
						<Tag>{ categories.nodes[0].name }</Tag>
					) }
					<h1 className={classes.title}>
						<span>{ title }</span>
					</h1>
					<p className={classes.published}><span className="gradient-text">{ displayDate(published) }</span></p>
					<p className={classes.author}>By { author }</p>
				</div>
			</div>
		</Panel>,
		<div key="back" className={classes.back}>
			<div className="container">
				<div className="row">
					<div className="col-xs-12">
						<Link to="/news/">
							<Button color="black" className={classes.backBtn}>
								<FontAwesomeIcon icon={[ 'far', 'angle-left' ]} color="black" className={cx(classes.icon, 'back')} />
								Back to news
							</Button>
						</Link>
					</div>
				</div>
			</div>
		</div>
	] : null
}

export default is(style)(NewsHeader)