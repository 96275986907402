export default ({
    colors,
    spaces,
    helpers,
}) => ({
    key: { name: 'TeamCard' },
    
    card: {
        transition: 'opacity ease-in-out .25s',
        pointerEvents: 'none',

        '&.active': {
            pointerEvents: 'auto',

            '& $image': {
                ...helpers.clipCorners.default,
            },
        },

        '&:hover': {
            '& .btn': {
                '&::before': {
                    transform: 'translate3d(0, 0, 0)',
                },

                '&.icon': {
                    color: colors.white,
                },
            },
        },
    },

    tilt: {
        transformStyle: 'preserve-3d',
        transform: 'perspective(1000px)',
    },

    inner: {
        width: '100%',
        paddingTop: '75%',
        position: 'relative',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        transform: 'scale(.5)',
        transformOrigin: 'center',
        transition: 'transform ease-in-out .25s',

        '.active &': {
            transform: 'scale(1)',
        }
    },

    image: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        ...helpers.clipCorners.animated,
        transition: 'clip-path ease-in-out .15s, grayscale ease-in-out .15s',
        transitionDelay: '.15s',
        filter: 'grayscale(100%)',

        '.active &': {
            filter: 'grayscale(0%)',
        }
    },

    links: {
        position: 'absolute',
        top: '100%',
        right: spaces[3],
        transform: 'translate3d(0, -50%, 0)',
    },

    link: {
        margin: [0, 0, 0, -spaces[2]],

        '&:first-child': {
            marginLeft: 0,
        },
    },
})