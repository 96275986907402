export default ({
    spaces,
    colors,
    helpers,
    breakpoints,
}) => ({
    key: { name: 'Tag' },

    tag: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 700,
        letterSpacing: '.25rem',
        textTransform: 'uppercase',
        textAlign: 'center',
        backgroundColor: colors.white,
        borderRadius: 3,

        [breakpoints.lg]: {
            letterSpacing: '.5rem',
        },
    },

    text: {
        marginRight: -10,
    },
    
    color_gradient: {
        ...helpers.gradientText,
    },
    
    color_tertiary: {
        color: colors.tertiary,
    },

    size_s: {
        height: 32,
        minHeight: 32,
        padding: [0, spaces[1]],
        fontSize: 16,
    },

    size_m: {
        height: 32,
        minHeight: 32,
        padding: [0, spaces[1]],
        fontSize: 16,

        [breakpoints.lg]: {
            height: 42,
            padding: [0, spaces[2]],
            fontSize: 20,
            letterSpacing: '.5rem',
        },
    },
})
