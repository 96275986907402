export default ({
    colors,
    spaces
}) => ({
    key: { name: 'Column' },
    
    column: {
        padding: [ spaces[2], 0 ],
        backgroundColor: colors.white,
    }
})