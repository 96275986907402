import React, { useState } from "react"
import cx from "classnames"
import is from "react-jss"
import { graphql, useStaticQuery } from "gatsby"

import Button from "../../../Button"
import Card from "./Card"

import style from "./style"

const GamesArchiveDefaultView = ({ classes }) => {
  const pageCount = 8

  const [toDisplay, setToDisplay] = useState(pageCount)

  const data = useStaticQuery(graphql`
    query GamesArchive {
      allWpGame(limit: 1000) {
        nodes {
          title
          slug
          featuredImage {
            node {
              sourceUrl
              mediaDetails {
                sizes {
                  name
                  sourceUrl
                }
              }
            }
          }
          platforms {
            nodes {
              slug
              platformFields {
                icon {
                  sourceUrl
                }
              }
            }
          }
          gameFields {
            thumbnail {
              sourceUrl
            }
            video {
              mediaItemUrl
            }
            logo {
              sourceUrl
            }
            legals
          }
        }
      }
    }
  `)

  const loadMore = () => {
    setToDisplay(toDisplay + pageCount)
  }

  return (
    <div className={cx(classes.default)}>
      <div className={cx("container", "container--wide")}>
        <div className={cx("row")}>
          <div className={cx("col-xs-12", "col-lg-3")}>
            <h1 className={cx(classes.title)}>
              <span>
                Portfolio
                <br />
                Highlights
              </span>
            </h1>
          </div>
          <div className={cx("col-xs-12", "col-lg-9", classes.grid)}>
            {data?.allWpGame?.nodes.slice(0, toDisplay).map((game, i) => (
              <Card key={i} index={(i % 8) + 1} game={game} />
            ))}
          </div>
          {data?.allWpGame?.nodes?.length > toDisplay && (
            <div className="col-xs-12 tac">
              <Button
                clickHandler={() => loadMore()}
                className={classes.loadMore}
              >
                <span className="gradient-text">Load More</span>
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default is(style)(GamesArchiveDefaultView)
