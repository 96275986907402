export default ({
    breakpoints,
    spaces,
    colors,
    helpers,
}) => ({
    key: { name: 'ParallaxSliderTitles' },

    titles: {
        padding: 0,
        position: 'relative',
        zIndex: 5,
        textAlign: 'left',
    },

    title: {
        display: 'none',

        '&.active': {
            display: 'block',
        },
    },

    subtitle: {
        display: 'none',
        width: 300,
        maxWidth: '100%',
        fontSize: 24,
        lineHeight: '28px',
        fontWeight: 300,
        color: colors.white,

        [breakpoints.lg]: {
            display: 'block',
        },
    },

    heading: {
        margin: 0,
        padding: [spaces[5], 0, 0],
        fontSize: 30,
        lineHeight: '32px',
        color: colors.white,

        '& > span': {
            display: 'inline-block',
            ...helpers.gradientText,

            [breakpoints.lg]: {
                display: 'block',
            },
        },

        // [breakpoints.sm]: {
        //     fontSize: 45,
        //     lineHeight: '47px',
        // },

        [breakpoints.lg]: {
            fontSize: 54,
            lineHeight: '56px',
        },

        [breakpoints.lg]: {
            padding: [spaces[2], 0, 0],
        },
    },
})
