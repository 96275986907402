import React, { useEffect } from 'react'
import cx from 'classnames'
import LazyLoad from 'react-lazyload'
import is from 'react-jss'

import { withIsVisible } from '../../helpers'

import withSlider from '../Slider'
import Panel from '../Panel'
import Button from '../Button'
import Link from '../Link'
import ProgressWheel from '../ProgressWheel'

import style from './style'

const Wheel = ({
    classes,
    isVisible = false,
    title,
    description,
    posts = [],
    link = {},
    index,
    setIndex,
    animated,
    setAnimated,
    navigation,
    setNavigation,
    goTo,
    setActive,
    active,
    Card,
    color = 'tertiary',
    progressColor = 'yellow',
    gradients = {
        top: 'blue',
        bottom: 'pink',
    },
    classNames,
}) => {
    useEffect(() => {
        setIndex(1)
    }, [])

    useEffect(() => {
        if (isVisible && !active) {
            setActive(true)
        } else if (!isVisible && active) {
            setActive(false)
        }
    }, [ isVisible ])

    useEffect(() => {
        let interval = null

        if (active) {
            interval = setInterval(() => {
                next()
            }, 5000)
        } else if (!active) {
            clearInterval(interval)
        }

        return () => clearInterval(interval)
    }, [active, index, navigation])

    const next = () => {
        if (navigation) {
            setIndex((prevState) => prevState+1)
            setAnimated(true)

            if (index === Math.ceil(posts.length/2)) {
                setNavigation(false)
    
                setTimeout(() => {
                    setAnimated(false)
                    setIndex(1)
                    setNavigation(true)
                }, 250)
            }
        }
    }

    const NavigationItems = () => {
        return posts.map((post, i) => {
            return (i < posts.length / 2) ? <span className={ cx(classes.navigationItem, { active: i + 1 === index }) } key={i} onClick={() => goTo(i+1)} /> : null
        })
    }

    return (
        <Panel
            classNames={{
                panel: cx(
                    classes.panel,
                    classes[`color_${color || 'tertiary'}`],
                    classNames.panel, {
                        [classes.showLink]: link.to && link.text,
                    },
                ),
                inner: cx(
                    classes.inner,
                    classNames.inner,
                ),
            }}
            gradients={gradients}
            outer={[
                <div key="navigation" className={classes.navigation}><NavigationItems /></div>,
                <div key="link" className={classes.link}>
                    { link.to && link.text && (
                        <Link to={link.to}>
                            <Button>{ link.text }</Button>
                        </Link>
                    ) }
                </div>,
                <ProgressWheel
                    key="progressWheel"
                    current={index}
                    max={posts.length/2}
                    className={classes.progressWheel}
                    color={progressColor}
                />
            ]}
        >
            { title && (
                <h2 className={classes.title}><span>{ title }</span></h2>
            ) }
            { description && (
                <p className={classes.description}><span>{ description }</span></p>
            ) }
            <LazyLoad height={800} offset={300}>
                <div className={ cx( classes.posts, classNames.posts, { [classes.animated]: animated }) } style={{ transform: `translate3d(0, -50%, 0) rotate(-${index * 15}deg)` }}>
                    { posts.map((post, i) => (
                        <Card key={i} { ...{ color, index: i, post, active: i === index } } className={classes.card} mouseEnter={ (e) => setActive(false) } mouseLeave={ (e) => setActive(true) } />
                    )) }
                </div>
            </LazyLoad>
        </Panel>
    )
}

export default is(style)(withIsVisible(withSlider(Wheel)))
