export default ({
    colors,
    spaces,
    breakpoints,
    helpers,
}) => ({
    key: { name: 'GamesArchiveDefaultViewCard' },
    
    card: {
        flexBasis: '100%',
        height: 0,
        margin: [ spaces[0]/2, 0, spaces[0]/2, 0 ],
        paddingTop: '65.5%',
        position: 'relative',

        '&:hover': {
            '& $inner': {
                transform: `translate3d(0px, -${spaces[3]}px, 0)`,
                transformOrigin: '100% 100%',

                [breakpoints.sm]: {
                    transform: `translate3d(-${spaces[2]}px, -${spaces[3]}px, 0)`,
                },
            },

            '& $button': {
                '& > span': {
                    maxWidth: 300,
                },
            },
        },

        [breakpoints.sm]: {
            flexBasis: `calc(50% - ${spaces[0]/2}px)`,
            paddingTop: '32.6%',

            '&:nth-child(2n)': {
                marginLeft: spaces[0],
            },
        },

        [breakpoints.md]: {
            flexBasis: '45%',
            paddingTop: '32.6%',
            margin: spaces[0]/2,
            alignSelf: 'flex-end',

            transition: 'all .25s ease',

            '&:nth-child(2n)': {
                marginLeft: spaces[0]/2,
            },
            
            '&:nth-child(8n+1)': {
                flexBasis: '55%',
            },

            '&:nth-child(8n+2), &:nth-child(8n+5)': {
                flexBasis: '40%',
            },

            '&:nth-child(8n+1), &:nth-child(8n+5)': {
                marginLeft: 'auto',
            },

            '&:nth-child(8n+4), &:nth-child(8n+8)': {
                marginRight: spaces[0] / 2,
            },
        },
    },

    inner: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',

        padding: [spaces[3], spaces[2]],

        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: colors.black,

        transition: 'all .25s ease',
        transitionDelay: '.35s',
        ...helpers.clipCorners.default,
        zIndex: 2,

        '&::before, &::after': {
            display: 'block',
            width: '100%',
            height: '100%',
            position: 'absolute',
            bottom: 0,
            left: 0,
            transition: 'all ease-in-out .15s',
            content: '""',
            zIndex: 1,
        },

        '&::before': {
            background: `-moz-linear-gradient(top, ${helpers.hexToRgba(colors.tertiary, 0)} 0%, ${helpers.hexToRgba(colors.tertiary, 1)} 100%)`,
            background: `-webkit-linear-gradient(top, ${helpers.hexToRgba(colors.tertiary, 0)} 0%,${helpers.hexToRgba(colors.tertiary, 1)} 100%)`,
            background: `linear-gradient(to bottom, ${helpers.hexToRgba(colors.tertiary, 0)} 0%,${helpers.hexToRgba(colors.tertiary, 1)} 100%)`,
            filter: `progid:DXImageTransform.Microsoft.gradient( startColorstr='${colors.tertiary}', endColorstr='${colors.tertiary}',GradientType=1 )`,
            opacity: .65,
        },

        '&::after': {
            background: `-moz-linear-gradient(top, ${helpers.hexToRgba(colors.blue, 0)} 0%, ${helpers.hexToRgba(colors.blue, 1)} 100%)`,
            background: `-webkit-linear-gradient(top, ${helpers.hexToRgba(colors.blue, 0)} 0%,${helpers.hexToRgba(colors.blue, 1)} 100%)`,
            background: `linear-gradient(to bottom, ${helpers.hexToRgba(colors.blue, 0)} 0%,${helpers.hexToRgba(colors.blue, 1)} 100%)`,
            filter: `progid:DXImageTransform.Microsoft.gradient( startColorstr='${colors.blue}', endColorstr='${colors.blue}',GradientType=1 )`,
            opacity: 0,
        },

        '.hovered &': {
            [breakpoints.md]: {
                transitionDelay: '0s',
    
                '&::before': {
                    opacity: 0,
                },
    
                '&::after': {
                    opacity: 1,
                },
            },
        },
    },

    title: {
        position: 'relative',
        margin: 0,
        fontSize: 30,
        lineHeight: '32px',
        fontWeight: 700,
        color: 'white',
        textAlign: 'center',
        zIndex: 2,
        transition: 'transform ease-in-out .25s',
        transitionDelay: '.35s',

        '.hovered &': {
            [breakpoints.md]: {
                transform: 'translate3d(0, -31px, 0)',
                transitionDelay: '0s',
            },
        },
    },

    platforms: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: spaces[1],
        position: 'relative',
        zIndex: 2,
    },

    button: {
        display: 'none',
        position: 'relative',
        bottom: 31,
        left: spaces[4],
        transform: 'translate3d(0, -50%, 2px)',
        overflow: 'hidden',
        zIndex: 3,

        [breakpoints.md]: {
            display: 'block',
        },

        '& > span': {
            maxWidth: 0,
            maxHeight: 62,
            transition: 'max-width ease-in-out .25s',
            overflow: 'hidden',

            '& > span': {
                textOverflow: 'ellipsis',
                opacity: 0,
                overflow: 'hidden',
                transition: 'opacty ease-in-out .25s',

                '.hovered &': {
                    [breakpoints.md]: {
                        opacity: 1,
                    },
                },
            },
        },

        '.hovered &': {
            [breakpoints.md]: {
                opacity: 1,
            },
        },

        [breakpoints.sm]: {
            left: spaces[3],
        },
    },

    wide: {},
})