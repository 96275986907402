import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Wheel from '../../../Wheel'
import PostArchiveLatestViewCard from './PostArchiveLatestViewCard'

const PostArchiveLatest = () => {
    const data = useStaticQuery(graphql`
        query {
            allWpPost(sort: {fields: date, order: DESC}, limit: 5) {
                nodes {
                    title
                    slug
                    date
                    postFields {
                        thumbnail {
                            sourceUrl
                        }
                        offSitePost
                        postUrl
                    }
                }
            }
        }
    `)

    const posts = [
        data?.allWpPost?.nodes[data?.allWpPost?.nodes?.length-1] || [],
        ...data?.allWpPost?.nodes || [],
        ...data?.allWpPost?.nodes?.slice(0, data?.allWpPost?.nodes?.length-2) || [],
    ]

    return posts.length > 0 ? (
        <Wheel { ...{
            posts,
            Card: PostArchiveLatestViewCard,
            link: { to: '/news/', text: 'All news' },
            title: 'Latest News',
            gradients: {
                top: 'pink',
                bottom: 'blue',
            },
            classNames: {},
        } } />
    ) : null
}

export default PostArchiveLatest
