export default ({
    spaces,
    breakpoints,
    helpers,
}) => ({
    key: { name: 'GameArchiveFeaturedForegrounds' },
    
    foregrounds: {
        width: `calc(100% + ${spaces[4]*2}px)`,
        height: 'calc(100% + 2px)',
        position: 'absolute',
        top: -1,
        right: -spaces[4],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        zIndex: 2,

        [breakpoints.md]: {
            width: '90%',
            right: 0,
        },

        [breakpoints.lg]: {
            width: '80%',
            right: spaces[4],
        },
    },

    foreground: {
        display: 'block',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        transform: 'translate3d(-20%, 0, 0)',
        backgroundSize: '70%',
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
        transition: 'all ease-in-out .2s',
        transitionDelay: '.15s',
        opacity: 0,

        '&.active': {
            transform: 'translate3d(0, 0, 0)',
            transitionDelay: '1.25s',
            opacity: 1,
        },

        [breakpoints.md]: {
            ...helpers.clipRect('26% 0%', '100% 0%', '100% 100%', '70% 100%'),

            '&.active': {
                ...helpers.clipRect('6% 0%', '100% 0%', '100% 100%', '50% 100%'),
            },
        },

        [breakpoints.max]: {
            right: spaces[0],
        },
    },
})
