export default ({ colors, spaces, breakpoints }) => ({
  key: { name: "PowerUpTeamUp" },

  row: {
    textAlign: "center",

    [breakpoints.mw.md]: {
      marginBottom: spaces[4],

      "&:last-child": {
        marginBottom: spaces[0],
      },
    },
  },

  bulletList: {
    display: "inline-block",
    "& > li": {
      textAlign: "left",
      width: "200px",
      paddingBottom: "15px",
    },
    [breakpoints.lg]: {
      position: "relative",
      left: "-40px",
    },
  },
  logoHeading: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    width: spaces[5],
    height: spaces[5],

    margin: "auto",
    marginBottom: spaces[1],
  },

  title: {
    marginBottom: spaces[2],
    whiteSpace: "nowrap",

    "&$red": {
      color: colors.red,
    },
    "&$yellow": {
      color: colors.darkYellow,
    },
    "&$blue": {
      color: "#66cccc",
    },

    [breakpoints.lg]: {
      marginBottom: spaces[4],
    },
  },

  dotRowParent: {
    display: "none",

    [breakpoints.md]: {
      display: "block",
    },
  },

  dotRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    height: spaces[5],

    margin: [0, -spaces[4]],

    [breakpoints.md]: {
      margin: [0, -spaces[3]],
    },

    "&:before": {
      position: "absolute",
      width: "100%",
      height: 1,
      content: '""',
      backgroundColor: colors.lightGrey,
    },
  },
  dot: {
    position: "relative",
    width: "2rem",
    height: "2rem",

    backgroundColor: colors.white,

    borderRadius: 100,

    transition: "all .15s ease",

    "&:not(:first-child):not(:last-child)": {
      width: "0.75rem",
      height: "0.75rem",
    },

    "&$blue": {
      border: `2px solid #66cccc`,
    },

    "&$yellow": {
      border: `2px solid ${colors.yellow}`,
    },

    "&$red": {
      border: `2px solid ${colors.red}`,
    },

    "&:after": {
      position: "absolute",
      top: "50%",
      left: "50%",

      transform: "translate3d(-50%, -50%, 0)",

      width: "0.75rem",
      height: "0.75rem",
      content: '""',
      backgroundColor: colors.lightGrey,
      borderRadius: 100,
    },
  },

  backgroundText: {
    top: 0,
    left: 0,

    [breakpoints.md]: {
      top: "95%",

      "& > span": {
        bottom: "100%",
      },
    },
  },

  red: {},
  yellow: {},
  blue: {},
})
