import React from 'react'
import cx from 'classnames'
import htmlParser from 'react-html-parser'
import is from 'react-jss'

import { useCookieSettingsState } from '../../../context'

import style from './style'

const positionRow = (position) => {
    switch (position) {
        case 'middle': return 'justify-center'
        case 'right': return 'align-right'
        default: return ''
    }
}

const processCookieButton = (string) => {
    const re = /[{{][\w\W]*[}}]/
    let match = string.match(re)?.[0]

    if (match?.length > 0) {
        const cookieText = match.replace(/\{\{/g, '').replace(/\}\}/g, '')
        
        if (cookieText?.length > 0) {
            string = string.replace(match, `<a href="javascript:void(0);" class="open-cookie-banner">${cookieText}</a>`)
        }
    }

    return string
}

const Column = ({
    classes,
    content: {
        heading,
        content,
        position,
    },
}) => {
    const { setOpen } = useCookieSettingsState()

    const handleClick = (e) => {
        if (e.target.classList.contains('open-cookie-banner')) {
            e.preventDefault()
            setOpen(true)
        }
    }

    return (
        <div className={cx(classes.column)}>
            <div className={cx('container', 'container')}>
                <div className={cx('row', positionRow(position) )}>
                    <div className={position === 'wide' ? 'col-xs-12' : 'col-xs-12 col-md-8'}>
                        <h4>{ heading }</h4>
                    </div>
                    <div className={`wysiwyg ${position === 'wide' ? 'col-xs-12' : 'col-xs-12 col-md-8'}`} onClick={(e) => handleClick(e)}>{ htmlParser(processCookieButton(content)) }</div>
                </div>
            </div>
        </div>
    )
}

export default is(style)(Column)