import React from 'react'
import cx from 'classnames'
import is from 'react-jss'

import Button from '../../../../Button'
import Link from '../../../../Link'

import style from './style'

const PostArchiveFeaturedButtons = ({
    classes,
    index,
    buttons = [],
}) => (
    <div className={classes.buttons}>
        { buttons.map(({ slug, offsite }, i) => offsite ? (
			<a {...{ key: i, href: slug, target: '_blank', rel :'noreferrer noopener' }}>
                <Button className={ cx(classes.button, { active: i === index }) }>
                    <span className="gradient-text">Learn more</span>
                </Button>
            </a>
        ) : (
			<Link key={i} to={`/news/${slug}/`}>
                <Button className={ cx(classes.button, { active: i === index }) }>
                    <span className="gradient-text">Learn more</span>
                </Button>
            </Link>
		)) }
    </div>
)

export default is(style)(PostArchiveFeaturedButtons)
