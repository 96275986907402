export default ({
    colors,
    breakpoints,
    spaces,
}) => ({
    key: { name: 'TextWheel' },
    
    panel: {
        '&::before': {
            display: 'block',
            width: 200,
            height: 200,
            position: 'absolute',
            top: '100%',
            left: 'auto',
            right: -spaces[3],
            transform: 'translate3d(0, -80%, 0)',
            border: `3px solid ${colors.secondary}`,
            borderRadius: '100%',
            zIndex: 1,
            content: '""',

            [breakpoints.sm]: {
                width: 300,
                height: 300,
            },

            [breakpoints.md]: {
                width: 400,
                height: 400,
                right: spaces[4],
            },
        },

        '&::after': {
            height: '30%',
            opacity: .5,
        },
    },

    inner: {
        alignItems: 'flex-start',
        position: 'relative',
        zIndex: 2,

        [breakpoints.mw.lg]: {
            paddingBottom: 0,
        },
    },

    text: {
        margin: 0,
        padding: [spaces[3], 0],
        
        [breakpoints.lg]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            width: '50%',
            height: '100%',
            padding: [spaces[5], 0, spaces[5]*2],
            position: 'absolute',
            top: 0,
            left: '46%',
        },
    },

    title: {
        display: 'none',
        position: 'relative',
        width: 300,
        fontSize: 54,
        lineHeight: '56px',
        textAlign: 'left',

        '& > span': {
            display: 'block',
            position: 'relative',
            zIndex: 2,
        },

        '&::before': {
            display: 'block',
            width: 2,
            height: '200%',
            position: 'absolute',
            top: 0,
            left: '90%',
            transform: 'translateY(-20%) rotate(-35deg)',
            backgroundColor: colors.blue,
            zIndex: 1,
            content: '""',
        },

        [breakpoints.lg]: {
            display: 'block',
        },
    },

    posts: {
        width: '100%',
        paddingTop: '56.25%',
        position: 'relative',

        [breakpoints.mw.lg]: {
            transform: 'none!important',
        },

        [breakpoints.lg]: {
            width: '400%',
            paddingTop: '400%',
            position: 'absolute',
            top: '50%',
            right: '90%',
            transform: 'translate3d(0, -50%, 0)',
            transformOrigin: 'center',
        },
    },

    animated: {
        transition: 'transform ease-in-out .2s',
    },

    navigation: {
        display: 'flex',
        flexWrap: 'wrap',
        height:'100%',
        justifyContent: 'center',
        padding: [0, 20],
        margin: [spaces[3], 'auto'],
        zIndex: 2,

        [breakpoints.lg]: {
            flexDirection: 'column',
            flexWrap: 'wrap',
            margin: 0,
            padding: 0,
            position: 'absolute',
            top: '50%',
            transform: 'translate(0, -50%)',
            right: '3%',
        },
    },
    
    navigationItem: {
        width: 10,
        height: 10,
        padding: '11px',
        position: 'relative',
        cursor: 'pointer',
        zIndex: 3,
        // border: `2px solid ${colors.tertiary}`,
        [breakpoints.sm]: {
            // padding: spaces[2],
        },

        '&::before': {
            display: 'block',
            width: 10,
            height: 10,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: 8,
            content: '""',
            border: `2px solid ${colors.darkGrey}`,
            transition: 'border-color ease-in-out .15s',
        },

        '&:hover': {
            '&::before': {
                borderColor: colors.white,
            },
        },

        '&.active': {
            '&::before': {
                backgroundColor: colors.black,
                borderColor: `${colors.secondary}!important`,
                boxShadow: `0 0 10px 0 ${colors.secondary}`,
            },
        },
    },

    progressWheel: {
        position: 'absolute',
        bottom: spaces[3],
        left: spaces[3],
        zIndex: 2,
    },

    link: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        margin: [spaces[3], 'auto', spaces[4]],
        zIndex: 2,

        [breakpoints.lg]: {
            display: 'block',
            margin: 0,
            position: 'absolute',
            bottom: spaces[3],
            left: '50%',
            transform: 'translate(-50%, 0)',
        },
    },

    color_primary: { backgroundColor: '#34a0fb' },
    color_secondary: { backgroundColor: '#f140ff' },
    color_tertiary: { backgroundColor: '#000034' },
    
    color_white: {
        backgroundColor: '#ffffff',

        '& $title': {
            color: colors.black,
        },

        '& $navigationItem': {
            '&:hover': {
                '&::before': {
                    borderColor: colors.black,
                },
            },
        },
    },

    color_black: { backgroundColor: '#000000' },
    
    color_pink: { backgroundColor: '#f33fff' },
    color_blue: { backgroundColor: '#6653fd' },
    color_yellow: { backgroundColor: '#ffc813' },

    color_darkGrey: { backgroundColor: '#5a595e' },

    card: {
        width: '100%',
        maxWidth: '80%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate3d(-50%, -50%, 0)',
        opacity: 0,
        filter: 'grayscale(100%)',

        [breakpoints.sm]: {
            maxWidth: '70%',
        },

        [breakpoints.lg]: {
            maxWidth: '8%',
            left: '100%',
            transformOrigin: '-625% 50%',
            opacity: .3,

            '&:nth-child(1)': {
                transform: 'translateY(-50%) rotate(0)',
            },
    
            '&:nth-child(2)': {
                transform: 'translateY(-50%) rotate(6deg)',
            },
    
            '&:nth-child(3)': {
                transform: 'translateY(-50%) rotate(12deg)',
            },
    
            '&:nth-child(4)': {
                transform: 'translateY(-50%) rotate(18deg)',
            },
    
            '&:nth-child(5)': {
                transform: 'translateY(-50%) rotate(24deg)',
            },
    
            '&:nth-child(6)': {
                transform: 'translateY(-50%) rotate(30deg)',
            },
    
            '&:nth-child(7)': {
                transform: 'translateY(-50%) rotate(36deg)',
            },
    
            '&:nth-child(8)': {
                transform: 'translateY(-50%) rotate(42deg)',
            },
    
            '&:nth-child(9)': {
                transform: 'translateY(-50%) rotate(48deg)',
            },
    
            '&:nth-child(10)': {
                transform: 'translateY(-50%) rotate(54deg)',
            },
    
            '&:nth-child(11)': {
                transform: 'translateY(-50%) rotate(60deg)',
            },
    
            '&:nth-child(12)': {
                transform: 'translateY(-50%) rotate(66deg)',
            },
    
            '&:nth-child(13)': {
                transform: 'translateY(-50%) rotate(72deg)',
            },
    
            '&:nth-child(14)': {
                transform: 'translateY(-50%) rotate(78deg)',
            },
    
            '&:nth-child(15)': {
                transform: 'translateY(-50%) rotate(84deg)',
            },
    
            '&:nth-child(16)': {
                transform: 'translateY(-50%) rotate(90deg)',
            },
    
            '&:nth-child(17)': {
                transform: 'translateY(-50%) rotate(96deg)',
            },
    
            '&:nth-child(18)': {
                transform: 'translateY(-50%) rotate(102deg)',
            },
    
            '&:nth-child(19)': {
                transform: 'translateY(-50%) rotate(108deg)',
            },
    
            '&:nth-child(19)': {
                transform: 'translateY(-50%) rotate(114deg)',
            },
        },

        '&.active': {
            opacity: 1,
            pointerEvents: 'auto',
            filter: 'none',
        },
    },
})
