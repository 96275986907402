export default ({
    helpers,
    breakpoints,
}) => ({
    key: { name: 'NavThumb' },
    
    thumb: {
        width: 110,
        height: 110,
        cursor: 'pointer',

        '&:not(.disabled):hover': {
            // [breakpoints.md]: {
            //     '& $inner': {
            //         '&::after': {
            //             transform: 'translate3d(0, 0, 0)',
            //         },
            //     },
            // },

            '& $image': {
                transform: 'translate3d(-50%, -50%, 0) scale(1.15)',
            },
        },

        [breakpoints.sm]: {
            width: 150,
            height: 150,
        },
    },

    next: {
        '& $inner': {
            right: 0,
            ...helpers.clipSides.left.default,
        },

        '&.disabled': {
            '& $inner': {
                transform: 'translate3d(50%, 0, 0)',
                ...helpers.clipSides.left.animated,
            },
        },
    },

    prev: {
        '& $inner': {
            left: 0,
            ...helpers.clipSides.right.default,
        },

        '&.disabled': {
            '& $inner': {
                transform: 'translate3d(-50%, 0, 0)',
                ...helpers.clipSides.right.animated,
            },
        },
    },

    inner: {
        display: 'block',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        transition: 'all ease-in-out .15s',
        opacity: 0,
        pointerEvents: 'none',
        overflow: 'hidden',

        '&.active': {
            opacity: 1,
            pointerEvents: 'auto',
        },
        
        // '&::after': {
        //     display: 'block',
        //     width: '100%',
        //     height: '100%',
        //     position: 'absolute',
        //     top: 0,
        //     left: 0,
        //     backgroundColor: helpers.hexToRgba(colors.secondary, .75),
        //     transform: 'translate3d(0, 100%, 0)',
        //     transition: 'transform ease-in-out .15s',
        //     content: '""',
        // },
    },

    image: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate3d(-50%, -50%, 0) scale(1.15)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        transition: 'transform ease-in-out .25s',

        '.active &': {
            transform: 'translate3d(-50%, -50%, 0) scale(1)',
        },
    },
})
