export default ({
    colors,
	spaces,
	breakpoints,
}) => ({
    key: { name: 'PartnerQuotes' },
    
    quote: {
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 660,
        fontSize: 18,
        lineHeight: '24px',
        fontWeight: 300,
        textAlign: 'center',
        color: colors.white,
        opacity: 0,
        pointerEvents: 'none',
        transition: 'opacity ease-in-out .35s',

        '&.active': {
            opacity: 1,
        },
        
        [breakpoints.sm]: {
            fontSize: 24,
            lineHeight: '28px',
        },
		
		[breakpoints.md]: {
			fontSize: 30,
			lineHeight: '34px',
		},
		
		[breakpoints.lg]: {
			fontSize: 28,
		},
    },

    name: {
        display: 'block',
        fontWeight: 700,
        margin: [spaces[3], 0, 0],
    },
})
