export default ({
    colors,
    breakpoints,
    spaces,
}) => ({
    key: { name: 'Testimonials' },

    panel: {
        '&::before': {
            display: 'block',
            width: 300,
            height: 300,
            position: 'absolute',
            top: '10%',
            left: 0,
            transform: 'translate3d(-30%, -40%, 0) scale(.9)',
            border: `3px solid ${colors.secondary}`,
            borderRadius: '100%',
            zIndex: 2,
            transition: 'opacity ease-in-out .25s, transform ease-in-out .25s',
            content: '""',

            [breakpoints.md]: {
                width: 400,
                height: 400,
                left: -spaces[3],
            },
        },
    },

    inner: {
        // height: '100vh',
    },

    posts: {
        [breakpoints.mw.sm]: {
            paddingTop: '120%',
        },
    },
})