import React, { useState } from 'react'
import cx from 'classnames'
import htmlParser from 'react-html-parser'
import is from 'react-jss'

import Tag from '../../../../Tag'
import Button from '../../../../Button'
import RandomText from '../../../../RandomText'
import Link from '../../../../Link'

import style from './style'

const Card = ({
    classes,
    career: {
        title,
        slug,
        careerFields: {
            location,
        },
    },
}) => {
    const [ hovered, setHovered ] = useState(false)
    
    return (
        <div
            className={ cx(
                classes.card, {
                    hovered,
                },
            ) }
            onMouseEnter={e => setHovered(true)}
            onMouseLeave={e => setHovered(false)}
        >
            <Link to={`/careers/${slug}/`}>
                <div className={classes.inner}>
                    <div>
                        <h2 className={ cx(classes.title, { 'gradient-text': !hovered }) }>{ htmlParser(title) }</h2>
                        <Tag size="s" className={classes.location}>{ location.split(', ')[0] }</Tag>
                    </div>

                </div>
                <Button color="black" className={ cx(classes.button, 'no-hover') }>
                    <RandomText speed={25} animate={hovered}>Apply</RandomText>
                </Button>
            </Link>
        </div>
    )
}

export default is(style)(Card)
