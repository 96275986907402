import React from 'react'
import cx from 'classnames'
import is from 'react-jss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import style from './style'

const OurOfferingRightContent = ({
    classes,
    title,
    tagline,
    description,
    powerUp,
    teamUp,
}) => (
    <div className={classes.ourOfferingsRightContent}>
        <div className={classes.iconRow}>
            <h4 className={classes.iconLabel}>Power Up</h4>
            { powerUp && <FontAwesomeIcon icon={[ 'far', 'check-circle' ]} color="white" className={classes.icon} /> }
        </div>
        
        <div className={cx(classes.mobileDivide)}></div>

        <div className={classes.iconRow}>
            <h4 className={classes.iconLabel}>Team Up</h4>
            { teamUp && <FontAwesomeIcon icon={[ 'far', 'check-circle' ]} color="white" className={classes.icon} /> }
        </div>
    </div>
)

export default is(style)(OurOfferingRightContent)