import React from 'react'
import cx from 'classnames'
import is from 'react-jss'

import RandomText from '../../../../RandomText'

import style from './style'

const PostArchiveFeaturedTitles = ({
    classes,
    index,
    titles = [],
}) => (
    <div className={classes.titles}>
        { titles.map((title, i) => (
            <h2 key={i} className={ cx(classes.title, { active: index === i }) }>
                <RandomText speed={15} full={false} animate={index === i}>{ title }</RandomText>
            </h2>
        )) }
    </div>
)

export default is(style)(PostArchiveFeaturedTitles)
