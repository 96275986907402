export default ({
    colors,
    breakpoints,
}) => ({
    key: { name: 'HeroFull' },
    
    panel: {
        '&::before': {
            // display: 'block',
            width: 3,
            height: '120%',
            backgroundColor: colors.secondary,
            position: 'absolute',
            top: '50%',
            left: '60%',
            transform: 'translate3d(0, -50%, 0) rotate(-31deg)',
            transition: 'height ease-in-out .3s, width ease-in-out .3s, transform ease-in-out .3s',
            transitionDelay: '.5s',
            content: '""',
            zIndex: 1,
        },
    },

    hero: {
        paddingTop: 126,
        paddingBottom: 138,
        
        [breakpoints.mw.xs60]: {
            paddingBottom: 225,
        },

        [breakpoints.lg]: {
            paddingTop: 0,
            paddingBottom: 0,
        },
    },
})