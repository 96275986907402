export default ({
    colors,
    spaces,
    breakpoints,
}) => ({
    key: { name: 'OurOfferingsOffering' },

    row: {
        position: 'relative',
		
		'& > *': {
			flex: '1',
        },
        
        [breakpoints.md]: {
            display: 'flex',
            padding: [spaces[2], 0],
			paddingLeft: spaces[2],
			
			'& > *': {
				flexBasis: '45%',
	
				'&:first-child': {
					flexBasis: '55%',
				},
			},
        },

        

        borderBottom: `1px solid ${colors.grey}`,

        '&:last-child': {
            borderBottom: 'none',
        }
    },

    description: {
        display: 'flex',
        alignItems: 'flex-start',
        position: 'absolute',
        background: colors.white,
        color: colors.black,
        width: '100%',
        top: spaces[1],
        left: 0,
        padding: spaces[0],
        margin: 0,
        borderRadius: spaces[0] / 2,
        transition: 'opacity .25s ease',

        fontSize: 17,
        lineHeight: '19px',
        
        pointerEvents: 'none',
        opacity: 0,

        display: 'flex',
        flexDirection: 'row',

        '& > svg': {
            width: spaces[2],
            height: spaces[2],
            fontSize: spaces[2],
            lineHeight: `${spaces[2]}px`,
			margin: [spaces[0], spaces[0], 0, 0],
			flexShrink: 0,
			
			[breakpoints.md]: {
				width: spaces[3],
				height: spaces[3],
				fontSize: spaces[3],
				lineHeight: `${spaces[3]}px`,
			},

            '& > path': {
                fill: colors.tertiary,
            },
        },

        '&.show': {
            opacity: 1,
            pointerEvents: 'auto',
        },

        [breakpoints.md]: {
            alignItems: 'center',
            width: `55%`,
            minHeight: '60%',
            margin: [0, spaces[0], 0, spaces[4] + spaces[2]],
            padding: spaces[1],
            top: '50%',
            transform: 'translate(0, -50%)',

            fontSize: 13,
            lineHeight: '15px',

            '& > *': {
                '&:first-child': {
                    display: 'none',
                }
            }
        },

        [breakpoints.lg]: {
            fontSize: 17,
            lineHeight: '19px',
        }
    }
})