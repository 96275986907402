import React, { useEffect } from "react"
import cx from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import is from "react-jss"

import { useVideoState } from "../../../../context"

import withSlider from "../../../Slider"
import Panel from "../../../Panel"
import Tag from "../../../Tag"
import Button from "../../../Button"
import Link from "../../../Link"
import NavThumb from "../../../NavThumb"

import Titles from "./Titles"
import Backgrounds from "./Backgrounds"
import Midgrounds from "./Midgrounds"
import Foregrounds from "./Foregrounds"

import style from "./style"
import { getImageSourceUrl } from "../../../../helpers"

const Featured = ({
  classes,
  index,
  setIndex,
  prevIndex,
  setPrevIndex,
  active,
  navigation,
  setNavigation,
  direction,
  setDirection,
  content: { featuredContent },
}) => {
  const { enableVideoPlayer } = useVideoState()

  const prev = () => {
    if (navigation) {
      setDirection(0)
      setNavigation(false)
      setPrevIndex(index)

      if (index === 0) {
        setIndex(featuredContent.length - 1)
      } else {
        setIndex(prevState => prevState - 1)
      }

      setTimeout(() => {
        setNavigation(true)
      }, 1000)
    }
  }

  const next = () => {
    if (navigation) {
      setDirection(1)
      setNavigation(false)
      setPrevIndex(index)

      if (index === featuredContent.length - 1) {
        setIndex(0)
      } else {
        setIndex(prevState => prevState + 1)
      }

      setTimeout(() => {
        setNavigation(true)
      }, 1000)
    }
  }

  useEffect(() => {
    let interval = null
    if (active) {
      interval = setInterval(() => {
        next()
      }, 5000)
    } else if (!active) {
      clearInterval(interval)
    }

    return () => clearInterval(interval)
  }, [index, active, navigation])

  return (
    <Panel
      classNames={{ inner: classes.inner }}
      outer={[
        <Backgrounds
          key="backgrounds"
          {...{ index, direction, prevIndex }}
          images={featuredContent.map(slide =>
            getImageSourceUrl(slide.background, "panel_background")
          )}
        />,
        <NavThumb
          disabled={!navigation}
          key="prev"
          prevIndex={prevIndex}
          index={index - 1 < 0 ? featuredContent.length - 1 : index - 1}
          images={featuredContent.map(
            slide => slide.game.gameFields.thumbnail?.sourceUrl
          )}
          direction="prev"
          clickHandler={() => prev()}
          className={classes.prev}
        />,
        <NavThumb
          disabled={!navigation}
          key="next"
          prevIndex={prevIndex}
          index={index + 1 > featuredContent.length - 1 ? 0 : index + 1}
          images={featuredContent.map(
            slide => slide.game.gameFields.thumbnail?.sourceUrl
          )}
          direction="next"
          clickHandler={() => next()}
          className={classes.next}
        />,
      ]}
    >
      <div className={classes.featured}>
        <div className={classes.text}>
          <Tag className={classes.tag}>Featured</Tag>
          <Titles
            {...{ index }}
            titles={featuredContent.map(slide => slide.game.title)}
          />
          <div className={classes.buttons}>
            {featuredContent[index].game.gameFields.video &&
              featuredContent[index].game.gameFields.video.mediaItemUrl && (
                <Button
                  className={classes.button}
                  clickHandler={() =>
                    enableVideoPlayer({
                      video:
                        featuredContent[index].game.gameFields.video
                          .mediaItemUrl,
                    })
                  }
                >
                  <FontAwesomeIcon
                    icon={["far", "play-circle"]}
                    color="black"
                    className="icon"
                  />
                </Button>
              )}
            <Link
              to={`/games/${featuredContent[index].game.slug}/`}
              className={classes.button}
            >
              <Button color="black">See details</Button>
            </Link>
          </div>
        </div>
      </div>
      <Midgrounds
        {...{ index, direction, prevIndex }}
        images={featuredContent.map(slide => slide.midground?.sourceUrl)}
      />
      <Foregrounds
        {...{ index, direction, prevIndex }}
        images={featuredContent.map(slide => slide.foreground?.sourceUrl)}
      />
    </Panel>
  )
}

export default is(style)(withSlider(Featured))
