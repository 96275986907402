import React, { useEffect } from 'react'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import is from 'react-jss'

import withSlider from '../../Slider'
import ProgressWheel from '../../ProgressWheel'

import style from './style'

const Stars = ({
    rating,
    className,
}) => {
    let stars = []

    for (let i = 0; i < rating; i++) {
        if (i > (rating-1)) {
            stars.push(<FontAwesomeIcon icon={[ 'fas', 'star-half' ]} color="white" className="star icon small" />)
        } else {
            stars.push(<FontAwesomeIcon icon={[ 'fas', 'star' ]} color="white" className="star icon small" />)
        }
    }

    return <div { ...{ className } }>{ stars }</div>
}

const Glassdoor = ({
    classes,
    index,
    setIndex,
    active,
    navigation,
	setNavigation,
    content: {
        reviews,
    },
}) => {
    const next = () => {
        if (navigation) {
            setIndex((prevState) => prevState+1)

            if (index === reviews.length-1) {
                setIndex(0)
            }
        }
    }

    useEffect(() => {
        let interval = null
        if (active) {
            interval = setInterval(() => {
                next()
            }, 6000)
        } else if (!active) {
            clearInterval(interval)
        }

        return () => clearInterval(interval)
    }, [index, active, navigation])

    return (
        <div className={classes.glassdoor}>
            <div className={classes.inner}>
                <div className={classes.reviews}>
                    { reviews.map(({ review, rating }, i) => (
                        <div className={classes.review}>
                            <img src="https://tiltingpoint-website.s3.eu-west-2.amazonaws.com/static/glassdoor-logo.png" alt="Glassdoor Reviews" className={ cx(classes.logo, { active: i === index }) } />
                            <Stars { ...{ rating } } className={ cx(classes.stars, { active: i === index }) } />
                            <span key="rating" className={ cx(classes.rating, { active: i === index }) }>{ rating % 1 === 0 ? `${rating}.0` : rating }</span>
                            <p key="review" className={ cx(classes.reviewText, { active: i === index }) }>"{ review }"</p>
                        </div>
                    )) }
                </div>
            </div>

            <ProgressWheel color="secondary" key="progress" current={index + 1} max={reviews.length} className={classes.progressWheel} />
        </div>
    )
}

export default is(style)(withSlider(Glassdoor))
