import React from 'react'
import cx from 'classnames'
import isTouchDevice from 'is-touch-device'
import is from 'react-jss'

import style from './style'

const OurOfferingsLeftContent = ({
    classes,
    title,
	tagline,
	setHover,
	hover,
}) => {
	const handlePointerLeave = () => {
		if (isTouchDevice() !== true) {
			setHover(false)
		}
	}

	return (
		<div
			className={classes.ourOfferingsLeftContent}
			onPointerEnter={ (e) => setHover(true) }
			onPointerLeave={ (e) => handlePointerLeave() }
		>
			<span {...{ className: cx(classes.icon, 'desktop', {
				'hover': hover,
			}) }}>
				?
				<span className={classes.interaction} />
			</span>

			<span {...{ className: cx(classes.icon, 'mobile', {
				'hover': hover,
			}) }}>
				?
				<span className={classes.interaction} />
			</span>

			<div className={classes.content}>
				<h4 className={classes.title}>{ title }</h4>
				<p className={classes.tagline}>{ tagline }</p>
			</div>
		</div>
	)
}

export default is(style)(OurOfferingsLeftContent)