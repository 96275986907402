export default ({
    colors,
    breakpoints,
    spaces,
}) => ({
    key: { name: 'HeroTagline' },
    
    panel: {
        '&::before, &::after': {
            display: 'block',
            top: 0,
            backgroundColor: colors.tertiary,
            content: '""',
            zIndex: 2,
        },

        '&::before': {
            display: 'block',
            width: '100%',
            height: '100%',
            left: 0,
            transform: 'none',
            opacity: 0,
        },

        '&::after': {
            width: '100%',
            height: '100%',
            left: 0,
            transform: 'translate3d(20%, 0, 0)',
            '-webkit-clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 100% 100%)',
            'clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 100% 100%)',
            opacity: 0,
            transition: 'opacity ease-in-out .25s, transform ease-in-out .25s',
            transitionDelay: '0s',

            [breakpoints.sm]: {
                left: '10%',
                '-webkit-clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 40% 100%)',
                'clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 40% 100%)',
            },

            [breakpoints.sm]: {
                left: '30%',
                '-webkit-clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 30% 100%)',
                'clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 30% 100%)',
            },
        },

        '&.loaded': {
            '&::before': {
                opacity: .5,
            },

            '&::after': {
                transform: 'translate3d(0, 0, 0)',
                opacity: 1,
            },

            '&.disabled': {
                '&::after': {
                    transform: 'translate3d(-20%, 0, 0)',
        
                    [breakpoints.sm]: {
                        transform: 'translate3d(-20%, 0, 0)',
                    },
        
                    [breakpoints.sm]: {
                        transform: 'translate3d(-20%, 0, 0)',
                    },
                },
            }
        },
    },

    inner: {
        zIndex: 4,

        '&::before': {
            display: 'block',
            width: 300,
            height: 300,
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translate3d(0, -60%, 0) scale(.9)',
            border: `3px solid ${colors.primary}`,
            borderRadius: '100%',
            zIndex: 2,
            transition: 'opacity ease-in-out .25s, transform ease-in-out .25s',
            opacity: 0,
            content: '""',

            [breakpoints.md]: {
                width: 400,
                height: 400,
            },

            '.loaded &': {
                transform: 'translate3d(0, -60%, 0) scale(1)',
                opacity: 1,
                transitionDelay: '.5s',
            },
        },
    },

    background: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate3d(-50%, -50%, 0) scale(1.6)',
        transformOrigin: 'center',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        opacity: 0,
        transition: 'opacity ease-in-out .5s, transform ease-in-out .5s',
        zIndex: 0,

        '.loaded &': {
            transform: 'translate3d(-50%, -50%, 0) scale(1)',
            transitionDelay: '.15s',
            opacity: 1,
            zIndex: 1,
        },
    },

    hero: {
        width: '100%',
        padding: [126, spaces[2], 64],
        position: 'relative',
        textAlign: 'center',

        [breakpoints.lg]: {
            padding: 0,
        },

        '&::before': {
            display: 'block',
            width: 0,
            height: '0%',
            backgroundColor: colors.secondary,
            position: 'absolute',
            top: '50%',
            left: '60%',
            transform: 'translate3d(0, -50%, 0) rotate(-31deg)',
            transition: 'height ease-in-out .3s, width ease-in-out .3s, transform ease-in-out .3s',
            transitionDelay: '.5s',
            content: '""',
            zIndex: 1,
        },

        '.loaded &': {
            '&::before': {
                width: 3,
                height: '80%',

                [breakpoints.md]: {
                    height: '160%',
                },
            },
        },
    },

    title: {
        margin: 0,
        fontSize: 54,
        lineHeight: '66px',
        position: 'relative',
        zIndex: 4,
        color: colors.white,

        [breakpoints.md]: {
            fontSize: 80,
            lineHeight: '76px',
        },

        [breakpoints.lg]: {
            fontSize: 100,
            lineHeight: '96px',
        },
    },

	tagline: {
        display: 'block',
        maxWidth: 500,
        margin: [spaces[3], 'auto', 0],
        position: 'relative',
        zIndex: 4,
        color: colors.white,

        [breakpoints.md]: {
            margin: [spaces[4], 0, 0],
            textAlign: 'left',
            float: 'right',
        },

        '& *': {
            fontSize: 18,
            lineHeight: '22px',

            [breakpoints.sm]: {
                fontSize: 24,
                lineHeight: '28px',
            },
        },
	}
})