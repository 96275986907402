import React, { useEffect} from 'react'
import cx from 'classnames'
import is from 'react-jss'

import { getImageSourceUrl } from '../../../../helpers'

import Panel from '../../../Panel'
import withSlider from '../../../Slider'
import Tag from '../../../Tag'
import NavThumb from '../../../NavThumb'

import PostArchiveFeaturedBackgrounds from './PostArchiveFeaturedBackgrounds'
import PostArchiveFeaturedTitles from './PostArchiveFeaturedTitles'
import PostArchiveFeaturedHighlights from './PostArchiveFeaturedHighlights'
import PostArchiveFeaturedButtons from './PostArchiveFeaturedButtons'

import style from './style'

const Featured = ({
    classes,
    loaded,
    setLoaded,
    index,
    setIndex,
    prevIndex,
    setPrevIndex,
    navigation,
    setNavigation,
    setDirection,
    content: {
        featuredContent,
    },
}) => {
    useEffect(() => {
        setLoaded(true)
    }, [ loaded ])

    useEffect(() => {
        let interval = null
        interval = setInterval(() => {
            next()
        }, 8000)

        return () => clearInterval(interval)
    }, [index, navigation])

    const prev = () => {
        if (navigation) {
            setDirection(0)
            setNavigation(false)
            setPrevIndex(index)

            if (index === 0) {
                setIndex(featuredContent.length - 1)
            } else {
                setIndex((prevState) => prevState-1)
            }

            setTimeout(() => {
                setNavigation(true)
            }, 1000)
        }
    }

    const next = () => {
        if (navigation) {
            setDirection(1)
            setNavigation(false)
            setPrevIndex(index)

            if (index === featuredContent.length - 1) {
                setIndex(0)
            } else {
                setIndex((prevState) => prevState+1)
            }

            setTimeout(() => {
                setNavigation(true)
            }, 1000)
        }
    }

	return (
        <Panel
            outer={[
                <PostArchiveFeaturedBackgrounds { ...{ index, loaded, key: 'backgrounds' } } images={ featuredContent.map((slide) => getImageSourceUrl(slide.background, 'panel_background')) } />,
                <NavThumb disabled={!navigation} key="prev" prevIndex={prevIndex} index={ (index-1 < 0) ? featuredContent.length-1 : index-1 } images={ featuredContent.map(slide => slide.post.postFields.thumbnail && slide.post.postFields.thumbnail.mediaItemUrl) } direction="prev" clickHandler={() => prev()} className={classes.prev} />,
                <NavThumb disabled={!navigation} key="next" prevIndex={prevIndex} index={ (index+1 > featuredContent.length-1) ? 0 : index+1 } images={ featuredContent.map(slide => slide.post.postFields.thumbnail && slide.post.postFields.thumbnail.mediaItemUrl) } direction="next" clickHandler={() => next()} className={classes.next} />
            ]}
            classNames={{
                panel: cx(classes.panel, { loaded, disabled: !navigation }),
                inner: classes.inner,
                background: classes.background,
            }}
        >
            <div className={classes.featured}>
                <div className={classes.text}>
                    <Tag color="tertiary" className={classes.tag}>Featured</Tag>
                    <PostArchiveFeaturedTitles { ...{ index } } titles={ featuredContent.map((slide) => slide.post.title)} />
                    <div className={classes.textBottom}>
                        <PostArchiveFeaturedHighlights { ...{ index } } highlights={ featuredContent.map((slide) => slide.highlights) } />
                        <PostArchiveFeaturedButtons { ...{ index } } buttons={ featuredContent.map((slide) => ({
							offsite: slide.post.postFields.offSitePost,
							slug: slide.post.postFields.offSitePost ? slide.post.postFields.postUrl : slide.post.slug,
						})) } />
                    </div>
                </div>
            </div>
        </Panel>
    )
}

export default is(style)(withSlider(Featured))