export default ({
    spaces,
    breakpoints,
}) => ({
    key: { name: 'ParallaxSliderForegrounds' },
    
    foregrounds: {
        width: '90%',
        paddingTop: '100%',
        position: 'absolute',
        bottom: 0,
        right: 0,

        [breakpoints.sm]: {
            width: '80%',
            paddingTop: '75%',
            maxWidth: 400,
            right: spaces[3],
        },

        // [breakpoints.md]: {
        //     width: '70%',
        //     paddingTop: '50%',
        //     right: spaces[4]*2,
        //     maxWidth: 500,
        // },

        [breakpoints.lg]: {
            width: '100%',
            height: '80%',
            maxWidth: 580,
        },
        
        [breakpoints.max]: {
            right: spaces[4],
        },
    },

    foreground: {
        width: '100%',
        height: 'auto',
        position: 'absolute',
        bottom: 0,
        left: '50%',
        opacity: 0,
        verticalAlign: 'bottom',
        pointerEvents: 'none',
        zIndex: 3,

        '&.active': {
            transform: 'translate3d(-50%, 0, 0)',
            opacity: 1,
            zIndex: 4,
        },
    },

    direction0: { // ltr
        '& $foreground': {
            transform: 'translate3d(-50vw, 0, 0)',
            // ...helpers.clipRect('40% 0%', '40% 0%', '84% 100%', '84% 100%'),
        },

        '& $in': {
            '&.active': {
                transform: 'translate3d(-50%, 0, 0)',
                // ...helpers.clipRect('6% 0%', '40% 0%', '84% 100%', '50% 100%'),
            },
        },

        '& $out': {
            transform: 'translate3d(50vw, 0, 0)',
            // ...helpers.clipRect('6% 0%', '6% 0%', '50% 100%', '50% 100%'),

            '&.active': {
                transform: 'translate3d(-50%, 0, 0)',
                // ...helpers.clipRect('6% 0%', '40% 0%', '84% 100%', '50% 100%'),
            },
        },
    },

    direction1: { // rtl
        '& $foreground': {
            transform: 'translate3d(50vw, 0, 0)',
            // ...helpers.clipRect('6% 0%', '6% 0%', '50% 100%', '50% 100%'),
        },

        '& $in': {
            '&.active': {
                transform: 'translate3d(-50%, 0, 0)',
                // ...helpers.clipRect('6% 0%', '40% 0%', '84% 100%', '50% 100%'),
            },
        },

        '& $out': {
            transform: 'translate3d(-50vw, 0, 0)',
            // ...helpers.clipRect('40% 0%', '40% 0%', '84% 100%', '84% 100%'),

            '&.active': {
                transform: 'translate3d(-50%, 0, 0)',
                // ...helpers.clipRect('6% 0%', '40% 0%', '84% 100%', '50% 100%'),
            },
        },
    },

    in: {
        transition: 'transform ease-in-out .75s, opacity ease-in-out .5s',
        transitionDelay: '.15s',
    },

    out: {
        transition: 'transform ease-in-out .75s, opacity ease-in-out .5s',
        transitionDelay: '.15s',
    },
})
