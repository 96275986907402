import React from 'react'
import cx from 'classnames'
import LazyLoad from 'react-lazyload'
import is from 'react-jss'

import Button from '../../../Button'

import { getImageSourceUrl } from '../../../../helpers'

import style from './style'

const Small = ({
	classes,
	content: {
		getInTouchContent: {
			title,
			titleSize,
			hideDiagonalSlice,
			background,
			button,
		},
	},
	className,
}) => {
	const backgroundImage = getImageSourceUrl(background, 'generic_wide')

	return backgroundImage ? (
		<div className={ cx(classes.small, className) }>
			<LazyLoad offset={200} height={0}>
				<div className={classes.background} style={{ backgroundImage: `url(${ backgroundImage })` }} />
			</LazyLoad>

			{ hideDiagonalSlice !== true && (
				<div className={classes.gradient} />
			)}
			<div className="container">
				<div className="row align-middle">
					<div className="col-xs-12 col-md-6 tac md-tal">
						{ titleSize === 'large' ? (
							<h2 className={cx(classes.title, 'large')}><span>{ title }</span></h2>
						) : (
							<h3 className={cx(classes.title)}><span>{ title }</span></h3>
						)}
					</div>
					{ button && button.link && (
						<div className="col-xs-12 col-md-6 tac md-tar">
							<a href={`${ button.link }`}>
								<Button className={classes.button}>{ button.label }</Button>
							</a>
						</div>
					)}
				</div>
			</div>
		</div>
	) : null
}

export default is(style)(Small)