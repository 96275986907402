import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import is from 'react-jss'

import style from './style'

const FlashingText = ({
    classes,
    text,
}) => {
    const letters = text.replace(/ /g,'').split('')
    const [ animatedLetters, setAnimatedLetters ] = useState(text.split(' ').map((word, i) => <span key={i} className={classes.word}>{ word }</span>))
    const [ selector, setSelector ] = useState(Math.floor(Math.random() * letters.length))

    const fetchAnimatedText = () => {
        let i = -1

        setAnimatedLetters(text.split(' ').map((word, j) => (
            <span key={j} className={classes.word}>
                { word.split('').map((letter, k) => {
                    if (letter !== ' ') i++
    
                    return <span key={k} className={ cx(classes.letter, { [classes.active]: i >= 0 && i === selector }) }>{ letter }</span>
                }) }
            </span>
        )))
    }

    useEffect(() => {
        let interval = null

        fetchAnimatedText()
        interval = setInterval(() => {
            setSelector(Math.floor(Math.random() * letters.length))
        }, 3400)

        return () => clearInterval(interval)
    }, [ selector ])
    
    return (
        <h1 className={classes.title}>{ animatedLetters }</h1>
    )
}

export default is(style)(FlashingText)
