import React, { useState, useEffect } from "react"
import cx from "classnames"
import is from "react-jss"

import Panel from "../../../Panel"

import style from "./style"

const CareersArchiveFeatured = ({ classes, content }) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
  }, [])

  return (
    <Panel
      outer={[
        <div
          key="background"
          className={classes.background}
          style={{
            backgroundImage: `url(https://cdn-tiltingpoint-website.tiltingpoint.io/app/uploads/2020/05/19091853/careers-bg.jpg)`,
          }}
        />,
      ]}
      classNames={{
        panel: cx(classes.panel, {
          loaded,
        }),
        inner: classes.inner,
      }}
    >
      <div className={classes.featured}>
        <div className={classes.text}>
          <h1 className={classes.title}>Join our team</h1>
          <p className={classes.subtitle}>
            <span className="gradient-text">Let's grow together</span>
          </p>
        </div>
      </div>
    </Panel>
  )
}

export default is(style)(CareersArchiveFeatured)
