import React from 'react'

import ScaleMomentum from './ScaleMomentum'
import PowerUpTeamUp from './PowerUpTeamUp'
import OurProprietaryTechnology from './OurProprietaryTechnology'

const About = ({
    content: {
        aboutType: type,
        ...content
    },
    ...props
}) => {
    switch (type) {
        case 'scale-momentum': return <ScaleMomentum {...{ content, ...props }} />
        case 'power-up-team-up': return <PowerUpTeamUp {...{ content, ...props }} />
        case 'our-proprietary-technology': return <OurProprietaryTechnology {...{ content, ...props }} />
        default: return null
    }
}

export default About