import React, { useState } from 'react'
import { withRouter } from 'react-router'
import cx from 'classnames'
import is from 'react-jss'
import { graphql, useStaticQuery } from 'gatsby'


import BackgroundText from '../../../BackgroundText'
import Button from '../../../Button'
import Link from '../../../Link'
import PostArchiveCard from '../PostArchiveCard'

import style from './style'

const PostArchiveDefaultView = ({
	classes,
	archivePosts: posts,
	category,
}) => {

    const pageCount = 8

    const [ toDisplay, setToDisplay ] = useState(pageCount)

	const data = useStaticQuery(graphql`
		query PostArchiveDefault {
			allWpCategory {
    			nodes {
      				name
	  				slug
    			}
  			}
		}
	`)

	const categories = data?.allWpCategory?.nodes ?? []

	const loadMore = () => {
		setToDisplay(toDisplay + pageCount)
    }
	
    return (
		<div className={cx(classes.default, {
			// 'hide-bottom': root?.posts?.edges.length <= 0,
		})}>
			<BackgroundText {...{ text: [ 'Latest', 'News' ], flip: true, vertical: false, className: classes.backgroundText }} />

			<div className={classes.inner}>
				<div className="container">
					{ categories && categories?.length > 0 && (
						<div className="row">
							<div className="col-xs-12">
								<h2 className={classes.title}>Latest news</h2>
							</div>
							<div className="col-xs-12">
								<ul className={classes.categories}>
									<Link to={`/news`} className={classes.category}>
										<Button 
											color={category ? 'white' : 'black'}
											className={cx({ 'no-hover': !category })}
										>All</Button>
									</Link>
									
									{ categories?.map((c, i) => (
										<Link key={i} to={`/news/category/${c.slug}`} className={classes.category}>
											<Button 
												className={cx({ 'no-hover': category })}
												color={ c.slug !== category ? 'white' : 'black' }
											>{ c.name }</Button>
										</Link>
									)) }

								</ul>
							</div>
						</div>
					) }

					{ posts && posts?.length > 0 && (
						<div className="row">
							<div className="col-xs-12">
								<div className={cx(classes.posts, {
									// 'loading': relay.isLoading(),
								})}>
									{ posts?.slice(0, toDisplay).map((post, i) => (
										<div className={classes.post} key={i}>
											<PostArchiveCard key={i} index={i+1} post={post} />
										</div>
									)) }
								</div>
							</div>
						</div>
					)}

					{ posts?.length > toDisplay && (
						<div className={ cx(classes.loadMore, 'row') }>
							<div className="col-xs-12 tac">
								<Button
									color="black"
									clickHandler={ () => loadMore() }
									// disabled={relay.isLoading()}
								>Load More</Button>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
    )
}

export default is(style)(PostArchiveDefaultView)