export default ({
    spaces,
    breakpoints,
    colors,
}) => ({
    key: { name: 'HeroFullSocialIcons' },
    
    social: {
        width: '100%',
        position: 'absolute',
        right: 0,
        bottom: spaces[2],
        zIndex: 2,
        opacity: 0,
        transform: 'translate3d(25%, 0, 0)',
        textAlign: 'center',
        visibility: 'hidden',
        backfaceVisibility: 'hidden',
        '-webkit-backface-visibility': 'hidden',
        transition: 'transform ease-in-out .25s, opacity ease-in-out .25s, visibility ease-in-out .25s',

        '&.loaded': {
            transform: 'translate3d(0, 0, 0)',
            opacity: 1,
            visibility: 'visible',
        },

        [breakpoints.sm]: {
            width: 'auto',
            right: spaces[3],
            bottom: spaces[3],
        },

        '& > div > span > span': {
            paddingLeft: spaces[2],
            paddingRight: spaces[2],
        },
    },

    socialLink: {
        padding: '20px',
        
        '&:first-child': {
            marginLeft: 0,
        },
        [breakpoints.mw.md] : {
            padding: '25px' 
        },
        [breakpoints.mw.xs60] : {
            padding: '16px' 
        }
    },

    socialIcon: {
        fontSize: 21,
        color: colors.black,
        verticalAlign: 'middle',
    },
})
