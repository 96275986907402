export default ({
    colors,
    spaces,
    breakpoints,
}) => ({
    key: { name: 'OurOfferingsRightContent' },

    ourOfferingsRightContent: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: spaces[2],
        overflow: 'hidden',
        position: 'relative',

        '& > *': {
            flexBasis: '50%',
        },

        [breakpoints.md]: {
            marginTop: 0,
        }
    },

    mobileDivide: {
        width: 4,
        height: '200%',
        position: 'absolute',
        transform: 'translate(10px, -50%) rotate3d(0, 0, 1, -30deg)',
        backgroundColor: colors.grey,
        left: '50%',
        transformOrigin: '50% 50%',
        top: '50%',

        [breakpoints.md]: {
            display: 'none',
        }
    },

    iconRow: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',

        marginBottom: spaces[2],

        [breakpoints.md]: {
            flexDirection: 'row',
            justifyContent: 'center',

            marginBottom: 0,

            '&:first-child': {
                marginLeft: spaces[2],
            },
            '&:last-child': {
                marginRight: spaces[2],
            }
        }
    },

    icon: {
        fontSize: 25,
    },
    
    iconLabel: {
        display: 'block',
        color: 'white',
        margin: 0,
        marginBottom: spaces[0],
        
        [breakpoints.md]: {
            display: 'none',
        },
    }
})