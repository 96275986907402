import React, {  useEffect } from 'react'
import is from 'react-jss'

import { getImageSourceUrl, withIsVisible } from '../../helpers'

import withSlider from '../Slider'
import Panel from '../Panel'
import Button from '../Button'
import NavThumb from '../NavThumb'
import ProgressWheel from '../ProgressWheel'
import Link from '../Link'

import Backgrounds from './Backgrounds'
import Foregrounds from './Foregrounds'
import Titles from './Titles'

import style from './style'

const ParallaxSlider = ({
    classes,
    isVisible = false,
    loaded,
    setLoaded,
    index,
    setIndex,
    prevIndex,
    setPrevIndex,
    navigation,
    setNavigation,
    direction,
    setDirection,
    slides,
}) => {
    useEffect(() => {
        setLoaded(true)
    }, [ loaded ])

    withIsVisible

    useEffect(() => {
        let interval = null
        interval = setInterval(() => {
            next()
        }, 5000)

        return () => clearInterval(interval)
    }, [index, navigation, isVisible])

    const next = () => {
        if (navigation && isVisible) {
            setDirection(1)
            setNavigation(false)
            setPrevIndex(index) 

            if (index === slides.length - 1) {
                setIndex(0)
            } else {
                setIndex((prevState) => prevState+1)
            }

            setTimeout(() => {
                setNavigation(true)
            }, 1000)
        }
    }

    return (
        <Panel
            classNames={{ panel: classes.panel, container: classes.container, inner: classes.inner }}
            gradients={{ top: 'pink', bottom: 'primary' }}
            outer={[
                <NavThumb
                    key="thumb"
                    index={ index === slides.length - 1 ? 0 : index+1 }
                    images={ slides.map((slide) => getImageSourceUrl(slide?.game?.gameFields?.thumbnail, 'medium')) }
                    className={classes.thumb}
                    clickHandler={() => next()}
                    disabled={!navigation}
                />,
                <ProgressWheel key="progress" current={index + 1} max={slides.length} className={classes.progressWheel} />
            ]}
        >
            <div className={classes.text}>
                <Titles { ...{ index } } titles={ slides.map((slide) => ({ title: slide.bigText, subtitle: slide.smallText, slug: slide.game.slug })) } />
                <Link to={ `/games/${slides[index].game.slug}/` } className={classes.link}>
                    <Button>See results</Button>
                </Link>
                <Link to={ `/games/${slides[index].game.slug}/` } className={classes.btn}>
                    <Button className={classes.btn}>See results</Button>
                </Link>
            </div>

            <Backgrounds { ...{ index, prevIndex, direction } } images={ slides.map((slide, i) => ({ image: getImageSourceUrl(slide?.midground, 'panel_background'), slug: slide.game.slug })) } />
            <Foregrounds { ...{ index, prevIndex, direction } } images={ slides.map((slide, i) => slide.foreground.sourceUrl) } />
        </Panel>
    )   
}

export default is(style)(withSlider(withIsVisible(ParallaxSlider)))
