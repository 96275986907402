export default ({
    colors,
	spaces,
	breakpoints,
}) => ({
    key: { name: 'NewsHeader' },
    
	panel: {
		backgroundColor: colors.tertiary,

		'&::before': {
            display: 'block',
            width: 300,
            height: 300,
            position: 'absolute',
            top: '100%',
            left: 0,
            transform: 'translate3d(-30%, -60%, 0) scale(.8)',
            border: `3px solid ${colors.primary}`,
            borderRadius: '100%',
			opacity: 0,
            transition: 'opacity ease-in-out .25s, transform ease-in-out .25s',
            content: '""',
			zIndex: 2,

            [breakpoints.md]: {
                width: 400,
                height: 400,
                left: -spaces[3],
            },
		},
		
		'&.loaded': {
			'&::before': {
				transform: 'translate3d(-30%, -60%, 0) scale(1)',
				opacity: 1,
			},
		},
	},

	header: {
        padding: [spaces[5], 0, spaces[4]],

        [breakpoints.lg]: {
            padding: 0,
        },
	},

	background: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate3d(-50%, -50%, 0) scale(1.6)',
        transformOrigin: 'center',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        opacity: 0,
        transition: 'all ease-in-out .5s',
		zIndex: 1,

        '.loaded &': {
            transform: 'translate3d(-50%, -50%, 0) scale(1)',
            transitionDelay: '.15s',
            opacity: .7,
        },
    },
	
	text: {
        position: 'relative',
		textAlign: 'center',
		zIndex: 3,
	},
	
	title: {
		display: 'inline-block',
        margin: [spaces[4], 'auto', 0],
        fontSize: 45,
        lineHeight: '47px',
		color: colors.white,

		'& span': {
			position: 'relative',
			zIndex: 4,
		},

        [breakpoints.sm]: {
            fontSize: 54,
            lineHeight: '56px',
        },

        [breakpoints.md]: {
            fontSize: 82,
            lineHeight: '84px',
        },

        '&::before': {
            display: 'block',
            width: 3,
            height: 200,
            position: 'absolute',
            top: '50%',
			left: '80%',
            transform: 'translate3d(0, -50%, 0) rotate(-45deg)',
            transformOrigin: 'center',
            backgroundColor: colors.secondary,
            opacity: 0,
            transition: 'all ease-in-out .25s',
			content: '""',
			zIndex: 1,

            '.loaded &': {
				height: 340,
                transform: 'translate3d(0, -50%, 0) rotate(-30deg)',
                opacity: 1,
                transitionDelay: '.5s',
            },
        },
	},
	
	published: {
		margin: [spaces[4], 0, 0],
		fontSize: 24,
		lineHeight: '28px',
		fontWeight: 700,
	},

	author: {
		margin: [spaces[1], 0, 0],
		fontSize: 24,
		lineHeight: '28px',
		textTransform: 'uppercase',
		color: colors.white,
    },
    
    back: {
        paddingTop: spaces[3],
        backgroundColor: colors.white,
        textAlign: 'center',

        [breakpoints.md]: {
            paddingTop: spaces[4],
            textAlign: 'left',
        },
    },

    icon: {
		margin: [0, spaces[1], 0, 0],
		transform: 'translateY(-2px)',
		verticalAlign: 'middle',
		fontSize: 22,
		color: colors.white,

		'&.top': {
			margin: [0, 0, 0, spaces[1]],
		},
	},
})
