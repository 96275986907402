export default ({
    colors,
    spaces,
    breakpoints,
}) => ({
    key: { name: 'OurOfferings' },
    
    ourOfferings: {
        backgroundColor: colors.tertiary,
        color: colors.white,

        padding: [ spaces[5], 0 ],
    },

    title: {
        color: 'white',
        textAlign: 'center',
        marginBottom: spaces[4],
    },
    
    table: {
        display: 'flex',
        flexDirection: 'column',
    },
    row: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        
        [breakpoints.md]: {
            flexDirection: 'row',
        },

        '& > *': {
            flexBasis: '45%',

            '&:first-child': {
                flexBasis: '55%',
            },
        },

        borderBottom: `1px solid ${colors.grey}`,

        '&:last-child': {
            borderBottom: 'none',
        }
    },
    titleRow: {
        display: 'none',

        padding: 0,

        [breakpoints.md]: {
            display: 'flex',
        }
    },

    titleLabel: {
        textAlign: 'center',

        color: 'white',
        margin: 0,
    },

    iconRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        margin: [ 0, spaces[2], spaces[1], spaces[2] ],
    },

    divdeContainer: {
        position: 'relative',
        overflow: 'hidden',
    },

    desktopDivide: {
        position: 'absolute',
        top: '50%',
        width: 4,
        height: '200%',
        transform: 'translate(0px, -50%) rotate3d(0, 0, 1, -30deg)',
        backgroundColor: colors.grey,
        left: '50%',

        '&.left': {
            left: 0,
            transform: 'translate(18px, -50%) rotate3d(0, 0, 1, -30deg)',
        },

        '&.right': {
            left: '100%',
            transform: 'translate(-21px, -50%) rotate3d(0, 0, 1, -30deg)',
        }
    },

    closing: {
        marginTop: spaces[4],
    }
})