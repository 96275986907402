export default ({ colors, breakpoints, spaces, helpers }) => ({
  key: { name: "WhoWeAre" },
  pageWWA: {
    width: "100%",
    overflow: "hidden",
    position: "relative",
    backgroundSize: "cover",
    backgroundColor: "#000034",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    "&:before": {
      left: "50%",
      width: "150%",
      height: "60%",
      content: '""',
      display: "none",
      opacity: "0.8",
      zIndex: 1,
      position: "absolute",
      transform: "translateX(-50%)",
      top: 0,
      display: "block",
      background:
        "-webkit-radial-gradient(center top, ellipse cover, rgba(243,63,255,1) 0%, rgba(243,63,255,0) 65%)",
    },
    "&:after": {
      left: "50%",
      width: "150%",
      height: "60%",
      content: '""',
      display: "none",
      opacity: "0.8",
      zIndex: 1,
      position: "absolute",
      transform: "translateX(-50%)",
      bottom: 0,
      display: "block",
      opacity: 1,
      background:
        "-webkit-radial-gradient(center bottom, ellipse cover, rgba(1,196,254,1) 0%, rgba(1,196,254,0) 65%)",
    },
  },
  backWWAL: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    "&:before": {
      content: '""',
      display: "block",
      backgroundColor: "#6653fd",
      width: "3px",
      height: "15%",
      top: "30%",
      left: "10%",
      position: "absolute",
      transform: "translate3d(-50%, -50%, 19px) rotate(-36deg)",
      transition:
        "height ease-in-out .3s, width ease-in-out .3s, transform ease-in-out .3s",
      transitionDelay: ".1s",
    },
    "&:after": {
      content: '""',
      display: "block",
      border: "3px solid #f140ff",
      borderRadius: "100%",
      width: "400px",
      height: "400px",
      left: "-122px",
      position: "absolute",
      bottom: "-150px",
      zIndex: 2,
    },
  },
  backWWAR: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    "&:before": {
      backgroundColor: "#6653fd",
      content: '""',
      display: "block",
      width: "3px",
      height: "15%",
      position: "absolute",
      bottom: "13%",
      right: "7%",
      zIndex: 2,
      transform: "translate3d(-50%, -50%, 19px) rotate(-36deg)",
      transition:
        "height ease-in-out .3s, width ease-in-out .3s, transform ease-in-out .3s",
      transitionDelay: ".1s",
    },
    "&:after": {
      border: "3px solid #f140ff",
      borderRadius: "100%",
      content: '""',
      display: "block",
      width: "400px",
      height: "400px",
      position: "absolute",
      left: "100%",
      top: "-5%",
      zIndex: 2,
      transform: "translate3d(-90%, -30%, 0)",
    },
  },
  containerWWA: {
    width: "100%",
    margin: "0 auto",
    maxWidth: "1400px",
    padding: "70px 24px 165px",
    position: "relative",
    zIndex: 5,
  },
  pageWWATitle: {
    color: "#fff",
    fontSize: "60px",
    marginBottom: "105px",
    textAlign: "center",
    [breakpoints.mw.md]: {
      marginTop: "70px",
    },
  },
  gamesWWA: {
    marginBottom: "30px",
    position: "relative",
    "&:before": {
      background:
        "linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 4%,rgba(255,255,255,1) 50%,rgba(255,255,255,1) 96%,rgba(255,255,255,0) 100%)",
      boxShadow:
        "0 0 1rem #00f6ff, 0 0 2rem #00f6ff, 0 0 5rem #00f6ff, 0 0 10rem #00f6ff",
      content: '""',
      display: "block",
      width: "3px",
      height: "100%",
      position: "absolute",
      top: 0,
      left: "50%",
      transform: "translateX(-50%)",
      zIndex: 2,
      [breakpoints.mw.tb]: {
        display: "none",
      },
    },
  },
  gameLeft: {
    position: "relative",
    [breakpoints.mw.tb]: {
      display: "flex",
      justifyContent: "center",
    },

    "&:after": {
      backgroundColor: "#fff",
      boxShadow:
        "0 0 1rem #00f6ff, 0 0 2rem #00f6ff, 0 0 5rem #00f6ff, 0 0 10rem #00f6ff",
      borderRadius: "100%",
      content: '""',
      display: "block",
      width: "17px",
      height: "17px",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: "2",
      [breakpoints.mw.tb]: {
        display: "none",
      },
    },
  },
  gameRight: {
    display: "flex",
    justifyContent: "flex-end",
    position: "relative",
    [breakpoints.mw.tb]: {
      justifyContent: "center",
    },

    "&:before": {
      backgroundColor: "#fff",
      boxShadow:
        "0 0 1rem #00f6ff, 0 0 2rem #00f6ff, 0 0 5rem #00f6ff, 0 0 10rem #00f6ff",
      borderRadius: "100%",
      content: '""',
      display: "block",
      width: "17px",
      height: "17px",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: "2",
      [breakpoints.mw.tb]: {
        display: "none",
      },
    },
  },
  gameInside: {
    width: "50%",
    position: "relative",
    [breakpoints.mw.tb]: {
      width: "100%",
    },
  },
  gameLeftHero: {
    position: "absolute",
    left: "0",
    bottom: "0",
    maxWidth: "217px",
    [breakpoints.mw.xlg]: {
      width: "180px",
      img: {
        width: "100%",
        height: "auto",
      },
    },
    [breakpoints.mw.tb]: {
      position: "relative",
      left: "auto",
      bottom: "auto",
      margin: "0 auto 20px",
    },
  },
  gameHero2: {
    position: "absolute",
    right: "-70px",
    bottom: "0",
    maxWidth: "299px",
    [breakpoints.mw.xlg]: {
      right: "0",
      width: "180px",
      img: {
        width: "100%",
        height: "auto",
      },
    },
    [breakpoints.mw.tb]: {
      position: "relative",
      right: "auto",
      bottom: "auto",
      margin: "0 auto 20px",
    },
  },
  gameHero3: {
    position: "absolute",
    left: "-30px",
    bottom: "-10px",
    maxWidth: "240px",
    [breakpoints.mw.xlg]: {
      left: "0",
      bottom: "0",
      width: "180px",
      img: {
        width: "100%",
        height: "auto",
      },
    },
    [breakpoints.mw.tb]: {
      position: "relative",
      left: "auto",
      bottom: "auto",
      margin: "0 auto 20px",
    },
  },
  gameHero4: {
    position: "absolute",
    right: "0",
    bottom: "-15px",
    maxWidth: "299px",
    [breakpoints.mw.xlg]: {
      width: "180px",
      img: {
        width: "100%",
        height: "auto",
      },
    },
    [breakpoints.mw.tb]: {
      position: "relative",
      right: "auto",
      bottom: "auto",
      margin: "0 auto 20px",
    },
  },
  gameHero5: {
    position: "absolute",
    left: "0",
    bottom: "-12px",
    maxWidth: "260px",
    [breakpoints.mw.xlg]: {
      width: "180px",
      img: {
        width: "100%",
        height: "auto",
      },
    },
    [breakpoints.mw.tb]: {
      position: "relative",
      left: "auto",
      bottom: "auto",
      margin: "0 auto 20px",
    },
  },
  gameHero6: {
    position: "absolute",
    right: "-15px",
    bottom: "-10px",
    maxWidth: "200px",
    [breakpoints.mw.xlg]: {
      right: "0",
      width: "180px",
      img: {
        width: "100%",
        height: "auto",
      },
    },
    [breakpoints.mw.tb]: {
      position: "relative",
      right: "auto",
      bottom: "auto",
      margin: "0 auto 20px",
    },
  },
  gameLeftText: {
    display: "flex",
    alignItems: "center",
    background:
      "linear-gradient(to right, rgba(1,0,50,0) 0%,rgba(1,0,50,1) 75%)",
    borderRadius: "28px",
    color: "#fff",
    height: "170px",
    lineHeight: "1.25",
    padding: "10px 60px 10px 230px",
    textAlign: "right",
    [breakpoints.mw.xlg]: {
      height: "200px",
      paddingLeft: "185px",
      paddingRight: "40px",
    },
    [breakpoints.mw.tb]: {
      maxWidth: "600px",
      margin: "0 auto",
      height: "auto",
      background:
        "linear-gradient(to bottom,  rgba(1,0,50,1) 0%,rgba(1,0,50,0) 95%)",
      padding: "30px",
      textAlign: "center",
    },
  },
  gameRightText: {
    display: "flex",
    alignItems: "center",
    background:
      "linear-gradient(to right, rgba(1,0,50,1) 0%,rgba(1,0,50,0) 75%)",
    borderRadius: "28px",
    color: "#fff",
    height: "170px",
    lineHeight: "1.25",
    padding: "10px 240px 10px 60px",
    [breakpoints.mw.xlg]: {
      height: "200px",
      paddingLeft: "40px",
      paddingRight: "185px",
    },
    [breakpoints.mw.tb]: {
      height: "auto",
      maxWidth: "600px",
      margin: "0 auto",
      background:
        "linear-gradient(to bottom,  rgba(1,0,50,1) 0%,rgba(1,0,50,0) 95%)",
      padding: "30px",
      textAlign: "center",
    },
  },
  milestones: {
    background:
      "linear-gradient(to bottom, rgba(1,0,50,1) 0%,rgba(1,0,50,0) 95%)",
    borderRadius: "28px 28px 0 0",
    color: "#fff",
    width: "100%",
    maxWidth: "820px",
    margin: "0 auto -70px",
    padding: "20px 60px 60px",
    [breakpoints.mw.tb]: {
      padding: "20px 30px 40px",
    },
    [breakpoints.mw.sm]: {
      padding: "20px 15px 40px",
    },
  },
  milestonesTitle: {
    fontSize: "32px",
    fontWeight: "500",
    marginBottom: "10px !important",
    textTransform: "uppercase",
    textAlign: "center",
  },
  milestonesList: {
    display: "flex",
    justifyContent: "space-between",
    [breakpoints.mw.tb]: {
      display: "block",
    },
  },
  milestonesLeft: {
    flex: "0 0 50%",
    width: "50%",
    [breakpoints.mw.tb]: {
      width: "100%",
    },
  },
  milestonesRight: {
    flex: "0 0 45%",
    width: "45%",
    [breakpoints.mw.tb]: {
      width: "100%",
    },
  },
  milestonesOne: {
    display: "flex",
    alignItems: "center",
    marginTop: "35px",
    [breakpoints.mw.sm]: {
      display: "block",
      textAlign: "center",
    },

    "&:first-child": {
      marginTop: "0",
      [breakpoints.mw.tb]: {
        marginTop: "35px",
      },
    },
  },
  milestonesIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: "0 0 74px",
    width: "74px",
    height: "69px",
    marginRight: "23px",
    [breakpoints.mw.sm]: {
      margin: "0 auto 15px",
    },
  },
  milestonesCaption: {
    fontSize: "21px",
    fontWeight: "700",
    lineHeight: "1",
  },
  backgroundText: {
    position: "absolute",
    userSelect: "none",
    pointerEvents: "none",
    bottom: "0",
    right: "0",
  },
  backgroundTextWords: {
    color: "rgba(255,255,255, 0.1)",
    display: "block",
    fontSize: "13vw",
    fontFamily: "'Zona Pro', 'Helvetica Nueue', 'Arial', sans-serif",
    fontWeight: "bold",
    lineHeight: ".85",
    textTransform: "uppercase",
    textAlign: "right",
    whiteSpace: "nowrap",
    opacity: "1",
    [breakpoints.xlg]: {
      fontSize: "10.5vw",
    },
  },
  gameSection: {
    backgroundColor: "#000034",
    color: "#fff",
    width: "100%",
    overflow: "hidden",
    position: "relative",
    "&:before": {
      left: "50%",
      width: "150%",
      height: "60%",
      content: '""',
      display: "block",
      opacity: "0.8",
      zIndex: 1,
      position: "absolute",
      transform: "translateX(-50%)",
      top: 0,
      background:
        "-webkit-radial-gradient(center top, ellipse cover, rgba(243,63,255,1) 0%, rgba(243,63,255,0) 65%)",
    },
    "&:after": {
      left: "50%",
      width: "150%",
      height: "60%",
      content: '""',
      display: "block",
      opacity: "0.8",
      zIndex: 1,
      position: "absolute",
      transform: "translateX(-50%)",
      bottom: 0,
      background:
        "-webkit-radial-gradient(center bottom, ellipse cover, rgba(102,83,253,1) 0%, rgba(102,83,253,0) 65%)",
    },
  },
  gameContainer: {
    width: "100%",
    margin: "0 auto",
    maxWidth: "1800px",
    padding: "70px 0",
    position: "relative",
    zIndex: 5,
    [breakpoints.mw.max]: {
      padding: "70px 24px 30px",
    },
  },
  gameContainerTitle: {
    color: "#fff",
    fontSize: "60px",
    marginBottom: "70px",
    textAlign: "center",
    [breakpoints.mw.md]: {
      marginTop: "70px",
    },
  },
  gameColumns: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  gameBlock: {
    flex: "0 0 25%",
    width: "25%",
    padding: "0 20px",
    position: "relative",
    [breakpoints.mw.s]: {
      padding: 0,
    },
    "&:before": {
      backgroundColor: "#fff",
      borderRadius: "4px",
      boxShadow:
        "0 0 1rem #00f6ff, 0 0 2rem #00f6ff, 0 0 5rem #00f6ff, 0 0 10rem #00f6ff",
      content: '""',
      display: "block",
      width: "3px",
      height: "90%",
      position: "absolute",
      top: "50%",
      left: "-2px",
      transform: "translateY(-50%)",
      [breakpoints.mw.md]: {
        width: "50%",
        height: "3px",
        top: "-30px",
        left: "50%",
        transform: "translateX(-50%)",
      },
    },
    "&:first-child": {
      "&:before": {
        display: "none",
      },
    },
    [breakpoints.mw.max]: {
      "&:nth-child(3)": {
        display: "none",
      },
      flex: "0 0 50%",
      width: "50%",
      marginBottom: "50px",
    },
    [breakpoints.mw.md]: {
      "&:nth-child(3)": {
        display: "block",
      },
      flex: "0 0 100%",
      width: "100%",
    },
  },
  gameSubtitle: {
    fontSize: "24px",
    marginBottom: "30px !important",
    textTransform: "uppercase",
    textAlign: "center",
  },
  gameIcon: {
    borderRadius: "6px",
    display: "block",
    width: "50px",
    height: "50px",
    margin: "0 auto 5px",
    overflow: "hidden",
    img: {
      width: "100%",
      height: "100%",
    },
  },
  gameName: {
    fontSize: "9px",
    lineHeight: 1,
  },
  gameList: {
    display: "flex",
    flexWrap: "wrap",
    textAlign: "center",
    paddingLeft: 0,
  },
  gameListLi: {
    flex: "0 0 20%",
    width: "20%",
    height: "110px",
    padding: "0 5px",
    "&:before": {
      display: "none",
    },
  },
  gameListBig: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    textAlign: "center",
    paddingLeft: 0,
  },
  gameListBigLi: {
    flex: "0 0 33.333333%",
    width: "33.333333%",
    padding: "0 5px",
    "&:before": {
      display: "none",
    },
  },
  gameIconBig: {
    borderRadius: "12px",
    display: "block",
    width: "90px",
    height: "90px",
    margin: "0 auto 10px",
    overflow: "hidden",
    img: {
      width: "100%",
      height: "100%",
    },
  },
  gameListTextLi: {
    marginBottom: "15px",
    "&:before": {
      display: "none",
    },
  },
  gameMoreLink: {
    backgroundColor: "rgba(1, 0, 50, 0.4)",
    borderRadius: "6px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50px",
    height: "50px",
    fontSize: "12px",
    lineHeight: 1,
  },
})
