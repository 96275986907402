export default ({
    colors,
    breakpoints,
    spaces,
    helpers,
}) => ({
    key: { name: 'Wheel' },
    
    theme_dark: {
        '&::after': {
            height: '30%',
            opacity: .5,
        },
    },

    inner: {
        alignItems: 'flex-start',

        [breakpoints.mw.lg]: {
            paddingBottom: 0,
        },
    },

    title: {
        display: 'none',
        position: 'relative',
        width: 300,
        fontSize: 54,
        lineHeight: '56px',
        textAlign: 'left',
        color: colors.white,

        '& > span': {
            display: 'block',
            position: 'relative',
            zIndex: 2,
        },

        '&::before': {
            display: 'block',
            width: 2,
            height: '200%',
            position: 'absolute',
            top: 0,
            left: '70%',
            transform: 'translateY(-20%) rotate(-35deg)',
            backgroundColor: colors.blue,
            zIndex: 1,
            content: '""',
        },

        [breakpoints.lg]: {
            display: 'block',
        },
    },

    description: {
        display: 'none',
        position: 'relative',
        width: 240,
        fontSize: 24,
        lineHeight: '28px',
        textAlign: 'left',
        ...helpers.gradientText,
        textTransform: 'uppercase',
        fontWeight: 'bold',

        [breakpoints.lg]: {
            display: 'inline-block',
        },
    },

    posts: {
        width: '100%',
        paddingTop: '75%',
        position: 'relative',

        [breakpoints.mw.lg]: {
            transform: 'none!important',
        },

        [breakpoints.md]: {
            paddingTop: '56.25%',
        },

        [breakpoints.lg]: {
            width: '240%',
            paddingTop: '240%',
            position: 'absolute',
            top: '50%',
            right: '60%',
            transform: 'translate3d(0, -50%, 0)',
            transformOrigin: 'center',
        },
    },

    animated: {
        transition: 'transform ease-in-out .5s',
    },

    navigation: {
        display: 'flex',
        justifyContent: 'center',
        margin: [spaces[3], 'auto'],
        zIndex: 2,

        [breakpoints.lg]: {
            flexDirection: 'column',
            margin: 0,
            position: 'absolute',
            top: '50%',
            transform: 'translate(0, -50%)',
            right: spaces[3],
        },
    },
    
    navigationItem: {
        width: 8,
        height: 8,
        padding: spaces[1],
        position: 'relative',
        cursor: 'pointer',
        zIndex: 3,

        [breakpoints.sm]: {
            padding: spaces[2],
        },

        '&::before': {
            display: 'block',
            width: 8,
            height: 8,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: 8,
            border: `2px solid ${colors.darkGrey}`,
            content: '""',
            transition: 'border-color ease-in-out .15s',
        },

        '&:hover': {
            '&::before': {
                borderColor: colors.white,
            },
        },

        '&.active': {
            '&::before': {
                backgroundColor: colors.black,
                borderColor: `${colors.secondary}!important`,
                boxShadow: `0 0 10px 0 ${colors.secondary}`,
            },
        },
    },

    progressWheel: {
        position: 'absolute',
        bottom: spaces[3],
        left: spaces[3],
        zIndex: 2,
    },

    link: {
        display: 'none',
        justifyContent: 'center',
        position: 'relative',
        margin: [spaces[3], 'auto', spaces[4]],
        zIndex: 2,

        [breakpoints.lg]: {
            display: 'block',
            margin: 0,
            position: 'absolute',
            bottom: spaces[3],
            left: '50%',
            transform: 'translate(-50%, 0)',
        },

        '$showLink &': {
            display: 'flex',
        },
    },

    color_primary: { backgroundColor: '#34a0fb' },
    color_secondary: { backgroundColor: '#f140ff' },
    color_tertiary: { backgroundColor: '#000034' },
    
    color_white: {
        backgroundColor: '#ffffff',

        '& $title': {
            color: colors.black,
        },

        '& $navigationItem': {
            '&:hover': {
                '&::before': {
                    borderColor: colors.black,
                },
            },
        },
    },

    color_black: { backgroundColor: '#000000' },
    
    color_pink: { backgroundColor: '#f33fff' },
    color_blue: { backgroundColor: '#6653fd' },
    color_yellow: { backgroundColor: '#ffc813' },

    color_darkGrey: { backgroundColor: '#5a595e' },

    card: {
        width: '100%',
        maxWidth: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate3d(-50%, -50%, 0)',
        opacity: 0,
        filter: 'grayscale(100%)',

        [breakpoints.sm]: {
            maxWidth: '90%',
        },

        [breakpoints.lg]: {
            maxWidth: '20%',
            left: '100%',
            transformOrigin: '-250% 50%',
            opacity: .3,

            '&:nth-child(1)': {
                transform: 'translateY(-50%) rotate(0)',
                order: 1,
            },
    
            '&:nth-child(2)': {
                transform: 'translateY(-50%) rotate(15deg)',
                order: 2,
            },
    
            '&:nth-child(3)': {
                transform: 'translateY(-50%) rotate(30deg)',
                order: 3,
            },
    
            '&:nth-child(4)': {
                transform: 'translateY(-50%) rotate(45deg)',
                order: 4,
            },
    
            '&:nth-child(5)': {
                transform: 'translateY(-50%) rotate(60deg)',
                order: 5,
            },
    
            '&:nth-child(6)': {
                transform: 'translateY(-50%) rotate(75deg)',
                order: 6,
            },
    
            '&:nth-child(7)': {
                transform: 'translateY(-50%) rotate(90deg)',
                order: 7,
            },
    
            '&:nth-child(8)': {
                transform: 'translateY(-50%) rotate(105deg)',
                order: 8,
            },
    
            '&:nth-child(9)': {
                transform: 'translateY(-50%) rotate(120deg)',
                order: 9,
            },
    
            '&:nth-child(10)': {
                transform: 'translateY(-50%) rotate(135deg)',
                order: 10,
            },
    
            '&:nth-child(11)': {
                transform: 'translateY(-50%) rotate(150deg)',
                order: 11,
            },
    
            '&:nth-child(12)': {
                transform: 'translateY(-50%) rotate(165deg)',
                order: 12,
            },
    
            '&:nth-child(13)': {
                transform: 'translateY(-50%) rotate(180deg)',
                order: 13,
            },
    
            '&:nth-child(14)': {
                transform: 'translateY(-50%) rotate(195deg)',
                order: 14,
            },
    
            '&:nth-child(15)': {
                transform: 'translateY(-50%) rotate(210deg)',
                order: 15,
            },
    
            '&:nth-child(16)': {
                transform: 'translateY(-50%) rotate(225deg)',
                order: 16,
            },
        },

        '&.active': {
            opacity: 1,
            pointerEvents: 'auto',
            filter: 'none',
        },
    },

    showLink: {},
})
