import React from 'react'
import cx from 'classnames'
import is from 'react-jss'

import style from './style'

const Midgrounds = ({
    classes,
    images = [],
    index,
    direction = 1,
    prevIndex,
}) => {
    return (
        <div className={ cx(classes.midgrounds, classes[`direction${direction}`] || classes.direction1) }>
            { images.map((image, i) => (
                <div
                    key={i}
                    style={{ backgroundImage: `url(${image})` }}
                    className={ cx(
                        classes.midground, {
                            active: index === i,
                            [classes.out]: prevIndex === i,
                            [classes.in]: index === i,
                        },
                    ) }
                />
            ))}
        </div>
    )
}

export default is(style)(Midgrounds)
