import React from "react"
import cx from "classnames"
import is from "react-jss"

import style from "./style"

const TeamText = ({
  classes,
  post: { name, jobTitle, description },
  active = false,
  className,
}) => (
  //NOTES: updates to admin panel?
  <div className={cx(classes.text, className, { active })}>
    {name === "Kevin Segalla" ? (
      <a href="https://www.tiltingpoint.com/kevinsegalla/" rel="noreferrer">
        <h3 className={classes.name}>{name}</h3>
      </a>
    ) : (
      <h3 className={classes.name}>{name}</h3>
    )}
    <span className={classes.jobTitle}>{jobTitle}</span>
    <p className={classes.description}>{description}</p>
  </div>
)

export default is(style)(TeamText)
