export default ({
    colors,
}) => ({
    key: { name: 'PostArchiveFeaturedBackgrounds' },
    
    backgrounds: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: colors.tertiary,
        opacity: 0,

        '&.loaded': {
            opacity: 1,
        },
    },

    background: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate3d(-50%, -50%, 0)',
        transformOrigin: 'center',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        opacity: 0,
        transition: 'opacity ease-in-out .5s',
        zIndex: 0,

        '&.active': {
            transitionDelay: '.15s',
            opacity: 1,
            zIndex: 1,
        },
    },
})
