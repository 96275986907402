import React from 'react'
import cx from 'classnames'
import is from 'react-jss'

import RandomText from '../../../RandomText'

import style from './style'

const Quotes = ({
    classes,
    quotes = [],
    index,
}) => (
    <div className={classes.quotes}>
        { quotes.map(({ quote, name }, i) => (
            <div key={i} className={ cx(classes.quote, { active: i === index }) }>
                { quote } <span className={classes.name}>- { name }</span>
            </div>
        )) }
    </div>
)

export default is(style)(Quotes)
