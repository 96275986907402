export default ({
    colors,
    breakpoints,
}) => ({
    key: { name: 'GameArchiveFeaturedMidgrounds' },

    titles: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        height: '100%',
        margin: 0,
        padding: 0,
    },

    title: {
        display: 'none',
        margin: 0,
        fontSize: 30,
        lineHeight: '32px',
		color: colors.white,
		
        [breakpoints.sm]: {
            paddingRight: '25%',
            fontSize: 54,
            lineHeight: '66px',
        },

        [breakpoints.lg]: {
            paddingRight: '40%',
        },

        [breakpoints.lg]: {
            fontSize: 80,
            lineHeight: '76px',
        },

        '&.active': {
            display: 'block',
        },
    },
})
