export default ({
    colors,
    spaces,
    breakpoints,
}) => ({
    key: { name: 'PostArchiveFeatured' },
    
    panel: {
        '&::before, &::after': {
            display: 'block',
            top: 0,
            backgroundColor: colors.tertiary,
            content: '""',
            zIndex: 2,
        },

        '&::before': {
            display: 'block',
            width: '100%',
            height: '100%',
            left: 0,
            transform: 'none',
            opacity: 0,
        },

        '&::after': {
            width: '100%',
            height: '100%',
            left: 0,
            transform: 'translate3d(20%, 0, 0)',
            '-webkit-clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 100% 100%)',
            'clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 100% 100%)',
            opacity: 0,
            transition: 'opacity ease-in-out .25s, transform ease-in-out .25s',
            transitionDelay: '0s',

            [breakpoints.sm]: {
                left: '10%',
                '-webkit-clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 40% 100%)',
                'clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 40% 100%)',
            },

            [breakpoints.sm]: {
                left: '30%',
                '-webkit-clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 30% 100%)',
                'clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 30% 100%)',
            },
        },

        '&.loaded': {
            '&::before': {
                opacity: .5,
            },

            '&::after': {
                transform: 'translate3d(0, 0, 0)',
                opacity: 1,
            },

            '&.disabled': {
                '&::after': {
                    transform: 'translate3d(20%, 0, 0)',

                    [breakpoints.sm]: {
                        transform: 'translate3d(-20%, 0, 0)',
                    },
        
                    [breakpoints.sm]: {
                        transform: 'translate3d(-20%, 0, 0)',
                    },
                },
            }
        },
    },

    inner: {
        height: '100vh',
        alignItems: 'flex-start',
        position: 'relative',
        zIndex: 4,
    },

    featured: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        padding: 0,

        '&::after': {
            display: 'block',
            width: 300,
            height: 300,
            position: 'absolute',
            top: '100%',
            left: 0,
            transform: 'translate3d(-30%, -60%, 0)',
            border: `3px solid ${colors.primary}`,
            borderRadius: '100%',
            zIndex: 1,
            content: '""',

            [breakpoints.md]: {
                width: 400,
                height: 400,
                left: -spaces[3],
            },
        },

        [breakpoints.lg]: {
            padding: 0,
        },
    },

    tag: {
        margin: [0, 0, spaces[2]],
        
        [breakpoints.lg]: {
            margin: [0, 'auto', spaces[2]],
        }
    },

    text: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        height: '100%',
        maxHeight: 440,
        paddingTop: spaces[5],
        position: 'relative',
        textAlign: 'left',
        zIndex: 4,

        [breakpoints.sm]: {
            maxHeight: 500,
            paddingTop: 0,
            paddingRight: 150 + spaces[2],
        },

        [breakpoints.lg]: {
            maxHeight: 600,
            paddingLeft: 150 - spaces[4] + spaces[2],
            paddingRight: 150 - spaces[4] + spaces[2],
        },
    },

    textBottom: {
        width: '100%',
    },

    button: {
        position: 'relative',
        zIndex: 4,
    },

    next: {
        display: 'none',
        position: 'absolute',
        top: '50%',
        right: 0,
        transform: 'translateY(-50%)',
        zIndex: 4,

        [breakpoints.sm]: {
            display: 'block',
        },
    },

    prev: {
        display: 'none',
        position: 'absolute',
        top: '50%',
        left: 0,
        transform: 'translateY(-50%)',
        zIndex: 4,

        [breakpoints.lg]: {
            display: 'block',
        },
    },
})
