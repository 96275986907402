export default ({
    colors,
	spaces,
    breakpoints,
}) => ({
    key: { name: 'CareersArchiveFeatured' },
    
	panel: {
        backgroundColor: colors.tertiary,
        
        '&::before, &::after': {
            display: 'block',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            opacity: 0,
            transform: 'none',
            transition: 'opacity ease-in-out .25s, transform ease-in-out .25s',
            backgroundColor: colors.tertiary,
            content: '""',
			zIndex: 2,
        },

        '&::before': {
            opacity: .3,
        },

        '&::after': {
            transform: 'translate3d(-20%, 0, 0)',
            '-webkit-clip-path': 'polygon(0% 0%, 0% 0%, 100% 100%, 0% 100%)',
            'clip-path': 'polygon(0% 0%, 50% 0%, 100% 100%, 0% 100%)',

            [breakpoints.sm]: {
                width: '70%',
                '-webkit-clip-path': 'polygon(0% 0%, 60% 0%, 100% 100%, 0% 100%)',
                'clip-path': 'polygon(0% 0%, 60% 0%, 100% 100%, 0% 100%)',
            },
        },

        '&.loaded': {
            '&::after': {
                transform: 'translate3d(0, 0, 0)',
                opacity: .65,
            },
        },
    },
    
    inner: {
        position: 'relative',
        zIndex: 3,
    },

	featured: {
        padding: [spaces[5], 0, spaces[4]],

        [breakpoints.lg]: {
            padding: 0,
        },

        '&::before': {
            display: 'block',
            width: 300,
            height: 300,
            position: 'absolute',
            top: 0,
            left: '65%',
            transform: 'translate3d(-30%, -60%, 0) scale(.8)',
            border: `3px solid ${colors.primary}`,
            borderRadius: '100%',
			opacity: 0,
            transition: 'opacity ease-in-out .25s, transform ease-in-out .25s',
            content: '""',
			zIndex: 2,

            [breakpoints.md]: {
                width: 400,
                height: 400,
            },
		},
		
		'&.loaded': {
			'&::before': {
				transform: 'translate3d(-30%, -60%, 0) scale(1)',
				opacity: 1,
			},
		},
	},

	background: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate3d(-50%, -50%, 0) scale(1.6)',
        transformOrigin: 'center',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        opacity: 0,
        transition: 'all ease-in-out .5s',
		zIndex: 1,

        '.loaded &': {
            transform: 'translate3d(-50%, -50%, 0) scale(1)',
            transitionDelay: '.15s',
            opacity: .7,
        },
    },
	
	text: {
        position: 'relative',
		textAlign: 'center',
		zIndex: 3,
	},
	
	title: {
		display: 'inline-block',
        maxWidth: 540,
        margin: [spaces[4], 'auto', 0],
        fontSize: 45,
        lineHeight: '47px',
		color: colors.white,

        [breakpoints.sm]: {
            fontSize: 54,
            lineHeight: '56px',
        },

        [breakpoints.md]: {
            fontSize: 82,
            lineHeight: '84px',
        },
	},
	
	subtitle: {
		margin: [spaces[4], 0, 0],
		fontSize: 24,
		lineHeight: '28px',
        fontWeight: 700,
        textTransform: 'uppercase',
	},
})
