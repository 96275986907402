import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import is from 'react-jss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import OurOfferingsLeftContent from '../LeftContent'
import OurOfferingsRightContent from '../RightContent'

import style from './style'

const Offering = ({
    classes,
    o,
}) => {
    const [ hover, setHover ] = useState(false)

    return (
        <div className={classes.row}>
            <OurOfferingsLeftContent {...{ ...o, hover, setHover }} />
            <OurOfferingsRightContent {...{ ...o }} />

            <div className={cx(classes.description, {
                'show': hover === true,
            })}>
                <FontAwesomeIcon icon={[ 'fas', 'times-circle' ]} color="black" className={classes.icon} onClick={ e => setHover(false) }/>
                <span>{ o.description }</span>
            </div>
        </div>
    )
}

export default is(style)(Offering)