export default ({
    colors,
    spaces,
    typography,
    breakpoints,
}) => ({
    key: { name: 'Partners' },
    
    panel: {
        backgroundColor: colors.tertiary,
    },

    inner: {
        justifyContent: 'flex-start',
        height: '100vh',
    
        [breakpoints.sm]: {
            minHeight: 960,
        },

        [breakpoints.lg]: {
            minHeight: 0,
        },
    },

    partners: {
        padding: [spaces[5], 0],
    },

    logos: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
    },

    logoColumn: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: [spaces[1], spaces[1]],
        transition: 'all ease-in-out 1s',

        '&:nth-child(1)': {
            '& $logo': {
                filter: 'grayscale(100%) brightness(50%) sepia(100%) hue-rotate(-135deg) saturate(1000%) contrast(1)',
            },
        },

        '&:nth-child(2)': {
            flexDirection: 'column-reverse',

            '& $logo': {
                filter: 'grayscale(100%) brightness(50%) sepia(100%) hue-rotate(-150deg) saturate(1000%) contrast(1)',
            },
        },

        '&:nth-child(3)': {
            display: 'none',

            '& $logo': {
                filter: 'grayscale(100%) brightness(50%) sepia(100%) hue-rotate(-160deg) saturate(1000%) contrast(1)',
            },

            [breakpoints.sm]: {
                display: 'block',
            },
        },

        '&:nth-child(4)': {
            flexDirection: 'column-reverse',
            display: 'none',

            '& $logo': {
                filter: 'grayscale(100%) brightness(50%) sepia(100%) hue-rotate(-175deg) saturate(1000%) contrast(1)',
            },

            [breakpoints.md]: {
                display: 'flex',
            },
        },

        '&:nth-child(5)': {
            display: 'none',

            '& $logo': {
                filter: 'grayscale(100%) brightness(50%) sepia(100%) hue-rotate(-195deg) saturate(1000%) contrast(1)',
            },

            [breakpoints.lg]: {
                display: 'flex',
            },
        },

        '&:nth-child(6)': {
            display: 'none',
            flexDirection: 'column-reverse',

            '& $logo': {
                filter: 'grayscale(100%) brightness(50%) sepia(100%) hue-rotate(-210deg) saturate(1000%) contrast(1)',
            },

            [breakpoints.xlg]: {
                display: 'flex',
            },
        },
    },

    logo: {
        flexShrink: 0,
        width: '85%',
        opacity: .35,
    },

    title: {
        ...typography.h1,
        display: 'none',
        color: colors.white,

        [breakpoints.md]: {
            display: 'block',
        },
    },
})