import React from 'react'

import ParallaxSlider from '../../../ParallaxSlider'

const GamesArchiveSlider = ({
    content,
}) => (
    <ParallaxSlider
        slides={content.sliderContent || []}
    />
)

export default GamesArchiveSlider
