export default ({
    colors,
    breakpoints,
}) => ({
    key: { name: 'PostArchiveFeaturedTitles' },

    titles: {
        width: '100%',
        maxWidth: 800,
        margin: [0, 'auto'],
        position: 'relative',
        color: colors.white,
        zIndex: 4,

        [breakpoints.lg]: {
            textAlign: 'center',
        },
    },

    title: {
        display: 'none',
        margin: 0,
        fontSize: 24,
        lineHeight: '28px',
        color: colors.white,

        [breakpoints.sm]: {
            fontSize: 30,
            lineHeight: '32px',
        },

        [breakpoints.md]: {
            fontSize: 54,
            lineHeight: '66px',
        },

        [breakpoints.xlg]: {
            fontSize: 80,
            lineHeight: '76px',
        },

        '&.active': {
            display: 'block',
        },
    },
})
