import React from 'react'
import is from 'react-jss'
import cx from 'classnames'

import style from './style'

const Panel = ({
    classes,
    image = false,
    gradients = {},
    classNames = {},
    outer,
    children,
}) => (
    <div
        className={ cx(
            classes.panel,
            classNames.panel,
            classes[`gradientTop_${gradients.top || 'none'}`],
            classes[`gradientRight_${gradients.right || 'none'}`],
            classes[`gradientBottom_${gradients.bottom || 'none'}`],
            classes[`gradientLeft_${gradients.left || 'none'}`],
        ) }
    >
        { image && (
            <div className={ cx(classes.background, classNames.background) } style={{ backgroundImage: `url(${image})` }} />
        ) }
        <div className={ cx(classes.container, classNames.container) }>
            <div className={classes.padding}>
                <div className={ cx(classes.inner, classNames.inner) }>
                    { children }
                </div>
            </div>
        </div>
        { outer }
    </div>
)

export default is(style)(Panel)
