import React from 'react'
import cx from 'classnames'
import LazyLoad from 'react-lazyload'
import is from 'react-jss'

import style from './style'

const Foregrounds = ({
    classes,
    images = [],
    index,
    prevIndex,
    direction,
}) => (
    <div className={ cx(classes.foregrounds, classes[`direction${direction}`] || classes.direction1) }>
        { images.map((image, i) => (
            <LazyLoad key={i} offset={200} height={0}>
                <img
                    src={image}
                    className={ cx(
                        classes.foreground, {
                            active: index === i,
                            [classes.out]: prevIndex === i,
                            [classes.in]: index === i,
                        },
                    ) }
                />
            </LazyLoad>
        )) }
    </div>
)

export default is(style)(Foregrounds)
