import React, { useState } from "react"
import cx from "classnames"
import is from "react-jss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { useMenuState } from "../../../../context"

import Button from "../../../Button"
import Link from "../../../Link"

import style from "./style"

const companyFunctionsItems = [
  { id: "developer", label: "Developer" },
  { id: "licensor", label: "Licensor" },
  { id: "platform", label: "Platform" },
  { id: "web3", label: "Web3" },
  { id: "other", label: "Other" },
]

const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const validateText = input => input.replace(/\s/g, "") !== ""
const validateEmail = input => validateText(input) && reg.test(input)
const validateAtLeastOneSelection = input => input.length

const Input = ({ classes, value, setValue, name, placeholder }) => {
  const [valid, setValid] = useState(null)

  return (
    <input
      {...{
        value,
        name,
        placeholder,
        type: "text",
        className: cx(classes.input, {
          [classes.error]: valid !== null && !valid,
        }),
        onChange: e => setValue(e.target.value),
        onBlur: e =>
          setValid(
            name === "email" ? validateEmail(value) : validateText(value)
          ),
      }}
    />
  )
}

const CheckboxList = ({
  classes,
  name,
  items,
  title,
  setValue,
  selections,
}) => {
  return (
    <div
      style={{ display: "flex", alignItems: "start", flexDirection: "column" }}
      className={cx(classes.checkboxContainer)}
    >
      <span>{title}</span>
      <div className={classes.checkboxListContainer}>
        {items.map(({ id, label }) => {
          return (
            <div key={id}>
              <input
                type="checkbox"
                checked={selections.includes(id)}
                name={name}
                id={`${name}-${id}`}
                value={id}
                className={cx(classes.checkboxBtn)}
                onChange={({ target }) => {
                  const { checked, value } = target
                  setValue(
                    checked
                      ? [...selections, value]
                      : selections.filter(id => id !== value)
                  )
                }}
              />
              <label for={`${name}-${id}`} style={{ marginLeft: "10px" }}>
                {label}
              </label>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const TextArea = ({ classes, value, setValue, name, placeholder }) => {
  const [valid, setValid] = useState(null)

  return (
    <textarea
      {...{
        value,
        name,
        placeholder,
        rows: 6,
        className: cx(classes.input, {
          [classes.error]: valid !== null && !valid,
        }),
        onChange: e => setValue(e.target.value),
      }}
    ></textarea>
  )
}

const Default = ({ classes, content: { title } }) => {
  const {
    options: { enquiryEmail },
  } = useMenuState()

  const [name, setName] = useState("")
  const [companyFunctionSelections, setCompanyFunctionSelections] = useState([])
  const [company, setCompany] = useState("")
  const [email, setEmail] = useState("")
  const [help, setHelp] = useState("")
  const [
    whyInterestedInTiltingPoint,
    setWhyInterestedInTiltingPoint,
  ] = useState("")

  const [message, setMessage] = useState("")

  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const doSubmit = async e => {
    if (!loading) {
      if (
        validateText(name) &&
        validateText(company) &&
        validateAtLeastOneSelection(companyFunctionSelections) &&
        validateEmail(email)
      ) {
        setLoading(true)
        setMessage(false)

        let response = await fetch(
            `${process.env.GATSBY_API_ENDPOINT}/contact/submit`,
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                fields: {
                  to: enquiryEmail,
                  name,
                  company,
                  companyFunctionSelections,
                  whyInterestedInTiltingPoint,
                  email,
                },
              }),
            }
          ),
          data = await response.json()

        if (data.success) {
          setMessage("Thank you for your enquiry. We'll be in touch soon.")
          setSubmitted(true)
        } else {
          setMessage("Sorry, something went wrong. Please try again.")
        }

        setLoading(false)
      } else {
        setMessage("Please check and complete the form.")
      }
    }
  }

  return (
    <div className={cx(classes.contact)} id="form">
      <div className={cx("container", "container--wide")}>
        <div className={cx("row")}>
          <div
            className={cx("col-xs-12", "col-md-4", "col-lg-3", classes.left)}
          >
            <h2 className={cx(classes.title)}>RAISE YOUR GAME WITH US</h2>

            <h4 className={cx(classes.subtitle)}>FOR GENERAL INQUIRIES</h4>
            <a className={cx(classes.email)} href={`mailto:${enquiryEmail}`}>
              {enquiryEmail}
            </a>

            <h4 className={cx(classes.careers)}>
              For job applications please visit{" "}
              <Link to="/careers" className={classes.careersLink}>
                careers
              </Link>
            </h4>
          </div>
          <div className={cx("col-xs-12", "col-md-7", "col-lg-offset-1")}>
            <div
              className={cx(classes.form, {
                [classes.loading]: loading || submitted,
                [classes.submitted]: submitted,
              })}
            >
              <Input
                {...{
                  value: name,
                  setValue: setName,
                  name: "name",
                  placeholder: "What's your name?",
                  classes,
                }}
              />
              <Input
                {...{
                  value: email,
                  setValue: setEmail,
                  name: "email",
                  placeholder: "What's your email address?",
                  classes,
                }}
              />
              <Input
                {...{
                  value: company,
                  setValue: setCompany,
                  name: "company",
                  placeholder: "What's your company's name?",
                  classes,
                }}
              />
              <CheckboxList
                {...{
                  selections: companyFunctionSelections,
                  classes,
                  name: "companyFunction",
                  setValue: setCompanyFunctionSelections,
                  items: companyFunctionsItems,
                  title: "What does your company do?",
                }}
              />

              <div className={cx(classes.textBoxContainer)}>
                <TextArea
                  {...{
                    classes,
                    value: whyInterestedInTiltingPoint,
                    setValue: setWhyInterestedInTiltingPoint,
                    name: "whyInterestedInTiltingPoint",
                    placeholder:
                      "What is your interest in working with Tilting Point?",
                  }}
                />
              </div>
              <div className={cx(classes.submitButtonContainer)}>
                <Button clickHandler={doSubmit} className={cx(classes.submit)}>
                  <FontAwesomeIcon
                    icon={["far", "angle-right"]}
                    color="black"
                    className={classes.icon}
                  />
                </Button>
              </div>
            </div>

            {message.length > 0 && <p className={classes.message}>{message}</p>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default is(style)(Default)
