export default ({
    colors,
    breakpoints,
    spaces,
    helpers,
}) => ({
    key: { name: 'CareerHeader' },
    
    panel: {
        '&::before': {
            display: 'block',
            width: '50%',
            height: '100%',
            left: 0,
            top: 0,
            transform: 'translate3d(-50%, 0, 0)',
            ...helpers.gradientBackgrounds.fadeOut.bottomTop,
            '-webkit-clip-path': 'polygon(0% 0%, 0% 0%, 100% 100%, 0% 100%)',
            'clip-path': 'polygon(0% 0%, 0% 0%, 100% 100%, 0% 100%)',
            opacity: 0,
            transition: 'opacity ease-in-out .25s, transform ease-in-out .25s',
            zIndex: 1,

            [breakpoints.sm]: {
                '-webkit-clip-path': 'polygon(0% 0%, 40% 0%, 100% 100%, 0% 100%)',
                'clip-path': 'polygon(0% 0%, 40% 0%, 100% 100%, 0% 100%)',
            },

            [breakpoints.sm]: {
                '-webkit-clip-path': 'polygon(0% 0%, 40% 0%, 100% 100%, 0% 100%)',
                'clip-path': 'polygon(0% 0%, 40% 0%, 100% 100%, 0% 100%)',
            },
        },

        '&::after': {
            display: 'block',
            width: '50%',
            height: '100%',
            right: 0,
            top: 0,
            transform: 'translate3d(50%, 0, 0)',
            ...helpers.gradientBackgrounds.fadeOut.bottomTop,
            '-webkit-clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 100% 100%)',
            'clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 100% 100%)',
            opacity: 0,
            transition: 'opacity ease-in-out .25s, transform ease-in-out .25s',
            zIndex: 1,

            [breakpoints.sm]: {
                '-webkit-clip-path': 'polygon(30% 0%, 100% 0%, 100% 100%, 100% 100%)',
                'clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 100% 100%)',
            },

            [breakpoints.sm]: {
                '-webkit-clip-path': 'polygon(40% 0%, 100% 0%, 100% 100%, 100% 100%)',
                'clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 100% 100%)',
            },
        },

        '&.loaded': {
            '&::before, &::after': {
                opacity: .5,
                transform: 'translate3d(0, 0, 0)',
            },
        },
    },
    
    background: {
        transform: 'scale(1.6)',
        transformOrigin: 'center',
        transition: 'all ease-in-out .25s',

        '.loaded &': {
            transform: 'scale(1)',
        },
    },

    inner: {
        '&::after': {
            display: 'block',
            width: 300,
            height: 300,
            position: 'absolute',
            top: '100%',
            left: 0,
            transform: 'translate3d(-30%, -60%, 0) scale(.9)',
            border: `3px solid ${colors.secondary}`,
            borderRadius: '100%',
            zIndex: 1,
            transition: 'opacity ease-in-out .25s, transform ease-in-out .25s',
            opacity: 0,
            content: '""',

            [breakpoints.md]: {
                width: 400,
                height: 400,
                left: -spaces[3],
            },

            '.loaded &': {
                transform: 'translate3d(-30%, -60%, 0) scale(1)',
                opacity: 1,
                transitionDelay: '.5s',
            },
        },
    },

    header: {
        padding: [spaces[5], 0, spaces[4]],

        [breakpoints.lg]: {
            padding: 0,
        },
    },

    text: {
        position: 'relative',
        textAlign: 'center',
    },

    title: {
        maxWidth: 800,
        width: '100%',
        margin: [spaces[2], 'auto'],
        position: 'relative',
        fontSize: 30,
        lineHeight: '32px',
        color: colors.white,

        '& > span': {
            position: 'relative',
            zIndex: 4,
        },

        [breakpoints.sm]: {
            fontSize: 45,
            lineHeight: '47px',
        },

        [breakpoints.md]: {
            fontSize: 54,
            lineHeight: '56px',
            marginBottom: spaces[3],
        },

        [breakpoints.lg]: {
            fontSize: 82,
            lineHeight: '84px',
            marginBottom: spaces[4],
        },

        '& > div': {
            position: 'relative',
            zIndex: 2,
        },

        '&::before': {
            display: 'block',
            width: 3,
            height: '150%',
            position: 'absolute',
            top: '50%',
            left: '80%',
            transform: 'translate3d(-50%, -50%, 0) rotate(-36deg)',
            transformOrigin: 'center',
            backgroundColor: colors.primary,
            opacity: 0,
            transition: 'all ease-in-out .25s',
            zIndex: 1,
            content: '""',

            '.loaded &': {
                transform: 'translate3d(-50%, -50%, 0) rotate(-36deg)',
                opacity: 1,
                transitionDelay: '.5s',
            },
        },
    },

    tag: {
        position: 'relative',
        zIndex: 2,
        transform: 'translate3d(0, 100%, 0)',
        opacity: 0,
        visibility: 'hidden',
        transition: 'all ease-in-out .25s',
        transitionDelay: '.25s',

        '.loaded &': {
            transform: 'translate3d(0, 0, 0)',
            opacity: 1,
            visibility: 'visible',
        },
    },
})
