import React, { useState } from "react"
import cx from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import is from "react-jss"
import { graphql, useStaticQuery } from "gatsby"

import BackgroundText from "../../../BackgroundText"
import Button from "../../../Button"

import CareersArchiveDefaultCard from "./CareersArchiveDefaultCard"

import style from "./style"

const Default = ({ classes }) => {
  const data = useStaticQuery(graphql`
    query CareerArchive {
      allWpCareer(sort: { fields: date, order: DESC }) {
        nodes {
          slug
          title
          date
          content
          careerFields {
            greenhouseId
            location
            departments
            offices
            questions
            locationQuestions
            updatedAt
          }
        }
      }
    }
  `)

  const [location, setLocation] = useState({ open: false, query: null })
  const [department, setDepartment] = useState({ open: false, query: null })

  let departmentOptions = data?.allWpCareer?.nodes
    ?.map(({ careerFields: { departments } }) => JSON.parse(departments))
    .reduce((a, b) => (b ? [...a, ...b] : [...a]), [])
  departmentOptions = departmentOptions.filter(
    (item, i) => departmentOptions.map(d => d.name).indexOf(item.name) === i
  )

  let locationOptions = data?.allWpCareer?.nodes?.map(
    ({ careerFields: { location: l } }) => l?.split(", ")[0]
  )
  locationOptions = locationOptions.filter(
    (item, i) => locationOptions.indexOf(item) === i
  )

  const toDisplay = data?.allWpCareer?.nodes?.filter(
    ({ careerFields: { departments: d, location: l } }) =>
      (!department.query ||
        JSON.parse(d)
          .map(({ name }) => name)
          .includes(department.query)) &&
      (!location.query || l?.split(", ")[0] === location.query)
  )
  console.log(toDisplay, data?.allWpCareer)
  const loadMore = () => {}

  return (
    <div className={classes.careers}>
      <BackgroundText
        {...{
          text: ["Our", "Vacancies"],
          flip: true,
          vertical: false,
          className: classes.backgroundText,
        }}
      />

      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <h2 className={classes.title}>Our vacancies</h2>
          </div>

          <div className="col-xs-12">
            <div className={classes.filters}>
              <div className={classes.filter}>
                <Button
                  color="black"
                  {...{
                    clickHandler: e => {
                      setLocation({ ...location, open: false })
                      setDepartment({ ...department, open: !department.open })
                    },
                  }}
                >
                  {department?.query || "Department"}{" "}
                  <FontAwesomeIcon
                    icon={["far", "angle-down"]}
                    color="white"
                    className={cx("icon", classes.icon)}
                  />
                </Button>

                {department.open && (
                  <div className={classes.filterOptions}>
                    <div
                      className={classes.filterOption}
                      onClick={e => setDepartment({ open: false, query: null })}
                    >
                      All
                    </div>

                    {departmentOptions.map(({ name }, i) => (
                      <div
                        key={i}
                        className={cx(classes.filterOption, {
                          active: name === department?.query,
                        })}
                        onClick={e =>
                          setDepartment({ open: false, query: name })
                        }
                      >
                        <span>{name}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className={classes.filter}>
                <Button
                  color="white"
                  {...{
                    clickHandler: e => {
                      setDepartment({ ...department, open: false })
                      setLocation({ ...location, open: !location.open })
                    },
                  }}
                >
                  {location?.query || "Location"}{" "}
                  <FontAwesomeIcon
                    icon={["far", "angle-down"]}
                    color="black"
                    className={cx("icon", classes.icon)}
                  />
                </Button>

                {location.open && (
                  <div className={classes.filterOptions}>
                    <div
                      className={classes.filterOption}
                      onClick={e => setLocation({ open: false, query: null })}
                    >
                      All
                    </div>

                    {locationOptions.map((l, i) => (
                      <div
                        key={i}
                        className={classes.filterOption}
                        onClick={e => setLocation({ open: false, query: l })}
                      >
                        {l}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          {toDisplay.length > 0 && (
            <div className={cx("col-xs-12", classes.grid)}>
              {toDisplay.map((career, i) => (
                <CareersArchiveDefaultCard
                  {...{ key: i, index: (i % 8) + 1 }}
                  career={career}
                />
              ))}
            </div>
          )}

          {toDisplay.length === 0 && (
            <div className={cx("col-xs-12", "tac", classes.empty)}>
              <h3>No results found</h3>
            </div>
          )}

          {/* <div className="col-xs-12 tac">
						<Button
						color="black" 
							clickHandler={ () => loadMore() }
							className={classes.loadMore}
							// disabled={relay.isLoading()}
						>
							Load More
						</Button>
					</div> */}
        </div>
      </div>
    </div>
  )
}

export default is(style)(Default)
