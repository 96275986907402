import React, { useState, useEffect } from 'react'
import * as _ from 'underscore'
import cx from 'classnames'
import is from 'react-jss'

import style from './style'

/**
 * Images
 */
const images = [
    'https://cdn-tiltingpoint-website.tiltingpoint.io/static/shards/shard1.jpg',
    'https://cdn-tiltingpoint-website.tiltingpoint.io/static/shards/shard2.jpg',
    'https://cdn-tiltingpoint-website.tiltingpoint.io/static/shards/shard3.jpg',
    'https://cdn-tiltingpoint-website.tiltingpoint.io/static/shards/shard4.jpg',
    'https://cdn-tiltingpoint-website.tiltingpoint.io/static/shards/shard5.jpg',
    'https://cdn-tiltingpoint-website.tiltingpoint.io/static/shards/shard6.jpg',
    'https://cdn-tiltingpoint-website.tiltingpoint.io/static/shards/shard7.jpg',
    'https://cdn-tiltingpoint-website.tiltingpoint.io/static/shards/shard8.jpg',
    'https://cdn-tiltingpoint-website.tiltingpoint.io/static/shards/shard9.jpg',
    'https://cdn-tiltingpoint-website.tiltingpoint.io/static/shards/shard10.jpg',
    'https://cdn-tiltingpoint-website.tiltingpoint.io/static/shards/shard11.jpg',
    'https://cdn-tiltingpoint-website.tiltingpoint.io/static/shards/shard12.jpg',
    'https://cdn-tiltingpoint-website.tiltingpoint.io/static/shards/shard13.jpg',
    'https://cdn-tiltingpoint-website.tiltingpoint.io/static/shards/shard14.jpg',
    'https://cdn-tiltingpoint-website.tiltingpoint.io/static/shards/shard15.jpg',
    'https://cdn-tiltingpoint-website.tiltingpoint.io/static/shards/shard16.jpg',
    'https://cdn-tiltingpoint-website.tiltingpoint.io/static/shards/shard17.jpg',
    'https://cdn-tiltingpoint-website.tiltingpoint.io/static/shards/shard18.jpg',
    'https://cdn-tiltingpoint-website.tiltingpoint.io/static/shards/shard19.jpg',
]

/**
 * randomIntFromInterval
 * 
 * @param {int} min
 * @param {int} max
 */
const randomIntFromInterval = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min)
}

/**
 * createShards
 */
const createShards = () => {
    const sizes = ['xs', 's', 'm', 'l', 'xl', 'full']
    const helperIndex = Math.random()
    const offsets = [
        randomIntFromInterval(30, 70),
        randomIntFromInterval(25, 50),
    ]
    const imageUrls = _.sample(images, 2)

    let shards = []

    // Add large shard
    shards.push({
        size: 'full',
        color: helperIndex >= .5 ? 'primary' : 'secondary',
        position: [offsets[0], offsets[1]],
    })

    // Add images
    shards.push(
        {
            size: sizes[randomIntFromInterval(3, sizes.length-2)],
            position: [
                offsets[0] - randomIntFromInterval(8, 20),
                offsets[1] - randomIntFromInterval(6, 16),
            ],
            image: imageUrls[0],
            color: 'tertiary',
            delay: 0.2,
        },
        {
            size: sizes[randomIntFromInterval(3, sizes.length-2)],
            position: [
                offsets[0] + randomIntFromInterval(8, 20),
                offsets[1] + randomIntFromInterval(6, 16),
            ],
            image: imageUrls[1],
            color: 'tertiary',
            delay: 0.6,
        },
    )

    // Add fragments
    for (let i = 0; i < 2; i++) {
        shards.push({
            size: sizes[randomIntFromInterval(0, 2)],
            position: [
                offsets[0] - ((i % 2 === 0 ? -1 : 1) * randomIntFromInterval(15, 20)),
                offsets[1] - ((i % 2 === 0 ? -1 : 1) * randomIntFromInterval(10, 20)),
            ],
            color: helperIndex < .5 ? 'primary' : 'secondary',
            delay: (i * 0.2) + 0.2,
        })
    }

    return shards
}

const Shards = ({
    classes,
    isVisible = false,
}) => {
    const [shards, setShards] = useState(createShards())
    const [state, setState] = useState(1)
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        let interval = null
        interval = setInterval(() => {
            if (isVisible) {
                setState(state === 2 ? 0 : state+1)
            }
        }, state === 2 ? 1000 : 3000)

        return () => clearInterval(interval)
    }, [ state, shards, isVisible ])

    useEffect(() => {
        if (state === 2) {
            setShards(createShards())
        }
    }, [ state ])

    useEffect(() => {
        setLoaded(true)
    }, [])

    return (
        <div className={ cx(classes.shards, `state_${state}`, { loaded }) }>
            { shards.map((shard, i) => (
                <div
                    key={i}
                    className={ cx(
                        classes.shard,
                        classes[`size_${shard.size}`] || classes.size_s,
                        classes[`color_${shard.color}`] || classes.color_transparent,
                    ) }
                    style={{
                        top: `${shard.position[1]}%`,
                        left: `${shard.position[0]}%`,
                        transitionDelay: `${shard.delay || 0}s`,
                    }}
                >
                    { shard.image && (
                        <span className={classes.image} style={{ backgroundImage: `url(${shard.image})` }} />
                    ) }
                </div>
            )) }
        </div>
    )
}

export default is(style)(Shards)
