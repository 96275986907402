export default ({
    colors,
    breakpoints,
	spaces,
	typography,
	helpers,
}) => ({
	key: { name: 'ProprietaryTechnology' },
	
	panel: {
		padding: [spaces[5], 0],
		backgroundColor: colors.tertiary,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
	},
	
    technologies: {
		textAlign: 'left',

		[breakpoints.md]: {
			textAlign: 'left',
		},
	},

	name: {
		position: 'relative',
		display: 'inline-block',
		float: 'left',
		marginBottom: spaces[2],

		'&:before': {
			...helpers.gradientBackgrounds.rightLeft,
			...helpers.clipRect('0 0', `calc(100% - ${spaces[2]}px) 0`, '100% 100%', '0 100%'),
			
			content: '""',
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,

			marginLeft: -(spaces[3] + spaces[2]),
			marginRight: -spaces[2],

			[breakpoints.lg]: {
				marginLeft: -(spaces[4] + spaces[3] + spaces[2]),
			}
		},

		'& h2': {
			...typography.h1,
			margin: '0!important',
			position: 'relative',
			display: 'inline-block',
			float: 'left',
			transform: 'translateY(2px)',
		},
	},

	title: {
		lineHeight: 1,
		
		[breakpoints.md]: {
			maxWidth: 300,
		},
	},

	tagline: {
		...helpers.gradientText,
		textTransform: 'capitalize',

		fontSize: 20,

		[breakpoints.md]: {
			maxWidth: 300,
			fontSize: 22,
		},
	},

	copy: {
		fontSize: 18,

		[breakpoints.md]: {
			fontSize: 22,
		},
	},

	image: {
		marginTop: spaces[3],

		'& img': {
			display: 'block',
			width: '100%',
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},

	light: {
		'& $name': {
			color: colors.white,
		}
	},
	dark: {
		'& $title, & $copy': {
			color: colors.white,
		}
	},
})