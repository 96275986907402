export default ({
    colors,
    breakpoints,
    spaces,
    helpers,
    gradients,
}) => ({
    key: { name: 'PostArchiveLatestViewCard' },
    
    card: {
        transition: 'opacity ease-in-out .25s',
        pointerEvents: 'none',

        '&:hover': {
            '& .btn': {
                color: colors.white,

                '&::before': {
                    transform: 'translate3d(0, 0, 0)',
                },
            },

            '& $line': {
                width: 3,
                height: '80%',
                transform: 'translate3d(0, 20%, -19px) rotate(-35deg)',
                transitionDelay: '0s',
            },
        },
    },

    tilt: {
        transformStyle: 'preserve-3d',
        transform: 'perspective(1000px)',
    },

    inner: {
        width: '100%',
        paddingTop: '75%',
        position: 'relative',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',

        [breakpoints.md]: {
            paddingTop: '55%',
        }
    },

    image: {
        display: 'block',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        ...helpers.clipCorners.default,
        transition: 'clip-path ease-in-out .15s',
        transitionDelay: '.15s',
        overflow: 'hidden',
        zIndex: 1,

        '&::before': {
            display: 'block',
            width: '250%',
            height: '150%',
            position: 'absolute',
            top: '-50%',
            left: '-100%',
            background: gradients.radial.bottom.tertiary,
            opacity: .6,
            transition: 'opacity ease-in-out .15s',
            content: '""',
        },
    },

    text: {
        display: 'block',
        width: '80%',
        maxWidth: 500,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate3d(-50%, -50%, 50px)',
        textAlign: 'left',
        zIndex: 1,

        [breakpoints.lg]: {
            width: '100%',
            left: 0,
            transform: 'translate3d(50px, -50%, 50px)',
        },
    },

    date: {
        display: 'inline-block',
        margin: [0, 0, spaces[0]],
        fontSize: 22,
        fontWeight: 700,
        textTransform: 'uppercase',
        ...helpers.gradientText,
        zIndex: 1,
    },

    title: {
        paddingRight: spaces[3],
        position: 'relative',
        fontSize: 20,
        lineHeight: '22px',
        fontWeight: 700,
        textTransform: 'uppercase',
        color: colors.white,
        zIndex: 1,

        '.browser:not(.ie) &': {
            color: 'transparent',
            '-webkit-text-stroke-width': '1px',
            '-webkit-text-stroke-color': colors.white,
        },

        '&::after': {
            display: 'block',
            paddingRight: spaces[3],
            position: 'absolute',
            top: 0,
            left: 0,
            content: 'attr(data-text)',
            color: colors.white,
            clip: 'rect(200px, 800px, 800px, 0px)',
            transition: 'clip ease-in-out .5s',

            '.active &': {
                clip: 'rect(20px, 800px, 800px, 0px)',

                [breakpoints.lg]: {
                    clip: 'rect(30px, 800px, 800px, 0px)',
                },
            },

            '.browser.ie &': {
                display: 'none',
            },
        },

        [breakpoints.sm]: {
            fontSize: 24,
            lineHeight: '26px',
		},
		

        [breakpoints.lg]: {
            fontSize: 32,
            lineHeight: '34px',
        },

        [breakpoints.xlg]: {
            paddingRight: 0,

            '&::after': {
                paddingRight: 0,
            }
        },
    },

    btn: {
        position: 'absolute',
        left: '50%',
        top: '100%',
        transform: 'translate3d(0, -55%, 20px)',
        backfaceVisibility: 'hidden',
        opacity: 0,
        transition: 'opacity ease-in-out .5s',
        overflow: 'hidden',
        zIndex: 1,

        [breakpoints.lg]: {
            '.active &': {
                opacity: 1,
            },
        },

        '& .btn': {
            overflow: 'hidden',

            '&::before': {
                display: 'block',
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                transform: 'translate3d(-100%, 0, 10px)',
                backgroundColor: colors.primary,
                background: `linear-gradient(left, ${colors.primary}, ${colors.secondary})`,
                content: '""',
                pointerEvents: 'none',
                transition: 'transform ease-in-out .1s',
                zIndex: 1,
            },
        }
    },

    line: {
        display: 'none',
        width: 2,
        height: '40%',
        backgroundColor: colors.primary,
        position: 'absolute',
        top: -spaces[4],
        right: spaces[5],
        transform: 'translate3d(0, 0, -19px) rotate(-10deg)',
        transition: 'height ease-in-out .3s, width ease-in-out .3s, transform ease-in-out .3s',
        transitionDelay: '.1s',
        zIndex: 0,

        '.active &': {
            height: '60%',
            transform: 'translate3d(0, 0, -19px) rotate(-25deg)',

            [breakpoints.lg]: {
                height: '100%',
            },
        },

        [breakpoints.md]: {
            display: 'block',
        },

        [breakpoints.lg]: {
            height: '50%',
            top: -spaces[3],
            right: spaces[3],
        },
    },
})
