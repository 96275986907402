export default ({
	colors,
	spaces,
	breakpoints,
	gradients,
}) => ({
	key: { name: 'PostArchiveImageRows' },
	
    imageRows: {
		backgroundColor: colors.tertiary,
		position: 'relative',
		overflow: 'hidden',
		
		padding: [ spaces[5], 0 ],

		[breakpoints.md]: {
			padding: [ spaces[6], 0 ],
		},

		'&:before, &:after': {
            width: '150%',
            height: '60%',
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            content: '""',
            zIndex: 1,
			opacity: .8,
		},

		'&:before': {
            top: 0,
            background: gradients.radial.top.pink,
		},

		'&:after': {
            bottom: 0,
            background: gradients.radial.bottom.primary,
            opacity: 1,
		},
	}
})