export default ({
    colors,
    spaces,
    breakpoints,
    typography,
}) => ({
    key: { name: 'OurOfferingsLeftContent' },

    ourOfferingsLeftContent: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',

        marginTop: spaces[2],

        [breakpoints.md]: {
            marginTop: 0,
            alignItems: 'center',
        },
    },

    icon: {
		...typography.h4,
		
		position: 'relative',

        width: spaces[3],
        height: spaces[3],
        fontSize: spaces[2],
        lineHeight: `${spaces[3]}px`,
        textAlign: 'center',
        borderRadius: 100,
        flexShrink: 0,
        
        marginBottom: 0,

        color: 'white',
        backgroundColor: colors.grey,

        transition: 'all .25s ease',

        '&:hover, &.hover': {
            backgroundColor: 'white',
            color: 'black',
		},
		
		'&.desktop': {
			display: 'none',
		},

		[breakpoints.md]: {
			'&.mobile': {
				display: 'none',
			},
			'&.desktop': {
				display: 'block',
			},
		},
    },

    content: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: spaces[2],

        '& > *': {
            margin: 0,
        },

        [breakpoints.md]: {
            marginLeft: spaces[3],
        },
    },

    title: {
        color: 'white',
    },

    tagline: {
        maxWidth: 240,
        lineHeight: '20px',
        minHeight: 40,
    },
    overlay: {
        position: 'absolute',
	},
	
	interaction: {
		position: 'absolute',
		top: -10,
		left: -10,
		right: -10,
		bottom: -10,
		
		cursor: 'pointer',
	}
})