import React from "react"
import is from "react-jss"

import style from "./style"

const WhoWeArePage = ({ classes, content }) => {
  const items = content?.whoweareitems[0]
  return (
    <>
      <div className={classes.pageWWA}>
        <div className={classes.backWWAR}></div>
        <div className={classes.backWWAL}></div>
        <div className={classes.containerWWA}>
          <h1 className={classes.pageWWATitle}>Our History</h1>
          {items && (
            <div className={classes.gamesWWA}>
              <div className={classes.gameLeft}>
                <div className={classes.gameInside}>
                  <article className={classes.gameLeftText}>
                    {items.description1}
                  </article>
                  <figure className={classes.gameLeftHero}>
                    <img src={items.image1.sourceUrl} alt="" />
                  </figure>
                </div>
              </div>
              <div className={classes["gameRight"]}>
                <div className={classes.gameInside}>
                  <article className={classes.gameRightText}>
                    {items.description2}
                  </article>
                  <figure className={classes.gameHero2}>
                    <img src={items.image2.sourceUrl} alt="" />
                  </figure>
                </div>
              </div>
              <div className={classes["gameLeft"]}>
                <div className={classes.gameInside}>
                  <article className={classes.gameLeftText}>
                    {items.description3}
                  </article>
                  <figure className={classes.gameHero3}>
                    <img src={items.image3.sourceUrl} alt="" />
                  </figure>
                </div>
              </div>
              <div className={classes["gameRight"]}>
                <div className={classes.gameInside}>
                  <article className={classes.gameRightText}>
                    {items.description4}
                  </article>
                  <figure className={classes.gameHero4}>
                    <img src={items.image4.sourceUrl} alt="" />
                  </figure>
                </div>
              </div>
              <div className={classes["gameLeft"]}>
                <div className={classes.gameInside}>
                  <article className={classes.gameLeftText}>
                    {items.description5}
                  </article>
                  <figure className={classes.gameHero5}>
                    <img src={items.image5.sourceUrl} alt="" />
                  </figure>
                </div>
              </div>
              <div className={classes["gameRight"]}>
                <div className={classes.gameInside}>
                  <article className={classes.gameRightText}>
                    {items.description6}
                  </article>
                  <figure className={classes.gameHero6}>
                    <img src={items.image6.sourceUrl} alt="" />
                  </figure>
                </div>
              </div>
            </div>
          )}

          <div className={classes.milestones}>
            <p className={classes.milestonesTitle}>Milestones</p>
            {content?.milestones?.length && content?.milestones && (
              <div className={classes.milestonesList}>
                <div className={classes.milestonesLeft}>
                  {content.milestones.slice(0, 3).map(item => (
                    <div className={classes.milestonesOne}>
                      <span className={classes.milestonesIcon}>
                        <img src={item.milestoneimage.sourceUrl} alt="" />
                      </span>
                      <div className={classes.milestonesText}>
                        <p className={classes.milestonesCaption}>
                          {item.milestonename}
                        </p>
                        {item.milestonedescription || ""}
                      </div>
                    </div>
                  ))}
                </div>
                <div className={classes.milestonesRight}>
                  {content?.milestones?.length &&
                    content?.milestones?.length > 3 &&
                    content?.milestones.slice(3).map(item => (
                      <div className={classes.milestonesOne}>
                        <span className={classes.milestonesIcon}>
                          <img src={item.milestoneimage.sourceUrl} alt="" />
                        </span>
                        <div className={classes.milestonesText}>
                          <p className={classes.milestonesCaption}>
                            {item.milestonename}
                          </p>
                          {item.milestonedescription || ""}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={classes.backgroundText}>
          <span className={classes.backgroundTextWords}>Who we are</span>
        </div>
      </div>
    </>
  )
}

export default is(style)(WhoWeArePage)
