import React, { useState } from 'react'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import is from 'react-jss'
import jsonp from 'jsonp'
import queryString from 'query-string'

import { useMenuState } from '../../../../context'

import Button from '../../../Button'

import style from './style'

const Newsletter = ({
    classes,
	content,
}) => {
	const { options: { mailchimp }} = useMenuState()
	const [ email, setEmail ] = useState('')
	const [ status, setStatus ] = useState('active')

	const submit = async () => {
		setStatus('loading')

		jsonp(`${mailchimp}&${queryString.stringify({ EMAIL: email })}`, { param: 'c' }, (err, data) => {
			if (err) {
				setStatus('error')
			} else {
				if (data.result === 'error') {
					if (data.msg.includes('is already subscribed')) {
						setStatus('existing')
					} else {
						setStatus('error')
					}
				} else {
					setStatus('success')
				}
			}
		})
	}

	return (
		<div className={classes.newsletter} style={{ backgroundImage: `url(https://cdn-tiltingpoint-website.tiltingpoint.io/app/uploads/2020/05/19092120/newsletter-bg.jpg)` }}>
			<div className={classes.gradient} />
			<div className="container container--wide">
				<div className="row reverse align-middle tac lg-tal">
					<div className={ cx('col-xs-12 col-lg-6', classes.titleContainer) }>
						<h2 className={classes.title}><span>Join our newsletter</span></h2>
					</div>
					<div className={cx('col-xs-12', 'col-lg-6')}>
						{ status === 'success' ? (
							<p className={classes.message}>Thank you for subscribing!</p>
						) : (
							<div className={ cx({ [classes.error]: status === 'error' }) }>
								<input type="hidden" name="u" value="e3f3b65326d33255715f6487d" />
								<input type="hidden" name="id" value="6ccb9d1265" />

								<div className={classes.emailContainer}>
									<input type="email" name="email" className={classes.email} placeholder="Email" value={email} onChange={ e => setEmail(e.target.value) } />
								</div>

								<Button color="gradient" clickHandler={ e => submit() } className={classes.btn}>
									<FontAwesomeIcon icon={[ 'far', 'angle-right' ]} color="white" className="icon" />
								</Button>

								{ status === 'error' && (
									<p className={ cx(classes.message, classes.formMessage) }>Something went wrong, please try again.</p>
								) }

								{ status === 'existing' && (
									<p className={ cx(classes.message, classes.formMessage) }>You are already subscribed.</p>
								) }
							</div>
						) }
					</div>
				</div>
			</div>
		</div>
	)
}

export default is(style)(Newsletter)
