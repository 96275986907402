import React from 'react'
import cx from 'classnames'
import is from 'react-jss'

import style from './style'

const Legals = ({
	classes,
	legals,
}) => legals && legals !== '' ? (
	<div className={cx(classes.legals)}>
		<div className={cx('container')}>
			<div className={cx('row')}>
				<div className={cx('col-xs-12', classes.column)}>
					<p>{ legals }</p>
				</div>
			</div>
		</div>
	</div>
) : null

export default is(style)(Legals)