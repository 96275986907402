import React from 'react'
import is from 'react-jss'

import { getImageSourceUrl, withIsVisible } from '../../../../helpers'
import { useMenuState } from '../../../../context'

import Panel from '../../../Panel'

import Shards from './components/Shards'
import FlashingText from './components/FlashingText'
import SocialIcons from './components/SocialIcons'

import style from './style'
import Buttons from './components/Buttons'

const Full = ({
    classes,
    isVisible = false,
    content: {
        title,
        background,
        social,
    },
}) => {
    const { options: { socialLinks } } = useMenuState()

    const backgroundUrl = getImageSourceUrl(background, 'panel_background')

    return (
        <Panel
            image={backgroundUrl}
            classNames={{ panel: classes.panel }}
            outer={[
                <Shards key="shards" { ...{ isVisible } } />,
                <SocialIcons key="social" { ...{ social, socialLinks } } />,
                <Buttons key="btn" { ...classes }/>
            ]}
        >
            <div className={classes.hero}>
                <FlashingText text={title} />
            </div>
        </Panel>
    )
}

export default is(style)(withIsVisible(Full))
