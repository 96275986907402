import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import is from 'react-jss'

import TextWheel from '../../TextWheel'

import Card from './Card'
import Text from './Text'

import style from './style'

const Team = ({content}) => {
    const [ posts, setPosts ] = useState([])

    useEffect(() => {
        let sorted = content ? content.teamMembers : []

        // console.log(sorted)
        // sorted = [
        //     sorted[sorted.length-2],
        //     sorted[sorted.length-1],
        //     ...sorted.slice(0, sorted.length-2),
        // ]
        setPosts(sorted)
    }, [content])

    return content.teamMembers.length >= 5 ? (
        <TextWheel { ...{
            posts,
            Text,
            Card,
            color: 'white',
            progressColor: 'primary',
            progressBackground: 'white',
            title: 'Meet our leadership team',
        } } />
    ) : null
}

export default is(style)(Team)