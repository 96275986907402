export default ({
    spaces,
    colors,
    helpers,
    gradients,
    breakpoints,
}) => ({
    key: { name: 'ContactNewsletter' },

    newsletter: {
        padding: [spaces[5], 0],
        position: 'relative',
        backgroundColor: colors.tertiary,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },

    gradient: {
		width: '100%',
		height: '100%',
		position: 'absolute',
		top: 0,
		left: 0,
		backgroundColor: helpers.hexToRgba(colors.blue, .4),
		'-webkit-clip-path': 'polygon(0% 0%, 70% 0%, 100% 100%, 30% 100%)',
        'clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',

		'&::before, &::after': {
			display: 'block',
			width: '150%',
			height: '100%',
			position: 'absolute',
			left: '50%',
			transform: 'translateX(-50%)',
			content: '""',
		},

		'&::before': {
			top: 0,
			background: gradients.radial.top.tertiary,
		},

		'&::after': {
			bottom: 0,
			background: gradients.radial.bottom.blue,
		},

		[breakpoints.md]: {
			width: '50%',
			left: '10%',
		},
    },

    titleContainer: {
        margin: [0, 'auto', spaces[3]],

        [breakpoints.lg]: {
            display: 'flex',
            justifyContent: 'flex-end',
            margin: 0,
        },
    },
    
    title: {
        display: 'inline-block',
        maxWidth: 400,
		margin: [0, 'auto'],
        position: 'relative',
        fontSize: 30,
        lineHeight: '32px',
		color: colors.white,

        '& > span': {
            display: 'block',
            position: 'relative',
            zIndex: 2,
        },

        '&::before': {
            display: 'block',
            width: 2,
            height: '150%',
            position: 'absolute',
            top: '50%',
            right: spaces[3],
            transform: 'translateY(-50%) rotate(-35deg)',
            backgroundColor: colors.secondary,
            zIndex: 1,
            content: '""',
		},

        [breakpoints.sm]: {
            fontSize: 45,
            lineHeight: '47px',
        },
		
		[breakpoints.md]: {
			margin: 0,
            fontSize: 54,
            lineHeight: '66px',
		},
    },

    emailContainer: {
        display: 'inline-block',
        margin: [0, 38],
        position: 'relative',
        zIndex: 2,
        
        '&::before, &::after': {
            display: 'block',
            width: 62,
            height: 62,
            position: 'absolute',
            top: 0,
            transform: 'skewX(30deg)',
            backgroundColor: colors.white,
            content: '""',
        },

        '&::before': {
            right: -20,
        },

        '&::after': {
            left: -20,
        },

        [breakpoints.mw.sm]: {
            width: 'calc(100% - 138px)',
            marginLeft: -20,
            marginRight: -20,
        },
	},
    
    email: {
        display: 'inline-flex',
        padding: [0, spaces[2]],
        alignItems: 'center',
        justifyContent: 'center',
        height: 62,
        position: 'relative',
        fontSize: 18,
        fontWeight: 400,
        backgroundColor: colors.white,
        zIndex: 3,

        [breakpoints.mw.sm]: {
            width: '100%',
        },
    },

    submit: {
        margin: [0, 0, 0, -spaces[2]],
    },

    error: {},

    message: {
		display: 'block',
		width: '100%',
		fontSize: 18,
		textTransform: 'uppercase',
		textAlign: 'center',
        fontWeight: 700,
        color: colors.white,
    },
    
    formMessage: {
        margin: [spaces[2], 0, 0],
    },

    btn: {
        marginLeft: -spaces[2],
        position: 'relative',
        zIndex: 4,

        [breakpoints.mw.sm]: {
            float: 'right',
        },
    },
})
