export default ({
    spaces,
    colors,
    breakpoints,
    typography,
    helpers,
}) => ({
    key: { name: 'CareersArchiveDefaultView' },

    careers: {
        padding: [spaces[5], 0],
        backgroundColor: colors.secondary,
        overflow: 'hidden',
    },

    title: {
        margin: 0,
        padding: 0,
        position: 'relative',
        fontSize: 38,
        lineHeight: '43px',
        textAlign: 'center',
        color: colors.white,
        zIndex: 3,
    },

    filters: {
        margin: [spaces[2], 0, 0],
        position: 'relative',
        textAlign: 'center',
        zIndex: 10,
        
        [breakpoints.sm]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: [spaces[4], 0, 0],
        },
    },

    filter: {
        position: 'relative',
        marginTop: spaces[1],

        '&:first-child': {
            marginTop: 0,
        },

        [breakpoints.sm]: {
            marginTop: 0,
        },
    },

    icon: {
        marginLeft: spaces[0],
        transform: 'translateY(-2px)',
    },

    filterOptions: {
        marginTop: spaces[2],
        position: 'absolute',
        top: '100%',
        left: '50%',
        transform: 'translateX(-50%)',
        textAlign: 'center',
        boxShadow: `0 0 16px 0 ${helpers.hexToRgba(colors.black, .25)}`,
        height: '370px',
        overflowY: 'auto',

        '&::before': {
            display: 'block',
            width: '0',
            height: '0',
            position: 'absolute',
            bottom: '100%',
            left: '50%',
            transform: 'translateX(-50%)',
            borderStyle: 'solid',
            borderWidth: '0 10px 10px 10px',
            borderColor: `transparent transparent ${colors.white} transparent`,
            content: '""',
        },
    },

    filterOption: {
        ...typography.h5,
        display: 'block',
        width: '100%',
        padding: [spaces[0]*1.5, spaces[2]],
        fontWeight: 700,
        textTransform: 'uppercase',
        backgroundColor: colors.white,
        borderTop: `1px solid ${colors.lightGrey}`,
        whiteSpace: 'nowrap',
        userSelect: 'none',
        cursor: 'pointer',

        '&:hover': {
            backgroundColor: colors.lightGrey,
        },

        '&:first-child': {
            borderTop: 0,
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
        },

        '&:last-child': {
            borderBottomRightRadius: 5,
            borderBottomLeftRadius: 5,
        },

        '&.active': {
            '& > span': {
                ...helpers.gradientText,
            },
        },

        [breakpoints.sm]: {
            padding: [spaces[0]*1.5, spaces[3]],
        },
    },

    grid: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        margin: [spaces[3], 0, 0],
        position: 'relative',
        zIndex: 2,

        [breakpoints.md]: {
            margin: [spaces[4], 0, 0],
        },
    },

    loadMore: {
        margin: [spaces[5], 0, 0],
    },

    empty: {
        margin: [spaces[4], 0, 0],

        '& > h3': {
            color: colors.white,
        }
    }
})