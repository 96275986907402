export default ({
	colors,
    spaces,
    breakpoints,
    gradients,
}) => ({
    key: { name: 'Panel' },
    
    panel: {
        position: 'relative',
        width: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundColor: colors.white,
        overflow: 'hidden',

        '&::before, &::after': {
            display: 'none',
            width: '150%',
            height: '60%',
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            content: '""',
            zIndex: 1,
            opacity: .8,
        },

        [breakpoints.lg]: {
            height: '100vh',
            minHeight: 650,
        },
    },

    background: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        zIndex: 0,
    },

    gradientTop_pink: {
        '&::before': {
            display: 'block',
            top: 0,
            background: gradients.radial.top.pink,
        },
    },

    gradientTop_white: {
        '&::before': {
            display: 'block',
            top: 0,
            background: gradients.radial.top.white,
        },
    },

    gradientBottom_primary: {
        '&::after': {
            display: 'block',
            bottom: 0,
            background: gradients.radial.bottom.primary,
            opacity: 1,
        },
    },

    gradientBottom_blue: {
        '&::after': {
            display: 'block',
            bottom: 0,
            background: gradients.radial.bottom.blue,
        },
    },

    gradientBottom_pink: {
        '&::after': {
            display: 'block',
            bottom: 0,
            background: gradients.radial.bottom.pink,
        },
    },

    container: {
        width: '100%',
        maxWidth: 1400,
        margin: [0, 'auto'],
        padding: [0, spaces[2]],

        [breakpoints.lg]: {
            height: '100vh',
        },
    },

    padding: {
        width: '100%',

        [breakpoints.lg]: {
            height: '100vh',
        },
    },

    inner: {    
        width: '100%',
        maxWidth: 1400,
        margin: [0, 'auto'],
        padding: [spaces[4], 0],
        position: 'relative',
        textAlign: 'center',
        zIndex: 2,
        
        [breakpoints.md]: {
            padding: [spaces[5], 0],
        },

        [breakpoints.lg]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            maxWidth: 1400,
            margin: [0, 'auto'],
            padding: [0, spaces[4]],
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            textAlign: 'left',
        },
    },
})