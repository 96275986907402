export default ({
    spaces,
    breakpoints,
    colors,
}) => ({
    key: { name: 'HeroFullShards' },
    
    shard: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate3d(-920px, -1500px, 0) skew(31deg)',
        transformOrigin: 'center',
        overflow: 'hidden',
        zIndex: 1,
        transition: 'all ease-in-out 1s',
        backfaceVisibility: 'hidden',
        '-webkit-backface-visibility': 'hidden',

        '.state_0.loaded &': {
            transform: 'translate3d(-920px, -1500px, 0) skew(31deg) scale(0)',
            transition: 'none',
        },

        '.state_1.loaded &': {
            transform: 'translate3d(0, -50%, 0) skew(31deg) scale(1)',
            
            [breakpoints.sm]: {
                transform: 'translate3d(0, 0, 0) skew(31deg) scale(1)',
            },
        },

        '.state_2.loaded &': {
            transform: 'translate3d(950px, 1500px, 0) skew(31deg) scale(0)',
        },

        '&:nth-child(2)': {
            display: 'none',

            [breakpoints.sm]: {
                display: 'block',
            },
        },
    },

    size_xs: {
        width: 3,
        height: '15%',
        transition: 'all ease-in-out .8s',

        [breakpoints.lg]: {
            height: '10%',
        },
    },

    size_s: {
        width: 3,
        height: '35%',
        transition: 'all ease-in-out .5s',

        [breakpoints.lg]: {
            height: '30%',
        },
    },

    size_m: {
        width: 3,
        height: '50%',
        transition: 'all ease-in-out 1s',
    },

    size_l: {
        width: 90,
        height: 150,
        transition: 'all ease-in-out .75s',

        [breakpoints.lg]: {
            width: 120,
            height: 200,
        },
    },

    size_xl: {
        width: 150,
        height: 375,
        transition: 'all ease-in-out .8s',

        [breakpoints.lg]: {
            width: 200,
            height: 500,
        },
    },

    size_full: {
        width: 0,
        height: '150%',
        transform: 'translate3d(-50%, -50%, 0) skew(31deg)',
        transition: 'all ease-in-out .25s',

        '.state_0.loaded &, .state_2.loaded &': {
            width: 0,
            transform: 'translate3d(-50%, -50%, 0) skew(31deg)',
        },

        '.state_1.loaded &': {
            width: 200,
            transform: 'translate3d(-50%, -50%, 0) skew(31deg)',
        },
    },

    color_transparent: {
        backgroundColor: 'transparent',
    },

    color_primary: {
        backgroundColor: colors.primary,
    },
    
    color_secondary: {
        backgroundColor: colors.secondary,
    },
    
    color_tertiary: {
        backgroundColor: colors.tertiary,
    },

    image: {
        display: 'block',
        width: '280%',
        height: '150%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate3d(-50%, -50%, 0) skew(-31deg) scale(2)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        opacity: .75,
        transition: 'all ease-in-out 2s',
        transitionDelay: '0s',

        '.state_1 &': {
            transform: 'translate3d(-50%, -50%, 0) skew(-31deg) scale(1)',
        },
    }
})
