import React, { useState } from 'react'
import cx from 'classnames'
import is from 'react-jss'

import Offering from './Offering'

import style from './style'

const OurOfferings = ({
    content,
    classes,
}) => content.offerings.length > 0 ? (
    <div className={cx(classes.ourOfferings)}>
        <div className={cx('container')}>
            <div className={cx('row')}>
                <div className={cx('col-xs-12')}>
                    <h2 className={cx(classes.title)}>Our Offering</h2>
                </div>
                <div className={cx('col-xs-12')}>
                    <div className={cx(classes.table)}>
                        <div className={cx(classes.row, classes.titleRow)}>
                            <div></div>
                            <div className={cx(classes.divdeContainer)}>
                                <div className={cx(classes.desktopDivide, 'left')}></div>
                                <div className={cx(classes.desktopDivide, 'middle')}></div>
                                <div className={cx(classes.desktopDivide, 'right')}></div>
                                
                                <div className={cx(classes.iconRow)}>
                                    <h4 className={cx(classes.titleLabel)}>Power Up<br/>Options*</h4>
                                    <h4 className={cx(classes.titleLabel)}>Team Up</h4>
                                </div>
                            </div>
                        </div>

                        { content.offerings.map((o, i) => (
                            <Offering {...{ key: i, o }} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
        <div className={cx('container', 'container--tiny')}>
            <div className={cx('row')}>
                <div className={cx('col-xs-12')}>
                    <p className={cx(classes.closing)}>* Every developer is different, and every deal is unique. We will tailor our offering to your needs and provide services that benefit your game the most.</p>
                </div>
            </div>
        </div>
    </div>
) : null

export default is(style)(OurOfferings)