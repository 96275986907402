import React from 'react'

import Full from './Full'
import Overlay from './Overlay'
import Tagline from './Tagline'

const Hero = ({
    type,
    content,
    ...props
}) => {
    if (content.backgroundType === 'full') return <Full {...{ content, ...props }} />
    else if (content.tagline) return <Tagline {...{ content, ...props }} />
	else return <Overlay {...{ content, ...props }} />
}

export default Hero
