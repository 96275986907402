export default ({
    colors,
    spaces,
    breakpoints,
    helpers,
}) => ({
    key: { name: 'CareerDetails' },
    
    details: {
        backgroundColor: colors.tertiary,
        
        padding: [spaces[5], 0],
        position: 'relative',

        '&::before, &::after': {
            display: 'block',
            position: 'absolute',
            content: '""',
        },

        '&::before': {
            display: 'none',
            width: 300,
            height: 300,
            top: '20%',
            right: spaces[1],
            border: `3px solid ${colors.secondary}`,
            borderRadius: '100%',
            zIndex: 1,

            [breakpoints.xlg]: {
                display: 'block',
            },
        },

        '&::after': {
            display: 'none',
            width: 3,
            height: 300,
            position: 'absolute',
            bottom: '10%',
            right: '10%',
            transform: 'rotate(-36deg)',
            backgroundColor: colors.primary,
            zIndex: 1,

            [breakpoints.md]: {
                display: 'block',
            },
        },

        '& img': {
            display: 'none',
        },
    },

    backgroundText: {
        top: '50%',
        left: '5%',
    },

    content: {
        '& p, & li': {
            margin: [0, 0, spaces[1]],
            color: colors.white,

            '&:last-child, &:last-of-type': {
                marginBottom: 0,
            },
        },

        '& ul, & ol': {
            margin: `${spaces[3]}px 0!important`,
            padding: 0,
            listStyle: 'none',
        },

        '& li': {
            paddingLeft: spaces[1] + 9,
            position: 'relative',

            '&::before': {
                display: 'block',
                width: 9,
                height: 9,
                position: 'absolute',
                top: spaces[0],
                left: 0,
                backgroundColor: colors.primary,
                borderRadius: 9,
                content: '""',
            },
        },

        '& strong': {
            ...helpers.gradientText,
            display: 'inline-block',
        },
    },
})